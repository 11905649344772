import { findIndex, get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, UncontrolledTooltip, Col, Row, Collapse } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import classes from './ActiveStudy.module.scss';
import Progress from 'components/Progress';
import moment from 'moment';
import InfoCircle from 'assets/img/icons/info-circle.svg';
import PreliminaryAnalysisIcon from 'assets/img/icons/preliminary-analysis-icon.svg';
import DocumentGatheringIcon from 'assets/img/icons/document-gathering-icon.svg';
import CalculationsIcon from 'assets/img/icons/calculations-icon.svg';
import FinalReportsIcon from 'assets/img/icons/final-reports-icon.svg';
import DollarIcon from 'assets/img/icons/dollar-icon.svg';
import { useHistory } from 'react-router-dom';
import className from 'classnames';
import Button from 'components/Button';
import Loading from 'components/Loading';

const ActiveStudy = ({ content, isInProgress }) => {
  const history = useHistory();
  const [showDescription, setShowDescription] = useState(false);
  let numberFormat = new Intl.NumberFormat('en-IN');

  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const kickOffDate = get(content, 'kick_of_date');
  const fillingDeadline = get(content, 'filing_deadline');
  const estimatedDeliveryDate = get(content, 'estimated_delivery_date');
  const currentCreditEstimate = get(content, 'current_credit_estimate');
  const federalCreditsDeliverd = get(content, 'federal_credits_delivered');
  const stateCreditsDeliverd = get(content, 'state_credits_delivered');
  const totalCreditsDeliverd = get(content, 'total_credits_delivered');
  const currentPhase = get(content, 'project_phase.label');
  const currentYear = get(content, 'study_years');
  const isCompleted = currentPhase === 'Complete';

  /*const formatCurrentYear = yearString => {
    const arrayOfYear = yearString.split(',');
    if (arrayOfYear.length > 1) {
      return `${arrayOfYear[0]}-${arrayOfYear[arrayOfYear.length - 1]}`;
    }
    return arrayOfYear[0];
  };*/

  const getHeader = phase => {
    switch (phase) {
      case 'Onboarding':
        return (
          <>
            <img src={PreliminaryAnalysisIcon} alt="" />
            <div className="d-flex flex-wrap">
              Current Phase:{' '}
              <span className="font-weight-700 ml-1">{phase}</span>
            </div>
          </>
        );
      case 'Document Gathering':
        return (
          <>
            <img src={DocumentGatheringIcon} alt="" />
            <div className="d-flex flex-wrap font-weight-700">
              Awaiting study documents, please
              <span
                style={{
                  color: 'rgb(12,168,235)',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
                onClick={() => history.push('/admin/my-documents')}
              >
                add them here
              </span>
            </div>
          </>
        );
      case 'Calculations':
        return (
          <>
            <img src={CalculationsIcon} alt="" />{' '}
            <div className="d-flex flex-wrap">
              Current Phase:{' '}
              <span className="font-weight-700 ml-1">{phase}</span>
            </div>
          </>
        );
      case 'Final Reports':
        return (
          <>
            <img src={FinalReportsIcon} alt="" />{' '}
            <div className="d-flex flex-wrap">
              Current Phase:{' '}
              <span className="font-weight-700 ml-1">{phase}</span>
            </div>
          </>
        );
      case 'Complete':
        return (
          <span className="font-weight-700">
            {totalCreditsDeliverd
              ? `$ ${numberFormat.format(totalCreditsDeliverd)}`
              : '$ 0'}{' '}
            total credits delivered!
          </span>
        );
      default:
        return (
          <>
            <img src={PreliminaryAnalysisIcon} alt="" />{' '}
            <div className="d-flex flex-wrap">
              Current Phase:{' '}
              <span className="font-weight-700 ml-1">{phase}</span>
            </div>
          </>
        );
    }
  };

  const getProgressBar = phase => {
    switch (phase) {
      case 'Onboarding':
        return (
          <Progress multi className={classes.progress}>
            <Progress
              bar
              striped
              value="24"
              className={classes.preAnaProgressBar}
            />
            <Progress
              bar
              value="42"
              className={classes.unFinishedProgressBar}
            />
            <Progress
              bar
              value="16"
              className={classes.unFinishedProgressBar}
            />
            <Progress
              bar
              value="18"
              className={classes.unFinishedProgressBar}
            />
          </Progress>
        );
      case 'Document Gathering':
        return (
          <Progress multi className={classes.progress}>
            <Progress bar value="24" color="success" />
            <Progress
              bar
              striped
              value="42"
              className={classes.docGatProgressBar}
            />
            <Progress
              bar
              value="16"
              className={classes.unFinishedProgressBar}
            />
            <Progress
              bar
              value="18"
              className={classes.unFinishedProgressBar}
            />
          </Progress>
        );
      case 'Calculations':
        return (
          <Progress multi className={classes.progress}>
            <Progress bar value="24" color="success" />
            <Progress
              bar
              value="42"
              color="success"
              className={classes.successProgressBar}
            />
            <Progress
              bar
              striped
              value="16"
              className={classes.calProgressBar}
            />
            <Progress
              bar
              value="18"
              className={classes.unFinishedProgressBar}
            />
          </Progress>
        );
      case 'Final Reports':
        return (
          <Progress multi className={classes.progress}>
            <Progress bar value="24" color="success" />
            <Progress
              bar
              value="42"
              color="success"
              className={classes.successProgressBar}
            />
            <Progress
              bar
              value="16"
              color="success"
              className={classes.successProgressBar}
            />
            <Progress
              bar
              striped
              value="18"
              color="success"
              className={classes.finalProgressBar}
            />
          </Progress>
        );
      case 'Complete':
        return (
          <div className={classes.completedCardSection}>
            <Card className={classes.completedCards}>
              <div className="mt-1">Federal Credits Delivered</div>
              <div className="text-xl mt-1">
                {federalCreditsDeliverd && federalCreditsDeliverd !== 0
                  ? `$ ${numberFormat.format(federalCreditsDeliverd)}`
                  : '-'}{' '}
              </div>
            </Card>
            <Card className={classes.completedCards}>
              <div className="mt-1">State Credits Delivered</div>
              <div className="text-xl mt-1">
                {stateCreditsDeliverd && stateCreditsDeliverd !== 0
                  ? `$ ${numberFormat.format(stateCreditsDeliverd)}`
                  : '-'}{' '}
              </div>
            </Card>
          </div>
        );
      default:
        return (
          <Progress multi className={classes.progress}>
            <Progress
              bar
              striped
              value="24"
              className={classes.preAnaProgressBar}
            />
          </Progress>
        );
    }
  };

  const getProgressBarLabel = phase => {
    if (phase !== 'Complete') {
      return (
        <div className={classes.progressBarLabel}>
          <div
            style={{ width: '24%' }}
            className={phase === 'Onboarding' && classes.selectedLabel}
          >
            Onboarding
          </div>
          <div
            style={{ width: '42%' }}
            className={phase === 'Document Gathering' && classes.selectedLabel}
          >
            Document Gathering
          </div>
          <div
            style={{ width: '16%' }}
            className={phase === 'Calculations' && classes.selectedLabel}
          >
            Calculations
          </div>
          <div
            style={{ width: '18%' }}
            className={phase === 'Final Reports' && classes.selectedLabel}
          >
            Final Reports
          </div>
        </div>
      );
    }
  };

  const displayCreditEstOrCredDelivered = isComplete => {
    if (!isComplete) {
      if (currentCreditEstimate) {
        return numberFormat.format(currentCreditEstimate);
      }
      return 'Pending';
    } else {
      if (totalCreditsDeliverd) {
        return `$ ${numberFormat.format(totalCreditsDeliverd)}`;
      }
      return `$ 0`;
    }
  };

  return (
    <>
      <Card className="mt-1">
        {!isInProgress ? (
          <CardBody className="d-flex flex-column">
            {currentUser?.roles[0] === 'Client_Administrator' ? (
              <>
                <p className="text-sm">{currentYear}</p>
                <div className="text-lg font-weight-bold">
                  Phase{' '}
                  {findIndex(get(content, 'phases', []), [
                    'id',
                    get(content, 'project_phase.id'),
                  ]) + 1}{' '}
                  of {get(content, 'phases', []).length} : {currentPhase}
                </div>
                <Progress
                  color="success"
                  className="my-3"
                  max="100"
                  value={get(content, 'progress', 0)}
                />
                <p className="ml-auto text-xs" id="active-study-completed-info">
                  {get(content, 'progress', 0)}% Completed
                  <img src={InfoCircle} className="ml-1" alt="InfoCircle" />
                </p>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`#active-study-completed-info`}
                >
                  The progress is calculated by the number of remaining vs
                  completed tasks in the study.
                </UncontrolledTooltip>
              </>
            ) : (
              <Row>
                <Col md="8" className="pr-5">
                  <p
                    className={className(
                      'text-sm',
                      `${
                        isCompleted
                          ? classes.completedStudyTitle
                          : classes.activeStudyTitle
                      }`
                    )}
                  >
                    {isCompleted
                      ? `YOUR ${currentYear && currentYear} STUDY IS COMPLETE`
                      : `ACTIVE STUDY - ${currentYear && currentYear}`}
                  </p>
                  <div
                    className={className(
                      `text-lg ${isCompleted ? 'mb-2' : 'mb-4'}`,
                      classes.headerSection
                    )}
                  >
                    {getHeader(currentPhase)}
                  </div>
                  {getProgressBar(currentPhase)}
                  {getProgressBarLabel(currentPhase)}
                </Col>
                <Col md="4" className={classes.lineheight}>
                  <div>
                    <Row>
                      <Col md="7" className="d-flex p-0">
                        <div>
                          <i className="far fa-calendar" />
                        </div>
                        <span className="ml-2">Kick Off Date:</span>
                      </Col>
                      <Col md="5" className="pr-0">
                        {kickOffDate
                          ? moment(kickOffDate).format('MM/DD/YYYY')
                          : 'Not Available'}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="7" className="d-flex p-0">
                        <div>
                          <i className="far fa-calendar" />
                        </div>
                        <span className="ml-2">Filing Deadline:</span>
                      </Col>
                      <Col md="5" className="pr-0">
                        {fillingDeadline
                          ? moment(fillingDeadline).format('MM/DD/YYYY')
                          : 'Not Available'}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="7" className="d-flex p-0">
                        <div>
                          <i className="far fa-calendar" />
                        </div>
                        <span className="ml-2">
                          {isCompleted ? 'Delivery Date' : 'Est. Delivery Date'}
                          :{' '}
                        </span>
                      </Col>
                      <Col md="5" className="pr-0">
                        {estimatedDeliveryDate
                          ? moment(estimatedDeliveryDate).format('MM/DD/YYYY')
                          : 'Not Available'}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="7" className="d-flex p-0">
                        <div>
                          <img src={DollarIcon} alt="" />
                        </div>
                        <span className="ml-2">
                          {isCompleted
                            ? 'Credits Delivered'
                            : 'Credit Estimate'}
                          :
                        </span>
                      </Col>
                      <Col md="5" className="pr-0">
                        {displayCreditEstOrCredDelivered(isCompleted)}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {currentPhase === 'Document Gathering' && (
              <>
                <Collapse isOpen={showDescription}>
                  <Row noGutters>
                    <Col
                      className={className(
                        'pt-5 pb-3',
                        classes.descriptionBodyText
                      )}
                    >
                      During the document gathering phase, the Strike team
                      assembles all of the documentation necessary to maximize
                      your tax credits. As you begin adding the initially
                      requested documents, the Strike team will often identify
                      additional information that is required and will request
                      those additions by assigning your team with further tasks
                      that will display below.
                    </Col>
                  </Row>
                </Collapse>
                <Row>
                  <Col className={!showDescription && 'mt-4'} xs="auto">
                    <Button
                      id="toggler"
                      color="link"
                      className={className(
                        'p-0 font-weight-normal user-permissions',
                        classes.descriptionText
                      )}
                      onClick={() => setShowDescription(!showDescription)}
                    >
                      {showDescription ? 'Less' : 'More'} about the document
                      gathering phase
                      <i
                        className={className(
                          'fas ml-1 text-xs',
                          `fa-caret-${showDescription ? 'up' : 'down'}`
                        )}
                      />
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        ) : (
          <Loading key="loader" size={100} />
        )}
      </Card>
    </>
  );
};

export default ActiveStudy;
