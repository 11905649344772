import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import classes from './epics.module.scss';

const ViewEpicsBar = props => {
  const { collapse } = props;
  return (
    <CSSTransition
      timeout={200}
      in={collapse}
      classNames={{
        enter: classes['placeHolder-enter'],
        enterActive: classes['placeHolder-enter-active'],
        exit: classes['placeHolder-exit'],
        exitActive: classes['placeHolder-exit-active'],
      }}
      unmountOnExit
    >
      <div
        className={cx(
          classes['placeHolder'],
          'bg-white text-light align-center'
        )}
      >
        <span>VIEW PHASES</span>
      </div>
    </CSSTransition>
  );
};

export default ViewEpicsBar;
