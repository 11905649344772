import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './RSelectAsync.module.scss';
import cs from 'classnames';
import Select, { components } from 'react-select';
import Request from 'api/request';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useDebounce } from 'react-use';
import Loading from 'components/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import DropdownIndicator from 'components/DropDownIndicator';
import customStyles from 'assets/scss/custom/_variables.scss';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import usePrevious from 'helpers/usePrevious';

const MenuList = listProps => {
  const loadMoreOptions = get(
    listProps,
    'selectProps.menuListProps.loadMoreOptions'
  );
  const hasMore = get(listProps, 'selectProps.menuListProps.hasMore');
  const loading = get(listProps, 'selectProps.menuListProps.loading');
  const selectedValue = get(listProps, 'selectProps.value');
  const shouldShowExtraComponentInList = get(
    listProps,
    'selectProps.menuListProps.shouldShowExtraComponentInList'
  );
  const Extra = get(listProps, 'selectProps.menuListProps.extraMenuListProps');
  return (
    <components.MenuList {...listProps}>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={() => loadMoreOptions(hasMore)}
        hasMore={hasMore}
        useWindow={false}
        className="d-flex flex-column"
      >
        {listProps.children}
        {loading ? <Loading key="loader" size={50} /> : null}
        {!loading && !listProps.isLoading && shouldShowExtraComponentInList ? (
          <Extra.component
            value={selectedValue}
            hasValue={listProps.hasValue}
          />
        ) : null}
      </InfiniteScroll>
    </components.MenuList>
  );
};

const RSelectAsync = props => {
  const [options, setOptions] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false);
  const {
    url,
    apiType,
    getOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    isClearable,
    param,
    error,
    touched,
    components,
    onBlur,
    customOptions,
    styles,
    refreshOptions,
    shouldShowExtraComponentInList,
    extraMenuListProps,
    onOptionsLoad,
    shouldInitialLoad,
    ...rest
  } = props;

  const prevUrl = usePrevious(url);

  useDebounce(
    () => {
      if (focus) loadOptionsOnOpen(keyword);
    },
    200,
    [keyword, focus, refreshOptions]
  );

  useEffect(() => {
    if (shouldInitialLoad) loadOptionsOnOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const loadOptionsOnOpen = useCallback(
    async keyword => {
      setLoading(true);
      const resp = await Request.call({
        url,
        method: apiType,
        params: { [param]: keyword },
      });

      if (resp.status === 1) {
        const rs = get(resp, 'data', []) || [];
        const meta = get(resp, 'meta', []) || [];
        if (!isEmpty(rs)) setOptions(rs.concat(customOptions));
        else setOptions(rs);
        setMetaData(meta);
        if (url !== prevUrl && onOptionsLoad) {
          onOptionsLoad(rs);
        }
      } else {
        setOptions([].concat(customOptions));
        setMetaData({});
      }

      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiType, customOptions, param, url]
  );

  const onInputChange = debounce(text => {
    setKeyword(text);
  }, 500);

  const loadMoreOptions = async hasMore => {
    // Checking if we have more data or not
    if (!hasMore) {
      return;
    }
    setLoadingMoreData(true);
    const resp = await Request.call({
      url: url,
      method: apiType,
      params: {
        [param]: keyword,
        page: metaData.current_page + 1,
        offset: metaData.current_page * parseInt(metaData.per_page) + 1,
      },
    });

    if (resp.status === 1) {
      const rs = get(resp, 'data', []) || [];
      const meta = get(resp, 'meta', []) || [];
      if (!isEmpty(rs))
        setOptions(
          without([...options, ...rs], ...[].concat(customOptions)).concat(
            customOptions
          )
        );
      else setOptions(rs);
      setMetaData(meta);
    } else {
      setOptions([].concat(customOptions));
      setMetaData({});
    }
    setLoadingMoreData(false);
  };

  return (
    <div
      className={cs(
        {
          [classes['is-invalid']]: touched && error,
          [classes.hasLeftIcon]: props.leftIcon,
        },
        classes.root
      )}
    >
      {props.leftIcon && (
        <span className={classes.leftIcon}>{props.leftIcon}</span>
      )}

      <Select
        classNamePrefix="rselect"
        isLoading={loading}
        onFocus={() => {
          setFocus(true);
          setKeyword('');
        }}
        onBlur={() => {
          setFocus(false);
          onBlur && onBlur();
        }}
        isClearable={isClearable}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        onInputChange={onInputChange}
        options={loading ? [] : options}
        menuListProps={{
          loadMoreOptions: loadMoreOptions,
          hasMore:
            !loadingMoreData && metaData.current_page < metaData.last_page,
          loading: loadingMoreData,
          shouldShowExtraComponentInList: shouldShowExtraComponentInList,
          extraMenuListProps: extraMenuListProps,
        }}
        components={{
          ...components,
          MenuList,
          DropdownIndicator,
        }}
        styles={{
          option: (provided, state) => {
            const customOptionStyles = get(
              styles,
              'option',
              dataProvided => dataProvided
            );
            return customOptionStyles(
              {
                ...provided,
                ':active': {
                  backgroundColor: customStyles.dropdownLinkHoverBg,
                },
                color: customStyles.bodyColor,
                ...(state.isSelected || state.isFocused
                  ? {
                      backgroundColor: customStyles.dropdownLinkHoverBg,
                      color: customStyles.dropdownLinkColor,
                    }
                  : {}),
              },
              state
            );
          },
          ...omit(styles, ['option']),
        }}
        {...rest}
      />

      {error && touched && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </div>
  );
};

RSelectAsync.propTypes = {
  url: PropTypes.string.isRequired,
  param: PropTypes.string.isRequired,
  customOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RSelectAsync.defaultProps = {
  param: 'q', // /api?q=..
  isClearable: true,
  shouldShowExtraComponentInList: false,
  placeholder: '',
  apiType: 'GET',
  getOptionLabel: option => option.text,
  getOptionValue: option => option.id,
  onChange: () => {},
  components: {},
  customOptions: [],
  styles: {},
  shouldInitialLoad: true,
};

export default RSelectAsync;

// all Props : https://react-select.com/props
