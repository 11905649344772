import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import FormikInput from 'components/FormFields/Input/FormikInput';
import PropTypes from 'prop-types';
import useTraceUpdate from 'helpers/useTraceUpdate';

const CreateStoryForm = ({
  closeModal,
  submitValues,
  isModalOpen,
  isLoading,
  isPersonalDashboard,
}) => {
  useTraceUpdate('hello', {
    closeModal,
    submitValues,
    isModalOpen,
    isLoading,
  });
  const project = useSelector(({ project }) =>
    get(project, 'getProject.data.data', {})
  );

  const CreateStorySchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Story name is required'),
    project: Yup.object().required('Please select project'),
    section: Yup.object().required('Please select section'),
  });

  const RenderForm = ({
    handleSubmit,
    closeModal,
    isValid,
    handleReset,
    values,
    touched,
    errors,
    setFieldValue,
  }) => {
    return (
      <>
        <ModalBody className="pb-0">
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <FormikInput
                groupClassName="mb-3"
                name="name"
                placeholder="Task Name"
                type="text"
              />
              <RSelectAsync
                url="/initiatives/list/dropdown"
                getOptionLabel={option => option.name}
                placeholder="Select Study"
                onChange={value => {
                  setFieldValue('project', value);
                  setFieldValue('section', null);
                }}
                value={values.project}
                touched={touched.project}
                error={errors.project}
                styles={{
                  multiValue: base => ({
                    ...base,
                    background: '#F4F5F7',
                    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
                    borderRadius: '4px',
                    padding: '4px 5px',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#32325d',
                  }),
                }}
              />
              <h5 className="font-weight-700 mt-3 mb-1">Backlog Section</h5>
              <RSelectAsync
                url={
                  values.project
                    ? `/initiatives/${values.project.id}/backlogSections/list/dropdown`
                    : ''
                }
                getOptionLabel={option => option.name}
                placeholder="Select Section"
                onChange={value => {
                  setFieldValue('section', value);
                }}
                isClearable={false}
                isDisabled={!values.project}
                value={values.section}
                touched={touched.section}
                error={errors.section}
                onOptionsLoad={options => {
                  if (!values.section) {
                    setFieldValue('section', options[0]);
                  }
                }}
                styles={{
                  multiValue: base => ({
                    ...base,
                    background: '#F4F5F7',
                    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
                    borderRadius: '4px',
                    padding: '4px 5px',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#32325d',
                  }),
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between pt-0">
          <Button
            onClick={() => {
              handleReset();
              closeModal();
            }}
            color="link"
            className={'float-right'}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || isLoading}
            color={!isValid || isLoading ? 'secondary' : 'primary'}
            className={'float-left'}
            loading={isLoading}
          >
            Create Task
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      toggle={closeModal}
      centered
      isOpen={isModalOpen}
      title="Create a Task"
      size="md"
    >
      <Formik
        validateOnMount
        initialValues={{
          project: isPersonalDashboard ? undefined : project,
          name: '',
          section: null,
        }}
        validationSchema={CreateStorySchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          await submitValues(values);
          resetForm();
        }}
      >
        {props => <RenderForm {...{ ...props, closeModal }} />}
      </Formik>
    </Modal>
  );
};

CreateStoryForm.propTypes = {
  closeModal: PropTypes.func,
  submitValues: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CreateStoryForm.defaultProps = {
  closeModal: () => {},
  submitValues: () => {},
  isModalOpen: false,
  isLoading: false,
};

export default CreateStoryForm;
