import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overview from './Overview';
import { Row, Col, Container } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import toNumber from 'lodash/toNumber';
import {
  getBudgetSpent,
  getSevenDaysHours,
  getTimeSpent,
  getStudyTasks,
  addDeliverableToStudy,
  getDeliverables,
} from 'store/actions/projectDashboard';
import get from 'lodash/get';
import classes from 'views/pages/Project/projects.module.scss';
import cx from 'classnames';
import RecentActivity from './RecentActivity';
import StoryModal from '../../Backlogs/Stories/StoryModal';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { fetchProjectActivities } from 'store/actions/projects';
import Loading from 'components/Loading';
import QuickActions from './Fields/QuickActions';
import ClientTasks from 'views/pages/dashboards/Fields/ClientTasks';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import TaskModal from 'views/pages/dashboards/TaskModal';
import DeliverablesModal from 'components/DeliverablesModal';
import Deliverables from './Fields/Deliverables';
import {
  doResetActiveDeliverables,
  doUpdateDeliverable,
  getDeliverable,
} from 'store/actions/clientProfile';
import { AlertPopupHandler } from 'components/AlertPopup';
import { downloadDocument } from 'store/actions/documents';
import FileViewer from 'components/FileViewer';
import { useDebounce } from 'react-use';
import { fetchYears } from 'store/actions/metadata';

const Dashboard = ({ isCompanyAdmin, userId, projectId }) => {
  const params = useParams();
  const id = get(params, 'id');
  const dispatch = useDispatch();
  const [showDeliverablesModal, setShowDeliverablesModal] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_dashboard,
      ...rest,
    });
  };
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const loggedInUserID = useSelector(({ auth }) => get(auth, 'user.id', null));
  const clientId = useGetFieldFromObject(
    'project',
    'getProject.data.data.client.id'
  );
  const studyName = useGetFieldFromObject(
    'project',
    'getProject.data.data.name'
  );
  const projectMembers = useSelector(({ project }) =>
    get(project, `getProject.data.data.team_members`, [])
  );
  const isMemberOfProject =
    projectMembers?.findIndex(m => m.id === loggedInUserID) > -1;

  useEffect(() => {
    dispatch(getDeliverables(projectId, { page: 0, sort: 'name' }));
  }, [dispatch, projectId]);

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_project_dashboard,
    });
    window.scrollTo(0, 0);
    const getAllDetails = async () => {
      dispatch(getSevenDaysHours(id));
      dispatch(getBudgetSpent(id));
      await dispatch(getTimeSpent(id));
      await dispatch(fetchProjectActivities(id, 1));
    };
    setIsDataLoading(true);
    getAllDetails();
    setIsDataLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchYears());
  }, [dispatch]);

  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState('');
  const [count, setCount] = useState(0);
  const [comment, setComment] = useState(null);
  const [isTaskModalOpen, setIsTaskModal] = useState(false);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');
  const [viewMode, setViewMode] = useState('');
  const [viewDeliverable, setViewDeliverable] = useState(false);
  const [file, setFile] = useState();

  const currentUser = useSelector(({ auth }) => get(auth, 'user'));

  const handleViewDeliverableToggle = () => {
    setViewDeliverable(!viewDeliverable);
    dispatch(doResetActiveDeliverables());
  };

  const handleActionPerform = params => {
    let apiParams = params;
    if (isClient) {
      apiParams = { ...apiParams, status: 'open' };
    }
    dispatch(getStudyTasks(id, apiParams));
  };

  useEffect(() => {
    if (queryProps.story) {
      setStoryId(queryProps.story);
      setIsTaskModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps.story]);

  const reload = () => setCount(count + 1);
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('projectDashboard', field, defaultValue);
  const commonProps = {
    isMobile,
    getDetails: useGetDetails,
    isTablet,
    analyticsConstants,
    studyDashboard: true,
    actionToPerform: handleActionPerform,
    ownerKey: currentUser.is_client ? 'owner_id' : 'created_by',
  };

  const handleEditDeliverables = deliverable => {
    dispatch(getDeliverable(deliverable.id));
    setViewMode('edit');
    setShowDeliverablesModal(true);
  };

  const handleViewDeliverable = async id => {
    const fileData = await dispatch(getDeliverable(id));
    setFile(fileData);
    setViewDeliverable(true);
  };

  const handleDownload = async id => {
    const fileData = await dispatch(getDeliverable(id));
    dispatch(downloadDocument(fileData.attachment));
  };

  const handleDeliverableModalClose = () => {
    setShowDeliverablesModal(false);
    dispatch(doResetActiveDeliverables());
  };

  useDebounce(
    () => {
      if (showDeliverablesModal === false) {
        setViewMode('');
      }
    },
    1500,
    [showDeliverablesModal]
  );

  return (
    <Container fluid className={cx(classes['dashboard'])}>
      {isDataLoading && <Loading wrapperClass={classes.loading} />}

      <Row>
        <Col md={4}>
          <Overview />
          <Deliverables
            reducerKey="projectDashboard"
            setShowDeliverablesModal={() => setShowDeliverablesModal(true)}
            setEditDeliverables={handleEditDeliverables}
            setViewDeliverable={handleViewDeliverable}
            onDeleted={() => {
              dispatch(getDeliverables(id));
            }}
            setDownloadDeliverable={handleDownload}
            projectId={projectId}
          />
        </Col>
        <Col md={8}>
          {currentUser?.is_client && (
            <QuickActions
              isMemberOfProject={isMemberOfProject}
              projectId={id}
              studyName={studyName}
            />
          )}
          <ClientTasks
            {...commonProps}
            onRowClick={id => {
              setStoryId(id);
              setIsTaskModal(true);
            }}
          />
          <RecentActivity projectId={id} />
        </Col>
      </Row>
      {isStoryModalOpen && (
        <StoryModal
          storyId={toNumber(storyId)}
          projectId={toNumber(id)}
          isOpen={isStoryModalOpen}
          closeModal={() => {
            setIsStoryModalOpen(false);
            history.push(`?`);
          }}
          currentTab={get(queryProps, 'tab', 'comments')}
          onChange={() => reload()}
          comment={comment}
        />
      )}
      {isTaskModalOpen && (
        <TaskModal
          storyId={toNumber(storyId)}
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setIsTaskModal(false);
            history.push(`?`);
          }}
          currentTab={'comment'}
        />
      )}
      <DeliverablesModal
        handleClose={handleDeliverableModalClose}
        isOpen={showDeliverablesModal}
        handleSubmit={async (values, { resetForm }) => {
          const formData = new FormData();
          const { name, description, attachment } = values;
          if (attachment.size > 51200000) {
            return AlertPopupHandler.open({
              onConfirm: () => {},
              confirmBtnText: `Ok`,
              showCancel: false,
              title: 'Upload Unsuccessful',
              text: (
                <div className="d-flex flex-column">
                  <div>Maximum allowed file size is 50 MB</div>
                </div>
              ),
            });
          }

          formData.append('name', name);
          formData.append('description', description);
          formData.append('client_id', clientId);
          formData.append('study_id', id);
          formData.append(`attachment`, attachment);
          if (viewMode === 'edit') {
            formData.append('_method', 'PUT');
            await dispatch(doUpdateDeliverable(formData, values.id));
          } else {
            await dispatch(addDeliverableToStudy(formData));
          }
          dispatch(getDeliverables(id));

          resetForm();
          setShowDeliverablesModal(false);
        }}
        viewMode={viewMode}
      />
      {viewDeliverable && (
        <FileViewer
          isOpen={viewDeliverable}
          toggle={handleViewDeliverableToggle}
          fileData={file?.attachment}
          title="Preview"
        />
      )}
    </Container>
  );
};

export default Dashboard;
