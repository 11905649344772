import React from 'react';
import cx from 'classnames';
import uniqBy from 'lodash/uniqBy';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import UserInfo from 'components/UserInfo';

const Collaborators = ({ getFieldValue }) => {
  const teamMembers = getFieldValue('team_members', []);

  // exclude users who do not have any role
  const membersWithRole = teamMembers.filter(user => {
    return user.roles?.length > 0;
  });

  let roles = membersWithRole?.map(user => {
    return user.roles[0];
  });

  roles = uniqBy(roles, 'name');

  roles.forEach(role => {
    role.users = teamMembers.filter(user => {
      return user.roles[0]?.name === role.name;
    });
  });

  if (!roles.length) return null;

  return (
    <div>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        COLLABORATORS
      </h6>
      <div>
        {roles.map((role, roleIndex) => (
          <div className="mb-2" key={roleIndex}>
            <div className={classes.roleTitle}>
              <span>{role.title}</span>
            </div>
            {role.users.map((member, index) => (
              <div className="mb-1" key={index}>
                <UserInfo info={member} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collaborators;
