import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './PartnerDashboard.module.scss';
import get from 'lodash/get';
import LeadModal from 'views/pages/dashboards/PartnerDashboard/LeadModal';
import { clearReferralCommentsData } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const PartnersTable = ({ headerText = '', data = [], type = '' }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const parentId = useSelector(({ auth }) => auth.user.company.id, null);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const [leadId, setLeadId] = useState('');
  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const rowEvents = {
    onClick: (e, row) => {
      // onRowClick(row.id);
    },
  };

  const getSortByValue = (obj, field) => {
    switch (field) {
      case 'copy':
        return new Date(obj['timestamp']);
      default:
        return obj[field];
    }
  };

  useEffect(() => {
    const clone = [...tableData];
    clone.sort((a, b) => {
      if (sortBy.order === 'asc') {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        return 0;
      } else {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        return 0;
      }
    });
    setTableData(clone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    setSortBy({
      dataField: sortField,
      order: sortOrder,
    });
  };

  const handleFetchReferralActivities = row => {
    setLeadId(get(row, 'lead_id'));
    setIsModal(true);
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div
          className={cx(
            'd-flex justify-content-between align-items-center flex-column w-100'
          )}
        >
          <div className={classes.emptyStateHeader}></div>
          <div className={classes.noData}>
            <div className={classes.iconWrapper}>
              <i className="fa fa-address-card" aria-hidden="true"></i>
            </div>
          </div>
          <p className={cx(classes.defaultText)}>Nothing to see here</p>
        </div>
      </div>
    );
  };

  const getDetails = (status, timestamp) => {
    switch (status) {
      case 'active_referrals':
        return `Active as of ${moment(timestamp)
          .tz(userTimeZone)
          .format('MM/DD/YYYY')}`;
      case 'disqualified':
        return `Disqualified as of ${moment(timestamp)
          .tz(userTimeZone)
          .format('MM/DD/YYYY')}`;
      case 'silent':
        return `Silent since ${moment(timestamp)
          .tz(userTimeZone)
          .format('MM/DD/YYYY')}`;
      case 'closed':
        return `Closed on ${moment(timestamp)
          .tz(userTimeZone)
          .format('MM/DD/YYYY')}`;
      default:
        return '-';
    }
  };

  return (
    <>
      {isModal ? (
        <LeadModal
          parentId={parentId}
          leadId={leadId}
          isOpen={isModal}
          modalType="referral"
          closeModal={() => {
            setIsModal(false);
            dispatch(clearReferralCommentsData());
          }}
        />
      ) : null}
      <div className={classes.partnerDetailsTableWrapper}>
        <h1 className="font-weight-700">{headerText}</h1>
        <Card className={cx(classes.viewedStories)}>
          <CardBody
            id="recently-viewed-stories-card-body"
            className={cx('p-0', classes.cardBody, classes.scroll, {
              [classes[type]]: tableData.length > 0,
            })}
          >
            <BaseTable
              keyField="id"
              // defaultSorted={[sortBy]}
              remote
              noDataIndication={NoDataIndication}
              search={false}
              bordered={false}
              paginationOptions={false}
              data={tableData}
              onTableChange={handleTableChange}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              columns={[
                {
                  dataField: 'name',
                  text: 'Lead Name',
                  sort: true,
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  classes: (cell, row) => {
                    return classes[row.status];
                  },
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div
                          id={`recent-story-name-${get(row, 'id')}`}
                          className={cx('m-0 text-break')}
                        >
                          {cell ? cell : '-'}
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'copy',
                  text: 'Detail',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <div className="d-flex align-content-center justify-content-between text-nowrap">
                        <div
                          id={`recent-story-name-${get(row, 'id')}`}
                          className={cx('m-0')}
                        >
                          {getDetails(row?.status, row?.timestamp)}
                        </div>
                        <div
                          onClick={() => handleFetchReferralActivities(row)}
                          className={cx('mx-2', classes.addIcon)}
                        >
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              rowEvents={rowEvents}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

PartnersTable.propTypes = {};

export default PartnersTable;
