import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/Button';
import Dropdowns from 'components/Dropdowns';
import { LineLoader } from 'components/ContentLoader';
import classes from './FileUpload.module.scss';
import { UncontrolledTooltip } from 'reactstrap';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDocumentTypesForFY,
  syncDocument,
  uploadDocumentsForYear,
} from 'store/actions/documents';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { disconnectCodat, disconnectFinch } from 'store/actions/onboarding';
import { getCompanyDetails } from 'store/actions/company';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';

const FileUpload = ({
  folder,
  inputProps = {},
  showContentLoader,
  isUpdateAllowed = true,
  showIntegrate,
  description,
  titleClick,
  index,
  handleIntegration,
  isQuickStartTour,
  pendingReview = false,
  verified = false,
  taxType,
  isIntegrated,
  activeYear,
}) => {
  const dispatch = useDispatch();
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const uploadFile = useRef();

  const {
    id,
    short_name: label,
    documents,
    last_synced_at: lastSyncTime,
  } = folder;

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const payrollProvider = useGetFieldFromObjects(
    'company',
    'details.data.payroll_provider'
  );

  const accountProvider = useGetFieldFromObjects(
    'company',
    'details.data.accounting_provider'
  );

  const handleFileChange = async attachments => {
    setLoading(true);
    const form = new FormData();
    const hasOverSize = attachments.some(attachment => {
      return attachment.size > 51200000;
    });
    if (hasOverSize) {
      return AlertPopupHandler.open({
        onConfirm: () => {
          setLoading(false);
        },
        confirmBtnText: `Ok`,
        showCancel: false,
        title: 'Upload Unsuccessful',
        text: (
          <div className="d-flex flex-column">
            <div>Maximum allowed file size is 50 MB</div>
          </div>
        ),
      });
    }
    attachments.forEach((attachment, i) => {
      form.append(`attachments[${i}]`, attachment);
    });
    const { status } = await dispatch(uploadDocumentsForYear(id, form));
    dispatch(fetchDocumentTypesForFY(activeYear));
    if (status) {
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } else {
      setError(true);
      setLoading(false);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileChange,
    disabled: !isClient,
  });
  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );
  const currentlySyncingData = useSelector(({ documents }) =>
    get(documents, 'syncDocument.syncInProgress', [])
  );

  const currentlySyncing = currentlySyncingData.map(element =>
    parseInt(element.id)
  );

  const getLastSyncTime = () => {
    if (!lastSyncTime) return;
    return (
      <span>
        Last synced on {moment(lastSyncTime).format('MM/DD/YYYY h:mma')}
      </span>
    );
  };

  const handleInputRef = event => {
    if (isQuickStartTour) {
      return false;
    }
    event.stopPropagation();
    uploadFile.current.click();
  };

  const handleSync = e => {
    e.stopPropagation();
    if (!isClient) return;
    dispatch(syncDocument(id, label === 'General Ledger' ? 'codat' : 'finch'));
  };

  const handleDisconnect = e => {
    e.stopPropagation();
    AlertPopupHandler.openCustom({
      type: 'warning',
      warning: true,
      success: false,
      showCancel: true,
      onConfirm: onConfirmDisconnect,
      title: 'Disconnect Integration',
      confirmBtnText: 'Yes, Disconnect',
      confirmBtnBsStyle: 'warning',
      text:
        'This will remove the integration and all data created by the integration will be removed. Are you sure you want to disconnect the integration?',
    });
  };

  const onConfirmDisconnect = async () => {
    let status;
    if (label === 'General Ledger') {
      status = await dispatch(disconnectCodat());
    } else {
      status = await dispatch(disconnectFinch());
    }
    if (status) {
      await dispatch(getCompanyDetails(false));
      await dispatch(fetchDocumentTypesForFY(activeYear));
      return AlertPopupHandler.open({
        type: 'success',
        warning: false,
        success: true,
        showCancel: false,
        onConfirm: () => {},
        title: 'Integration Disconnected',
        confirmBtnText: 'OK, got it!',
        confirmBtnBsStyle: 'success',
        text: 'You have successfully disconnected the integration!',
      });
    }
  };

  const isIntegrationFolder = [
    'General Ledger',
    '1099s',
    'Employee Payroll Reports',
  ].includes(label);

  const getDropDownOptions = () => {
    let reconnect;
    if (label === 'General Ledger') {
      reconnect = accountProvider?.reauthenticate_user;
    } else {
      reconnect = payrollProvider?.reauthenticate_user;
    }
    if (!isIntegrated || reconnect) {
      return [
        {
          text: reconnect ? 'Reconnect' : 'Integrate',
          onClick: e => {
            e.stopPropagation();
            return handleIntegration();
          },
        },
      ];
    }
    if (currentlySyncing?.includes(id)) {
      return [
        {
          text: 'Disconnect Integration',
          onClick: handleDisconnect,
        },
      ];
    }
    return [
      {
        text: 'Sync Integration',
        onClick: handleSync,
      },
      {
        text: 'Disconnect Integration',
        onClick: handleDisconnect,
      },
    ];
  };

  return (
    <>
      {isUpdateAllowed ? (
        showContentLoader ? (
          <LineLoader number={1} height="5.625rem" />
        ) : (
          <div
            className={classnames(classes.fileInputWrapper, {
              [classes.hoverDrop]: isDragActive,
              [classes.loadingStyle]: loading,
              [classes.success]: success,
              [classes.error]: error,
              [classes.afterSuccess]:
                !loading &&
                !success &&
                !error &&
                ((!isIntegrationFolder && documents.length > 0) ||
                  (isIntegrationFolder && isIntegrated) ||
                  pendingReview ||
                  documents.length > 0),
              [classes.otherDocuments]: label.includes('Other'),
              [classes.cursor]: documents.length > 0,
              [classes.pendingReview]: isClient && pendingReview,
              [classes.verified]: verified,
            })}
            {...(label.includes('Other') ? { id: 'otherDocument' } : {})}
            {...getRootProps()}
          >
            <input {...getInputProps({ ...inputProps })} ref={uploadFile} />
            <div className="d-flex align-items-center text-center justify-content-between">
              <div
                className={classnames(classes.title, {
                  [classes.titleAfterSuccess]: !loading && !success && !error,
                })}
                onClick={titleClick}
                {...((index === 0 &&
                  quickStartTour.activeTour === 'general_ledger_payroll') ||
                (index === 1 && quickStartTour.activeTour === 'tax_returns')
                  ? { id: 'document' }
                  : {})}
              >
                {label}
                <i
                  className="fas fa-info-circle ml-1"
                  id={`document${id}ToolTip`}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  target={`document${id}ToolTip`}
                >
                  {description}
                </UncontrolledTooltip>
              </div>
              <div className="d-flex align-items-center integration-dropdown">
                {isClient &&
                  pendingReview &&
                  !loading &&
                  !success &&
                  !error && (
                    <span className={classes.pendingText}>PENDING REVIEW</span>
                  )}
                {isClient &&
                  !!accountProvider &&
                  !!isIntegrated &&
                  currentlySyncing?.includes(id) && (
                    <span className={classes.syncText}>
                      Sync in progress...
                    </span>
                  )}
                {isClient &&
                  (documents.length > 0 || isIntegrated) &&
                  [
                    '1099s',
                    'Employee Payroll Reports',
                    'General Ledger',
                  ].includes(label) && (
                    <Dropdowns
                      onClick={event => event.stopPropagation()}
                      text={<i className="fas fa-ellipsis-v" />}
                      className={classes.moreOption}
                      options={getDropDownOptions()}
                      caret={false}
                      size="sm"
                      color=""
                    />
                  )}
              </div>
            </div>
            {loading ? (
              <div className={classes.loadingText}>Loading...</div>
            ) : success ? (
              <div className={classes.successText}>Successfully Uploaded</div>
            ) : error ? (
              <div className={classes.errorText}>Upload Unsuccessful</div>
            ) : isDragActive ? (
              <div className={classes.dropText}>Drop Your File(s) Here</div>
            ) : documents.length > 0 || isIntegrated ? (
              <div className="d-flex flex-column h-100 justify-content-between">
                <div className={classes.listHeader}>
                  <span>
                    {isIntegrationFolder && isIntegrated
                      ? 'LIVE INTEGRATIONS'
                      : 'FILES'}
                  </span>
                </div>
                <ul className={classes.fileList}>
                  {documents.length > 0 ? (
                    documents.map((document, index) => (
                      <li key={index} className={classes.listItem}>
                        <div
                          id={`file-name-${document.id}`}
                          className={classnames(
                            classes.fileName,
                            !isClient && classes.adminView
                          )}
                        >
                          <span>{document.name}</span>
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={`file-name-${document.id}`}
                          placement="top-start"
                          innerClassName={classes['tooltip']}
                          boundariesElement="viewport"
                          hideArrow={true}
                        >
                          {document.name}
                        </UncontrolledTooltip>
                        {!isClient && document.is_new && (
                          <div className={classes.isNew}>
                            <span>
                              <i className="fa fa-bell" />
                              NEW
                            </span>
                          </div>
                        )}
                      </li>
                    ))
                  ) : (
                    <li className={classes.listItem}>
                      <div
                        className={classnames(
                          classes.fileName,
                          !isClient && classes.adminView,
                          currentlySyncing && classes.hideRow
                        )}
                      >
                        <i className="fa fa-exclamation-circle" />
                        <span>No Integration Data Found</span>
                      </div>
                    </li>
                  )}
                </ul>
                <div
                  className={classnames(
                    'd-flex',
                    'w-100',
                    'mt-2',
                    'mb-2',
                    'mr-2',
                    {
                      'justify-content-between': taxType || !!isIntegrated,
                      'justify-content-end': !taxType,
                    }
                  )}
                >
                  {taxType && (
                    <span className={classes.taxType}>{taxType}</span>
                  )}
                  {!!isIntegrated && (
                    <span className={classes.taxType}>{getLastSyncTime()}</span>
                  )}
                  <Button
                    leftIcon={<i className="fas fa-upload" />}
                    color="primary"
                    outline
                    size="sm"
                    disabled={!isClient}
                    onClick={handleInputRef}
                    className={classnames('mr-2', classes.buttonPrimary)}
                  >
                    Upload File
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.buttonWrapper}>
                {showIntegrate && (
                  <Button
                    leftIcon={
                      <i
                        className={classnames(
                          'fas fa-share-alt',
                          classes.integrateButton
                        )}
                      />
                    }
                    disabled={!isClient}
                    color="primary"
                    className={classes.buttonPrimary}
                    {...(index === 0 ? { id: 'integrateButton' } : {})}
                    onClick={handleIntegration}
                  >
                    Integrate
                  </Button>
                )}
                <div className="d-flex align-items-center flex-column">
                  <Button
                    leftIcon={<i className="fas fa-upload" />}
                    color="primary"
                    outline={showIntegrate || label.includes('Other')}
                    onClick={handleInputRef}
                    className={classnames(classes.buttonPrimary, {
                      [classes.buttonOutline]: showIntegrate,
                      [classes.buttonOutline]: label.includes('Other'),
                    })}
                    disabled={!isClient}
                    {...((index === 0 &&
                      quickStartTour.activeTour === 'general_ledger_payroll') ||
                    (index === 1 && quickStartTour.activeTour === 'tax_returns')
                      ? {
                          id: 'uploadFileButton',
                        }
                      : {})}
                  >
                    Upload File
                  </Button>
                </div>
              </div>
            )}
          </div>
        )
      ) : null}
    </>
  );
};

FileUpload.prototype = {
  inputProps: PropTypes.object,
  rootProps: PropTypes.object,
  showContentLoader: PropTypes.bool,
  isUpdateAllowed: PropTypes.bool,
  label: PropTypes.string,
  showIntegrate: PropTypes.bool,
  supportedTypes: PropTypes.string,
  documents: PropTypes.array,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onDrop: PropTypes.func,
  loading: PropTypes.bool,
  isIntegrated: PropTypes.bool,
};

export default FileUpload;
