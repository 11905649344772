import { get, omitBy, isUndefined, isNil, isNull, isEmpty } from 'lodash';
import {
  fetchProjects,
  addProject,
  removeProject,
  updateProject,
  fetchProject,
  getProjectActivities,
  duplicateProject as duplicateProjectApi,
  addProjectMember as addProjectMemberApi,
  deleteProjectMember as deleteProjectMemberApi,
} from 'api/projects';
import Types from '../types/projects';
import history from 'helpers/history';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { getDropdown } from 'api/metadata';
import { getBudgetSpent } from 'store/actions/projectDashboard';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import { sum } from 'lodash/math';

export const fetchProjectsList = (page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROJECTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchProjects(page, sort, q);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_PROJECTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PROJECTS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROJECTS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const createProject = (
  {
    projectName: name,
    projectClient,
    projectContract,
    projectDescription: description,
    startDate: date_start,
    endDate: date_end,
    monthlyBudget: total_budget,
    monthlyHours: budget_hours,
  },
  { page, sort, q },
  shouldRefetchList = true,
  onBoardingCallback
) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_PROJECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const theme_id = isNull(projectContract)
        ? null
        : get(projectContract, 'id');
      const client_id = isNull(projectClient) ? null : get(projectClient, 'id');
      const resp = await addProject(
        omitBy(
          {
            name,
            client_id,
            theme_id,
            description,
            date_start,
            date_end,
            total_budget,
            budget_hours,
          },
          isNil
        )
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.CREATE_PROJECT_SUCCESS,
          data: resp || {},
        });
        history.push(`/admin/studies/${record.id}`);
        if (onBoardingCallback) {
          onBoardingCallback();
        }
        if (shouldRefetchList) {
          await dispatch(fetchProjectsList(page, sort, q));
        }

        NotificationHandler.open({
          message,
          operation: 'success',
          title: 'Congratulations!',
        });
      } else {
        dispatch({
          type: Types.CREATE_PROJECT_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message,
            title: 'Something went wrong',
          });
        }
      }
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.CREATE_PROJECT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
        title: 'Something went wrong',
      });
    }
  };
};

export const editProject = (
  id,
  {
    projectName: name,
    projectClient,
    projectContract,
    projectDescription: description,
    startDate: date_start,
    endDate: date_end,
    monthlyBudget: total_budget,
    monthlyHours: budget_hours,
    owner,
    color,
    status,
    project_phase,
    budgetType,
    billable,
    kick_of_date,
    filing_deadline,
    estimated_delivery_date,
    current_credit_estimate,
    current_study,
    engagement_years,
    federal_credits_delivered,
    state_credits_delivered,
    study_name,
  },
  sortDetails
) => {
  const theme_id = isNull(projectContract) ? null : get(projectContract, 'id');
  const client_id = isNull(projectClient) ? null : get(projectClient, 'id');
  const owner_id = get(owner, 'id');
  const status_id = get(status, 'id');
  const project_phase_id = get(project_phase, 'id');
  const contract_type_id = get(budgetType, 'id');
  const federal_credits = isUndefined(federal_credits_delivered)
    ? 0
    : parseInt(federal_credits_delivered);
  const state_credits = isUndefined(state_credits_delivered)
    ? 0
    : parseInt(state_credits_delivered);
  const total_credits_delivered =
    federal_credits + state_credits > 0
      ? federal_credits + state_credits
      : undefined;
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PROJECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateProject(
        id,
        omitBy(
          {
            name,
            client_id,
            theme_id,
            description,
            date_start,
            date_end,
            total_budget,
            budget_hours,
            owner_id,
            color,
            status_id,
            project_phase_id,
            contract_type_id,
            billable,
            kick_of_date,
            filing_deadline,
            estimated_delivery_date,
            current_credit_estimate,
            engagement_years,
            current_study,
            federal_credits_delivered,
            state_credits_delivered,
            total_credits_delivered,
            // study_name
          },
          isUndefined
        )
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        resp.data.study_name = study_name; // Mock passing value for study type when API not ready
        dispatch({
          type: Types.UPDATE_PROJECT_SUCCESS,
          data: resp || {},
        });
        if (!isEmpty(sortDetails)) {
          const { page, sort, q } = sortDetails;
          await dispatch(fetchProjectsList(page, sort, q));
          NotificationHandler.open({
            message,
            operation: 'update',
            title: 'Congratulations!',
          });
        }
        if (
          isEmpty(sortDetails) &&
          (!isEmpty(total_budget) || !isEmpty(budgetType))
        ) {
          dispatch(getBudgetSpent(id));
        }
      } else {
        dispatch({
          type: Types.UPDATE_PROJECT_FAILURE,
          message: message,
        });
        // if (!isEmpty(sortDetails)) {
        NotificationHandler.open({
          operation: 'failure',
          message,
          title: 'Something went wrong',
        });
        // }
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PROJECT_FAILURE,
        message: error,
      });
      // if (!isEmpty(sortDetails)) {
      NotificationHandler.open({
        operation: 'failure',
        message: error,
        title: 'Something went wrong',
      });
      // }
    }
  };
};

export const addProjectMember = (id, data, type) => {
  const people_id = get(data, 'id');
  return async dispatch => {
    dispatch({
      type: Types.ADD_PROJECT_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const payload = {
        members: [people_id],
        type,
      };
      const resp = await addProjectMemberApi(id, payload);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ADD_PROJECT_MEMBER_SUCCESS,
          data,
          field: type,
        });
      } else {
        dispatch({
          type: Types.ADD_PROJECT_MEMBER_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ADD_PROJECT_MEMBER_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteProjectMember = (projectId, id, type) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_PROJECT_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteProjectMemberApi(projectId, id, type);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_PROJECT_MEMBER_SUCCESS,
          id,
          field: type,
        });
      } else {
        dispatch({
          type: Types.DELETE_PROJECT_MEMBER_FAILURE,
          message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
          icon: ' ',
          title: ' ',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_PROJECT_MEMBER_FAILURE,
        message: error,
      });
    }
  };
};

export const getProject = id => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PROJECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchProject(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      resp.data.study_type = 'ERTC';
      resp.data.study_name = 'ERTC ' + resp.data.engagement_years;
      if (status) {
        dispatch({
          type: Types.GET_PROJECT_SUCCESS,
          data: resp || {},
        });
        dispatch(getProjectMetadata('contract_types'));
        dispatch(getProjectMetadata('initiative_statuses'));
      } else {
        dispatch({
          type: Types.GET_PROJECT_FAILURE,
          message,
          id,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PROJECT_FAILURE,
        message: 'Something went wrong',
        id,
      });
    }
  };
};

export const deleteProject = (id, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_PROJECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removeProject(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_PROJECT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;

        await dispatch(fetchProjectsList(page, sort, q));
        NotificationHandler.open({
          title: 'Study Deleted Successfully',
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_PROJECT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
          title: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_PROJECT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
        title: 'Something went wrong',
      });
    }
  };
};

export const duplicateProject = id => {
  return async dispatch => {
    dispatch({
      type: Types.DUPLICATE_PROJECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await duplicateProjectApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.DUPLICATE_PROJECT_SUCCESS,
          data: resp || {},
        });
        history.push(`/admin/studies/${record.id}`);
        NotificationHandler.open({
          title: 'Study created Successfully',
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DUPLICATE_PROJECT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
          title: 'Something went wrong',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.DUPLICATE_PROJECT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
        title: 'Something went wrong',
      });
    }
  };
};

export const getProjectMetadata = type => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROJECT_METADATA_INPROGRESS,
      payload: { type },
    });
    try {
      const resp = await getDropdown(type);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PROJECT_METADATA_SUCCESS,
          payload: { type, data },
        });
      } else {
        dispatch({
          type: Types.FETCH_PROJECT_METADATA_FAILURE,
          payload: { type, message },
        });
      }
    } catch (e) {
      dispatch({
        type: Types.FETCH_PROJECT_METADATA_FAILURE,
        payload: { type },
      });
    }
  };
};

export const fetchProjectActivities = (id, page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROJECT_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getProjectActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PROJECT_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PROJECT_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROJECT_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreProjectActivities = (id, page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_PROJECT_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getProjectActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_PROJECT_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_PROJECT_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_PROJECT_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};
