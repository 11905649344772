import Request from './request';

export const getPartnersDashboard = () => {
  return Request.call({
    url: '/referral/dashboard',
    method: 'GET',
  });
};

export const getIndustriesList = async () => {
  return Request.call({
    url: `/referral/industries/list`,
    method: 'GET',
  });
};

export const fetchPartnerTypeList = async () => {
  return Request.call({
    url: `/close/partner-types`,
    method: 'GET',
  });
};

export const getClientIndustriesList = async () => {
  return Request.call({
    url: `/close/industries`,
    method: 'GET',
  });
};

export const createReferral = async data => {
  return Request.call({
    url: `/referral`,
    method: 'POST',
    data,
  });
};

export const getInProgressPartnerRecords = () => {
  return Request.call({
    url: '/referral/inprogress',
    method: 'GET',
  });
};

export const getSilentPartnerRecords = () => {
  return Request.call({
    url: '/referral/silent',
    method: 'GET',
  });
};

export const getDisqualifiedPartnerRecords = () => {
  return Request.call({
    url: '/referral/disqualified',
    method: 'GET',
  });
};
