import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Card, CardBody, InputGroup, UncontrolledTooltip } from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import classes from './myDocuments.module.scss';
import Nav from 'reactstrap/es/Nav';
import NavItem from 'reactstrap/es/NavItem';
import { useDispatch, useSelector } from 'react-redux';
import NoClientsImg from 'assets/img/theme/No_Clients_List.png';
import {
  fetchDocumentTypesForFY,
  fetchFinancialYears,
  syncDocument,
  undoVerifyYear,
  verifyYear,
} from 'store/actions/documents';
import get from 'lodash/get';
import Loading from 'components/Loading';
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import DocumentDropzone from './DocumentDropzone';
import { useHistory, useLocation } from 'react-router-dom';
import DocumentPreviewModal from './DocumentPreviewModal';
import queryString from 'query-string';
import { updateActiveTourStatus } from 'store/actions/profile';
import useStartTour from 'components/QuickStartTour/useStartTour';
import useFinch from 'helpers/useFinch';
import { getCompanyDetails } from 'store/actions/company';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  fetchCodatConnectURL,
  fetchFinchDetails,
} from 'store/actions/onboarding';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import SubmitDocuments from './SubmitDocuments';
import socket from 'helpers/socket';
import { useAccess, permissions } from 'helpers/permission';

const MyDocuments = ({ companyId }) => {
  const dispatch = useDispatch();
  const [activeYear, setActiveYear] = useState(null);
  const startOnboardingTour = useStartTour();
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isSubmitModalOpen, setSubmitModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { search: queryParams } = location;
  const queryProps = queryString.parse(queryParams);
  const [modalData, setModalData] = useState({});
  const payrollProvider = useGetFieldFromObjects(
    'company',
    'details.data.payroll_provider'
  );

  const accountProvider = useGetFieldFromObjects(
    'company',
    'details.data.accounting_provider'
  );
  const [finchIntegration, setFinchIntegration] = useState(0);
  const showSuccessAlert = ({
    title = 'Connection Successful!',
    confirmBtnText = 'OK, got it!',
    text,
  }) => {
    AlertPopupHandler.openCustom({
      type: 'success',
      warning: false,
      success: true,
      showCancel: false,
      onConfirm: () => {},
      title,
      confirmBtnText,
      confirmBtnBsStyle: 'success',
      text,
    });
  };

  useEffect(() => {
    dispatch(getCompanyDetails(false));
  }, [dispatch]);
  const isClient = useSelector(({ auth }) => auth.user.is_client);

  const years = useSelector(({ documents }) =>
    get(documents, 'yearsState.data', [])
  );
  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );
  const isFetchingYears = useSelector(({ documents }) =>
    get(documents, 'yearsState.isInProgress')
  );
  const isFetchingDocs = useSelector(({ documents }) =>
    get(documents, `documentTypes.data.${activeYear}.isInProgress`)
  );

  const documents = useSelector(({ documents }) =>
    activeYear
      ? get(documents, `documentTypes.data.${activeYear.year}.data.data`, [])
      : []
  );

  const refetchYear = useSelector(({ documents }) =>
    get(documents, 'syncDocument.refetchYear', null)
  );

  const fetchDocumentsSuccess = useSelector(({ documents }) =>
    get(documents, 'documentTypes.status', 0)
  );

  useEffect(() => {
    if (!isClient && !companyId) {
      return history.push('/admin/dashboard');
    }
    const getData = async () => {
      const { status, data } = await dispatch(fetchFinancialYears(companyId));
      if (status && data.length) {
        setActiveYear(data[0]);
      }
    };
    getData();
    socket.listenForSyncDataComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refetchYear && activeYear?.year === refetchYear) {
      dispatch(fetchDocumentTypesForFY(activeYear, companyId, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchYear]);

  const { open } = useFinch(
    async ({ code }) => {
      const success = await dispatch(fetchFinchDetails(code));
      if (success) {
        let documentId = localStorage.getItem('documentId');
        localStorage.removeItem('documentId');
        await dispatch(syncDocument(documentId, 'finch', true));
        await dispatch(getCompanyDetails(false));
        setFinchIntegration(1);
        showSuccessAlert({
          text: `You have successfully connected with your payroll software!`,
        });
      }
    },
    error => {
      /* eslint-disable */
      console.log('@@@ failed connecting to finch : ', error);
      /* eslint-enable */
      AlertPopupHandler.openCustom({
        type: 'warning',
        warning: true,
        showCancel: false,
        onConfirm: () => {
          open();
        },
        title: 'Integration Unsuccessful',
        confirmBtnText: 'Try Again',
        confirmBtnBsStyle: 'warning pl-5 pr-5',
        text: `Looks like integration was unsuccessful. Please try connecting again.`,
      });
    }
  );

  useEffect(() => {
    return () => {
      dispatch(
        updateActiveTourStatus({
          ...quickStartTour,
          activeYear: null,
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeYear]);

  useEffect(() => {
    if (activeYear) {
      if (quickStartTour) {
        dispatch(
          updateActiveTourStatus({
            ...quickStartTour,
            activeYear: activeYear.year,
          })
        );
      }
      dispatch(fetchDocumentTypesForFY(activeYear, companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeYear, dispatch, finchIntegration]);

  useEffect(() => {
    if (
      fetchDocumentsSuccess &&
      quickStartTour.isRedirected &&
      documents.length
    ) {
      startOnboardingTour(documents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, fetchDocumentsSuccess]);

  const handleActiveYearClick = year => () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      (quickStartTour.activeTour === 'general_ledger_payroll' ||
        quickStartTour.activeTour === 'tax_returns')
    ) {
      return false;
    }
    setActiveYear(year);
  };

  const NoDataIndication = () => {
    return (
      <div className={cs('d-flex align-items-center', classes.noDataWrapper)}>
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          <img
            className={cs('m-auto w-100', classes.image)}
            src={NoClientsImg}
            alt="No Clients"
          />
          <h4 className="display-4 mb-0 text-center px-2 mt-2">
            No Data Available
          </h4>
        </div>
      </div>
    );
  };

  const updateModalData = document => () => {
    if (document.documents.length > 0) {
      setModalData(document);
      setIsDocumentModalOpen(!isDocumentModalOpen);
    }
  };

  const handleCloseModal = () => {
    setIsDocumentModalOpen(false);
    history.push(`?`);
  };

  const isSubmitButtonDisabled = () => {
    if (!documents?.length) return true;
    const generalLedger = documents.find(document => {
      return document.short_name === 'General Ledger';
    });
    return !generalLedger?.documents.length;
  };

  const hasMissingDocForType = name => {
    if (!documents?.length) return false;
    const doc = documents.find(document => {
      return document.short_name === name;
    });
    return !doc?.documents.length;
  };

  const handleSubmitYear = () => {
    setSubmitModalOpen(true);
  };

  const handleVerifyYear = async () => {
    const documentIds = documents.map(document => document.id);
    setLoading(true);
    await dispatch(verifyYear(activeYear.id, documentIds));
    await dispatch(fetchDocumentTypesForFY(activeYear, companyId));
    setLoading(false);
  };

  const handleUndoVerifyYear = async () => {
    const documentIds = documents.map(document => document.id);
    setLoading(true);
    await dispatch(undoVerifyYear(activeYear.id, documentIds));
    await dispatch(fetchDocumentTypesForFY(activeYear, companyId));
    setLoading(false);
  };

  const renderSubmitButton = () => {
    const isAdminWithoutDocuments = !isClient && !activeYear?.status;
    if (
      (activeYear?.status && activeYear.status === 'Pending Review') ||
      isAdminWithoutDocuments
    ) {
      if (isClient) {
        return <span className={classes.pendingButton}>Pending Review</span>;
      }
      return (
        <Button
          color="primary"
          size="sm"
          className={cs(classes.reviewButton, classes.verify)}
          loading={loading}
          disabled={loading || isAdminWithoutDocuments}
          onClick={handleVerifyYear}
        >
          <i className="fas fa-unlock" />
          {`Verify ${activeYear.year} Documents`}
        </Button>
      );
    }
    if (activeYear?.status && activeYear.status === 'Verified') {
      if (isClient) {
        return (
          <span className={cs(classes.pendingButton, classes.verified)}>
            Verified
          </span>
        );
      }
      return (
        <Button
          color="primary"
          size="sm"
          className={cs(classes.reviewButton, classes.undo)}
          loading={loading}
          disabled={loading}
          onClick={handleUndoVerifyYear}
        >
          <span>
            <i className="fas fa-undo" />
            Undo Verification
          </span>
        </Button>
      );
    }
    if (isSubmitButtonDisabled()) {
      return (
        <>
          <span
            className={classes.disabledButton}
            id="submitYearButton"
          >{`Submit ${activeYear.year} For Review`}</span>
          <UncontrolledTooltip
            delay={0}
            target="submitYearButton"
            placement="bottom-center"
            boundariesElement="viewport"
          >
            {`You can't submit ${activeYear.year} documents until you've uploaded at least one document.`}
          </UncontrolledTooltip>
        </>
      );
    }
    return (
      <Button
        id="submitYearButton"
        color="primary"
        size="sm"
        className={classes.reviewButton}
        loading={loading}
        disabled={loading}
        onClick={handleSubmitYear}
      >{`Submit ${activeYear.year} For Review`}</Button>
    );
  };

  const getIntegrationType = name => {
    if (!['General Ledger', '1099s', 'Employee Payroll Reports'].includes(name))
      return null;
    if (name === 'Tax Returns') return null;
    if (name === 'General Ledger') {
      return 'codat';
    }
    return 'finch';
  };

  const isAllowedFinchIntegration = useAccess([
    permissions.ENABLE_PAYROLL_REPORT_INTEGRATION,
  ]);
  const isAllowedCodatIntegration = useAccess([
    permissions.ENABLE_GENERAL_LEDGER_INTEGRATION,
  ]);
  const showIntegrateButton = doc => {
    const integrationType = getIntegrationType(doc.short_name);
    if (!integrationType) return false;
    return (
      (integrationType === 'finch' &&
        !payrollProvider &&
        isAllowedFinchIntegration) ||
      (integrationType === 'codat' &&
        !accountProvider &&
        isAllowedCodatIntegration)
    );
  };

  const checkIsIntegrated = doc => {
    const integrationType = getIntegrationType(doc.short_name);
    if (!integrationType) return false;
    return (
      (integrationType === 'finch' && payrollProvider) ||
      (integrationType === 'codat' && accountProvider)
    );
  };

  const handleIntegration = doc => async () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      (quickStartTour.activeTour === 'general_ledger_payroll' ||
        quickStartTour.activeTour === 'tax_returns')
    ) {
      return false;
    }
    localStorage.setItem('documentId', doc.id);
    if (['1099s', 'Employee Payroll Reports'].includes(doc.short_name)) {
      open();
    }
    if (['General Ledger'].includes(doc.short_name)) {
      localStorage.setItem(
        'codatRedirect',
        location.pathname + location.search
      );
      const url = await dispatch(fetchCodatConnectURL());
      if (url) {
        window.location.href = url;
      }
    }
  };

  const newDocuments = () => {
    if (!documents || !documents.length) return null;
    let count = 0;
    documents.forEach(folder => {
      const { documents: folderDocuments = [] } = folder;
      folderDocuments.forEach(doc => {
        if (doc.is_new) {
          count++;
        }
      });
    });
    return count;
  };

  if (isFetchingYears || isFetchingDocs) {
    return <Loading />;
  }

  return (
    <>
      <div className={classes.clients}>
        <div>
          {isClient && (
            <div className="d-inline-block page-title">
              <CardsHeader
                isRoot={true}
                currentPage="My Documents"
                childName={`${activeYear?.year || ''} Documents`}
              />
            </div>
          )}
          {isClient && (
            <div className={cs(classes.searchWrapper, 'px-4 py-3')}>
              <InputGroup className="input-group-sm ml-5 search-button">
                <div className="search-box">
                  <i className="fa fa-search" aria-hidden="true"></i>
                  <Input Placeholder="     Search documents...."></Input>
                </div>
              </InputGroup>
            </div>
          )}
        </div>
        <div className="px-4">
          <Card>
            <CardBody className="p-0 d-flex flex-column">
              {!activeYear || !years ? (
                <NoDataIndication />
              ) : (
                <div className="d-flex">
                  <div
                    className={cs(
                      classes.yearsList,
                      !isClient && classes.adminView
                    )}
                    id="financialYears"
                  >
                    <Nav>
                      {years.map(y => {
                        return (
                          <NavItem
                            key={y.id}
                            className={cs(classes.year, {
                              [classes.active]: activeYear.id === y.id,
                            })}
                            onClick={handleActiveYearClick(y)}
                          >
                            <i
                              className={cs('fas', 'fa-check', {
                                [classes.pendingYear]:
                                  y.status && y.status === 'Pending Review',
                                [classes.verifiedYear]:
                                  y.status && y.status === 'Verified',
                                [classes.activeYear]: !y.status,
                              })}
                            />
                            {y.year}
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>
                  {documents ? (
                    <div className={cs(classes.details, !isClient && 'pt-2')}>
                      <div className={classes.documentHeader}>
                        <div className={classes.titleWrapper}>
                          <h2 className={classes.heading}>
                            {activeYear.year} Documents
                            {!isClient &&
                              newDocuments() > 0 &&
                              activeYear.status !== 'Verified' && (
                                <>
                                  <span className={classes.separator}>-</span>
                                  <span
                                    className={classes.newDocHeader}
                                  >{`REVIEW NEW ${
                                    newDocuments() > 1
                                      ? 'DOCUMENTS'
                                      : 'DOCUMENT'
                                  }`}</span>
                                </>
                              )}
                            {activeYear?.status === 'Pending Review' &&
                              newDocuments() <= 0 && (
                                <>
                                  <span className={classes.separator}>-</span>
                                  <span className={classes.verifiedHeader}>
                                    PENDING REVIEW
                                  </span>
                                </>
                              )}
                            {activeYear?.status === 'Verified' && (
                              <>
                                <span className={classes.separator}>-</span>
                                <span className={classes.verifiedHeader}>
                                  <i className="fas fa-lock mr-1" />
                                  VERIFIED
                                </span>
                              </>
                            )}
                          </h2>
                          <div className={classes.subheading}>
                            The security of your data is our highest priority
                            and all files stored in this Strike portal are
                            encrypted on transfer and storage. To get started,
                            we will need the following documents.
                          </div>
                        </div>
                        {renderSubmitButton()}
                      </div>
                      <div className={classes.documentGrid}>
                        {documents.map((document, i) => (
                          <div
                            key={i}
                            onClick={updateModalData(document)}
                            className={cs({
                              [classes.otherDocument]: document.name.includes(
                                'Other'
                              ),
                            })}
                          >
                            <DocumentDropzone
                              key={i}
                              document={document}
                              index={i}
                              activeYear={activeYear}
                              documentModal={updateModalData}
                              showIntegrate={showIntegrateButton(document)}
                              handleIntegration={handleIntegration(document)}
                              isIntegrated={checkIsIntegrated(document)}
                            />
                          </div>
                        ))}
                      </div>
                      <DocumentPreviewModal
                        storyId={modalData.id}
                        activeYear={activeYear}
                        documentData={modalData}
                        title={
                          <div className="d-flex">
                            <i className="fas fa-folder-open" />
                            <div className="mr-2 ml-2">{activeYear.year}</div>
                            <div>{modalData.short_name}</div>
                          </div>
                        }
                        isOpen={isDocumentModalOpen}
                        closeModal={handleCloseModal}
                        currentTab={get(queryProps, 'tab')}
                        companyId={companyId}
                      />
                    </div>
                  ) : (
                    <NoDataIndication />
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      {isSubmitModalOpen && (
        <SubmitDocuments
          activeYear={activeYear}
          hasMissingEmployeeReports={hasMissingDocForType(
            'Employee Payroll Reports'
          )}
          hasMissing1099s={hasMissingDocForType('1099s')}
          hasTaxReturns={!hasMissingDocForType('Tax Returns')}
          documents={documents}
          isModalOpen={isSubmitModalOpen}
          closeModal={() => setSubmitModalOpen(false)}
          title={`Let’s confirm a few things before you submit ${activeYear?.year} documents!`}
        />
      )}
    </>
  );
};

export default MyDocuments;
