import React from 'react';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import DocumentActivity from './DocumentActivity';

const ActivityModal = ({
  isOpen,
  toggle,
  storyId,
  activeYear,
  documentData,
}) => {
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      title="View History"
      scrollable={true}
    >
      <ModalBody>
        <div className="p-1">
          <div className="d-flex align-items-center mb-3">
            <i className="fas fa-folder-open" />
            <div className="mr-2 ml-2">{activeYear.year}</div>
            <div>{documentData.short_name}</div>
          </div>
          <DocumentActivity documentTypeId={documentData.id} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ActivityModal;
