import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Row } from 'reactstrap';
import classes from './CreditBank.module.scss';
import FormikInput from 'components/FormFields/Input/FormikInput';
import get from 'lodash/get';
import RSelect from 'components/FormFields/RSelect';
import { Formik, Form } from 'formik';
import className from 'classnames';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchYears,
  fetchContactTerms,
  fetchQuarters,
  fetchEntityTypes,
} from 'store/actions/metadata';
import { getOptions } from 'helpers/clientProfile';
import { getValueFromOptions } from 'helpers/dropdownHelpers';
import TaxCredits from 'views/pages/ClientProfile/CreditBank/TaxCredits';
import EstimatedPayments from 'views/pages/ClientProfile/CreditBank/EstimatedPayments';
import Scope from 'views/pages/ClientProfile/CreditBank/Scope';
import Button from 'components/Button';

const schema = Yup.object().shape({
  entity_name: Yup.object()
    .nullable()
    .required('Required'),
  year_engaged: Yup.object()
    .nullable()
    .required('Required'),
  quarter_engaged: Yup.object()
    .nullable()
    .required('Required'),
  scope_state_start_year: Yup.object()
    .nullable()
    .required('Required'),
  scope_state_end_year: Yup.object()
    .nullable()
    .required('Required'),
  scope_federal_start_year: Yup.object()
    .nullable()
    .required('Required'),
  scope_federal_end_year: Yup.object()
    .nullable()
    .required('Required'),
  state_credit: Yup.string().required('State Credit is required'),
  contract_terms_option1: Yup.object()
    .nullable()
    .required('Required'),
  contract_terms_option2: Yup.object()
    .nullable()
    .required('Required'),
  credit_amounts: Yup.array(),
  estimated_payments: Yup.array(),
});

const CreditBank = ({ submitForm }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYears());
    dispatch(fetchContactTerms());
    dispatch(fetchQuarters());
    dispatch(fetchEntityTypes());
  }, [dispatch]);

  const clientProfileData = useSelector(({ clientProfile }) =>
    get(clientProfile, 'clientProfile.data')
  );

  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const contactTerms = useSelector(({ metadata }) =>
    get(metadata, 'contactTerms.data', {})
  );

  const quartersOptions = useSelector(({ metadata }) =>
    get(metadata, 'quarters.data', {})
  );

  const entityTypes = useSelector(({ metadata }) =>
    get(metadata, 'entityTypes.data', {})
  );

  const renderCreditBankForm = ({
    setFieldValue,
    values,
    isValid,
    handleSubmit,
  }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Row className={classes.row}>
          <div className={classes.textInputRightMargin}>
            <RSelect
              name="entity_type"
              label="Entity Type"
              value={values.entity_type}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              options={getOptions(entityTypes)}
              onChange={value => {
                setFieldValue('entity_type', value);
              }}
            />
          </div>
          <div className={className('flex-row', classes.textInputLeftMargin)}>
            <div className={classes.textInputRightMargin}>
              <RSelect
                name="year_engaged"
                label="Year Engaged"
                getOptionLabel={opt => opt.label}
                getOptionValue={opt => opt.value}
                options={getOptions(yearsList)}
                value={values.year_engaged}
                onChange={value => {
                  setFieldValue('year_engaged', value ? value : null);
                }}
              />
            </div>
            <div className={classes.textInputLeftMargin}>
              <RSelect
                name="quarter_engaged"
                label="Quarter Engaged"
                getOptionLabel={opt => opt.label}
                getOptionValue={opt => opt.value}
                options={getOptions(quartersOptions)}
                value={values.quarter_engaged}
                onChange={value => {
                  setFieldValue('quarter_engaged', value ? value : null);
                }}
              />
            </div>
          </div>
        </Row>
        <Row className={className('mb-0', classes.row)}>
          <div className={className('mb-0', classes.textInputRightMargin)}>
            <Scope
              type="state"
              label="State"
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className={className('mb-0', classes.textInputLeftMargin)}>
            <Scope
              type="federal"
              label="Federal"
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </Row>
        <Row className={className('mb-0 align-items-end', classes.row)}>
          <div className={className('mb-0', classes.textInputRightMargin)}>
            <Row className={classes.row}>
              <FormikInput
                name="state_credit"
                label="State Credit"
                type="text"
                groupClassName={className('mr-0', classes.textInputRightMargin)}
              />
            </Row>
          </div>
          <div className={classes.textInputLeftMargin}>
            <Row className={className('mb-0 font-weight-bold', classes.row)}>
              Contract Terms
            </Row>
            <Row className={classes.row}>
              <div className={classes.textInputRightMargin}>
                <RSelect
                  name="contract_terms_option1"
                  label="Option 1"
                  getOptionLabel={opt => opt.label}
                  getOptionValue={opt => opt.value}
                  options={getOptions(contactTerms)}
                  value={values.contract_terms_option1}
                  onChange={value => {
                    setFieldValue(
                      'contract_terms_option1',
                      value ? value : null
                    );
                  }}
                />
              </div>
              <div className={classes.textInputLeftMargin}>
                <RSelect
                  name="contract_terms_option2"
                  label="Option 2"
                  getOptionLabel={opt => opt.label}
                  getOptionValue={opt => opt.value}
                  options={getOptions(contactTerms)}
                  value={values.contract_terms_option2}
                  onChange={value => {
                    setFieldValue(
                      'contract_terms_option2',
                      value ? value : null
                    );
                  }}
                />
              </div>
            </Row>
          </div>
        </Row>
        <Row className={className('mb-0', classes.row)}>
          <TaxCredits values={values} setFieldValue={setFieldValue} />
          <EstimatedPayments values={values} setFieldValue={setFieldValue} />
        </Row>
        <div className="w-100 d-flex justify-content-end">
          <Button disabled={!isValid} color="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div className="ml-4 mr-4">
      <Card className={classes.card}>
        <CardHeader
          className={className('h3 font-weight-bold', classes.cardHeader)}
        >
          Credit Bank
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              ...clientProfileData,
              associated_entity:
                clientProfileData.associated_entity &&
                clientProfileData.associated_entity.length
                  ? clientProfileData.associated_entity?.split(',')
                  : [],
              entity_type: clientProfileData.entity_type
                ? getValueFromOptions(
                    entityTypes,
                    clientProfileData.entity_type
                  )
                : null,
              year_engaged: clientProfileData.year_engaged
                ? getValueFromOptions(yearsList, clientProfileData.year_engaged)
                : null,
              quarter_engaged: clientProfileData.quarter_engaged
                ? getValueFromOptions(
                    quartersOptions,
                    clientProfileData.quarter_engaged
                  )
                : null,
              scope_state_start_year: clientProfileData.scope_state_start_year
                ? getValueFromOptions(
                    yearsList,
                    clientProfileData.scope_state_start_year
                  )
                : null,
              scope_state_end_year: clientProfileData.scope_state_end_year
                ? getValueFromOptions(
                    yearsList,
                    clientProfileData.scope_state_end_year
                  )
                : '',
              scope_federal_start_year: clientProfileData.scope_federal_start_year
                ? getValueFromOptions(
                    yearsList,
                    clientProfileData.scope_federal_start_year
                  )
                : '',
              scope_federal_end_year: clientProfileData.scope_federal_end_year
                ? getValueFromOptions(
                    yearsList,
                    clientProfileData.scope_federal_end_year
                  )
                : '',
              state_credit: clientProfileData.state_credit
                ? clientProfileData.state_credit
                : '',
              contract_terms_option1: clientProfileData.contract_terms_option1
                ? getValueFromOptions(
                    contactTerms,
                    clientProfileData.contract_terms_option1
                  )
                : '',
              contract_terms_option2: clientProfileData.contract_terms_option2
                ? getValueFromOptions(
                    contactTerms,
                    clientProfileData.contract_terms_option2
                  )
                : '',
              credit_amounts: clientProfileData.credit_amounts
                ? clientProfileData.credit_amounts.map(c => ({
                    ...c,
                    year: getValueFromOptions(yearsList, c.year),
                  }))
                : [],
              estimated_payments: clientProfileData.estimated_payments
                ? clientProfileData.estimated_payments.map(e => ({
                    ...e,
                    year: getValueFromOptions(yearsList, e.year),
                    quarter: getValueFromOptions(quartersOptions, e.quarter),
                  }))
                : [],
            }}
            enableReinitialize
            // validationSchema={schema}
            onSubmit={values => submitForm(values)}
          >
            {renderCreditBankForm}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreditBank;
