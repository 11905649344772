import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import get from 'lodash/get';
import routes from 'routes.js';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import history from 'helpers/history';
import { withRouter } from 'react-router';
import includes from 'lodash/includes';
import some from 'lodash/some';

class Auth extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    const location = get(this.props, 'location.pathname');
    if (
      this.props.isActiveSession === true &&
      !some(['invite', 'company', 'verify', 'codat'], path =>
        includes(location, path)
      ) &&
      location !== '/auth/subscription/outdated'
    ) {
      history.push('/admin/my-documents');
    }
  }

  componentDidUpdate(e) {
    if (get(e.history, 'location.pathname') !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AuthNavbar
            login
            register={includes(this.props.location.pathname, 'register')}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isActiveSession: state.auth.isActiveSession,
  };
}

function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
