import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Col, Container, Row } from 'reactstrap';
import classes from './AuthHeader.module.scss';
import cs from 'classnames';
class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className={cs('header py-7 py-lg-8 pt-lg-9', classes.header)}>
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {this.props.title ? (
                    <h1 className={cs('text-white', this.props.titleClassName)}>
                      {this.props.title}
                    </h1>
                  ) : null}
                  {this.props.lead ? (
                    <p className="text-lead text-white">{this.props.lead}</p>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className={classes.separator}
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lead: PropTypes.string,
  titleClassName: PropTypes.string,
};

export default AuthHeader;
