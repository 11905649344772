import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputGroup, Input, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { debounce } from 'lodash';
import classes from './table.module.scss';
import get from 'lodash/get';

const Search = props => {
  const handleSearch = e => {
    // persisting event as we will make a call after 500ms
    e.persist();
    delayedSearch(e);
  };

  const searchInputChanged = e => {
    // actual call to the search will be made from here
    props.onSearch(e.target.value);
  };

  // debouncing search function so that we don't make a call on each key down event
  const delayedSearch = debounce(searchInputChanged, 500);
  return (
    <InputGroup
      className={classNames('input-group-sm ml-2', classes.searchInput)}
    >
      <Input onChange={handleSearch} />
    </InputGroup>
  );
};

// TODO: Need to change this with illustration created by design team
const NoRecordsFound = () => (
  <div className="d-flex justify-content-between align-items-center flex-column w-100">
    <span className="display-4">No records found!</span>
  </div>
);

const Table = ({
  keyField,
  search,
  data,
  columns,
  paginationOptions,
  noDataIndication: NoDataIndication,
  noSearchFound = NoRecordsFound,
  onTableChange,
  id,
  headerButtonText,
  headerButtonAction,
  headerButtonClassName,
  showHeaderWithNoData = false,
  className,
  titleWithNoData = '',
  sizePerPage = 50,
  ...rest
}) => {
  const componentRef = useRef(null);
  const [isSearched, setSearched] = useState(false);
  const [isInitialCall, setInitialCall] = useState(true);

  const fetchData = async () => {
    const { defaultSorted } = rest;
    const initialParams = {
      sortOrder: get(defaultSorted, '[0].order'),
      sortField: get(defaultSorted, '[0].dataField'),
      filters: {},
      page: 1,
      sizePerPage: 50,
      searchText: '',
      data: [],
    };
    // sending event type init on first fetch request
    await onTableChange('init', initialParams);
    setInitialCall(false);
  };

  // we are using this method to make a initial API call, as we can't use async/await in effect
  // calling another function from it on initial render
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isSearched && !data.length && !rest.loading && !showHeaderWithNoData ? (
        <NoDataIndication />
      ) : (
        <ToolkitProvider
          data={data}
          keyField={keyField}
          columns={columns}
          search={search}
        >
          {props => {
            return (
              <>
                <div
                  className={classNames(
                    classes.tableHeader,
                    !search && classes.withoutSearchHeader,
                    !search && !headerButtonText && classes.withoutHeader
                  )}
                >
                  {search && !titleWithNoData ? (
                    <div
                      className={classNames(
                        'd-flex align-items-center',
                        classes.tableSearch
                      )}
                    >
                      <span>Search: </span>
                      <Search {...props.searchProps} />
                    </div>
                  ) : (
                    <div className={classes.titleWithNoData}>
                      {titleWithNoData}
                    </div>
                  )}
                  {headerButtonText && (
                    <Button
                      onClick={headerButtonAction}
                      className={headerButtonClassName}
                    >
                      {headerButtonText}
                    </Button>
                  )}
                </div>

                <div id={id}>
                  <BootstrapTable
                    wrapperClasses={classNames('table-responsive', className)}
                    headerClasses={classes.tableHeaderSection}
                    ref={componentRef}
                    {...props.baseProps}
                    {...(paginationOptions && data.length > 0
                      ? {
                          pagination: paginationFactory({
                            sizePerPage: sizePerPage,
                            alwaysShowAllBtns: true,
                            showTotal: false,
                            withFirstAndLast: false,
                            hideSizePerPage: true,
                            ...paginationOptions,
                          }),
                        }
                      : {})}
                    noDataIndication={
                      (props.searchProps.searchText && data.length === 0) ||
                      isSearched
                        ? noSearchFound
                        : NoDataIndication
                    }
                    onTableChange={async (type, params) => {
                      // if we do not return from here, on initial render it will call API two times.
                      // First is from out useEffect and after then from table
                      // so to avoid that we are returning from here
                      if (isInitialCall) {
                        return;
                      }
                      if (type === 'search') {
                        setSearched(true);
                        // when searching making sure query has page 1 so updating params
                        params = {
                          ...params,
                          page: 1,
                        };
                      }
                      if (type === 'sort') {
                        // when sorting making sure query has page 1 so updating params
                        params = {
                          ...params,
                          page: 1,
                        };
                      }
                      await onTableChange(type, params);
                      if (!params.searchText && type === 'search') {
                        setSearched(false);
                      }
                    }}
                    overlay={overlayFactory({
                      spinner: true,
                      // className: classes.loading,
                      styles: {
                        overlay: base => ({
                          ...base,
                          background: 'rgba(0, 0, 0, 0.1)',
                        }),
                      },
                    })}
                    {...rest}
                  />
                </div>
              </>
            );
          }}
        </ToolkitProvider>
      )}
    </>
  );
};

Table.propTypes = {
  keyField: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  paginationOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  search: PropTypes.bool,
  onTableChange: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  columns: [],
  paginationOptions: {},
  search: true,
  onTableChange: () => {},
};

export default Table;
