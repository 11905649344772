import React, { useState, useCallback, useEffect } from 'react';
import { Col } from 'reactstrap';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './LeadModal.module.scss';
import classnames from 'classnames';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import PartnerDetails from './PartnerDetails';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Badge from 'reactstrap/lib/Badge';
import orderBy from 'lodash/orderBy';
import {
  fetchPartnerActivities,
  fetchReferralComments,
  clearReferralCommentsData,
} from 'store/actions/partners';
import socket from 'helpers/socket';
import { useLocation } from 'react-router';
import queryString from 'query-string';

const clientStudyStatus = [
  {
    label: 'Document Gathering',
    stage: 1,
  },
  {
    label: 'Calculations',
    stage: 2,
  },
  {
    label: 'Credits Delivered',
    stage: 3,
  },
  {
    label: 'Final Reports',
    stage: 4,
  },
];

const LeadModal = ({
  isOpen,
  closeModal,
  currentTab: parentCurrentTab,
  onTabChange,
  comment = '',
  parentId,
  leadId,
  modalType,
}) => {
  const dispatch = useDispatch();
  const [createdOn, setCreatedOn] = useState(undefined);
  const [updatedOn, setUpdatedOn] = useState(undefined);
  const isMobile = useBreakPoint('xs', 'down');

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const isDataFetchInProgress = useSelector(({ partner }) =>
    get(partner, 'activities.isInProgress', false)
  );

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const user = useSelector(({ auth }) => auth.user);

  const partnerData = useSelector(({ partner }) =>
    get(partner, 'activities.data', {})
  );

  const activities = useSelector(({ partner }) =>
    get(partner, 'activities.data.activities', [])
  );

  useEffect(() => {
    const isPartnerManager = user.roles.includes('Partner_Manager');

    dispatch(fetchPartnerActivities(leadId));
    dispatch(
      fetchReferralComments(parentId, '', 'Company', modalType, leadId, '')
    );

    if (isPartnerManager) {
      socket.joinAndListenComments('ClosePartner', null);
    }
    socket.joinAndListenComments('Company', parentId);

    return () => {
      socket.leaveComments();
      dispatch(clearReferralCommentsData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId]);

  useEffect(() => {
    if (activities.length) {
      const orderedData = orderBy(activities, ['date_created'], 'desc');
      const lastUpdateDate = moment(orderedData[0]?.date_created)
        .tz(userTimezone)
        .format('MMMM DD, YYYY');
      const createdDate = moment(
        orderedData[orderedData.length - 1]?.date_created
      )
        .tz(userTimezone)
        .format('MMMM DD, YYYY');
      setUpdatedOn(lastUpdateDate);
      setCreatedOn(createdDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <div className={classes.scrollBar} id="storyModal-leftColumn">
          {children}
        </div>
      );
    },
    [isMobile]
  );

  const handleModalClose = () => {
    closeModal();
  };

  const getStudyStage = status => {
    switch (status) {
      case 'Document Gathering':
        return 1;
      case 'Calculation':
        return 2;
      case 'Credits Delivered':
        return 3;
      case 'Final Reports (Half)':
        return 3.5;
      case 'Final Reports':
        return 4;
      case 'Paid':
        return 5;
      default:
        return 0;
    }
  };

  return (
    <Modal
      size="lg"
      scrollable
      fade={false}
      title={
        <div className="d-flex align-items-center">
          {!isDataFetchInProgress && <span>{partnerData?.client_name}</span>}
        </div>
      }
      toggle={handleModalClose}
      isOpen={isOpen}
      className={classes.modalWrapper}
    >
      <ModalBody
        className={classnames(
          'p-0 d-flex flex-row flex-wrap',
          classes['story-modal'],
          { [classes['story-modal-mobile']]: isMobile }
        )}
        id="storyModal-body"
      >
        {isDataFetchInProgress ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <ConditionallyRenderScrollBar>
              <Col className={classnames(classes['left-column'], 'py-3')}>
                {modalType === 'client' && partnerData?.client_data && (
                  <div className={classes.clientDetails}>
                    <h5>Study Status</h5>
                    <div className={classes.studyDetails}>
                      {partnerData.client_data.studies.map((study, index) => {
                        return (
                          <div
                            key={index}
                            className={classes.studyDetailsWrapper}
                          >
                            <div className={classes.studyType}>
                              <h3>{study?.name} Study</h3>
                            </div>
                            <div className={classes.studyProgression}>
                              {clientStudyStatus.map(({ label, stage }, i) => {
                                return (
                                  <div
                                    key={stage}
                                    className={classes.barWrapper}
                                  >
                                    {index === 0 && <p>{label}</p>}
                                    <div
                                      className={classnames(classes.bar, {
                                        [classes.firstChild]: i === 0,
                                        [classes.lastChild]: i === 3,
                                        [classes.activeBar]:
                                          stage <= getStudyStage(study?.status),
                                        [classes.completedStatus]:
                                          stage ===
                                          getStudyStage(study?.status),
                                        [classes.halfReport]:
                                          getStudyStage(study?.status) ===
                                            3.5 && stage > 3,
                                        [classes.onHoldBar]:
                                          study.status === 'Hold',
                                      })}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className={classnames(classes.paidInFull, {
                                [classes.fullBar]:
                                  getStudyStage(study?.status) === 5,
                              })}
                            >
                              {index === 0 && <p>Paid in Full</p>}
                              <div></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div
                  className={classnames(classes.details, classes.badgeWrapper)}
                >
                  <div>
                    <h5>Client Name</h5>
                    <p>{partnerData?.client_name}</p>
                  </div>
                  <Badge>
                    {partnerData?.activity_status === 'untouched'
                      ? 'Received'
                      : partnerData?.activity_status}
                  </Badge>
                </div>
                <div className={classes.details}>
                  <h5>Contact Name</h5>
                  <p>{partnerData?.contacts || '-'}</p>
                </div>
                <div className={classes.details}>
                  <h5>Introduction Date</h5>
                  <p>
                    {partnerData?.introduction_date
                      ? moment(partnerData?.introduction_date)
                          .tz(userTimezone)
                          .format('MM/DD/YYYY')
                      : '-'}
                  </p>
                </div>
                <div className={classes.details}>
                  <h5>Tax Credit Specialist</h5>
                  <p>{partnerData?.tax_credit_specialist || '-'}</p>
                </div>
                <div className={classes.details}>
                  <h5>Opportunity Type</h5>
                  <p>{partnerData?.oppotunity_type || '-'}</p>
                </div>
              </Col>
            </ConditionallyRenderScrollBar>
            <PartnerDetails
              type={modalType}
              parentType={'Company'}
              currentTab={parentCurrentTab}
              comment={comment}
              updatedOn={updatedOn}
              createdOn={createdOn}
              onTabChange={onTabChange}
              leadId={leadId}
              parentId={queryProps?.parent || parentId}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

LeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments', 'activity', 'info']),
  comment: PropTypes.number,
};

LeadModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  onTabChange: () => {},
  currentTab: 'comments',
};
export default LeadModal;
