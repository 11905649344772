import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import Activity from 'components/Activity';
import Loading from 'components/Loading';
import {
  fetchDocumentActivity,
  fetchMoreDocumentActivity,
} from 'store/actions/documents';
import classes from '../Tabs.module.scss';

const DocumentActivity = ({ documentTypeId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const activities = useSelector(({ documents }) =>
    get(documents, 'activities.data.data', [])
  );
  const activitiesMeta = useSelector(({ documents }) =>
    get(documents, 'activities.data.meta', {})
  );
  const isInProgress = useSelector(({ documents }) =>
    get(documents, 'activities.isInProgress', false)
  );

  useEffect(() => {
    if (documentTypeId) {
      dispatch(fetchDocumentActivity(documentTypeId, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeId]);

  const hasMore =
    activitiesMeta.current_page &&
    activitiesMeta.last_page &&
    activitiesMeta.current_page < activitiesMeta.last_page;

  const loadMoreActivities = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      await dispatch(
        fetchMoreDocumentActivity(
          documentTypeId,
          parseInt(activitiesMeta.current_page) + 1
        )
      );
      setIsLoading(false);
    }
  };

  if (isInProgress) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-start">
        <Loading key="loader" size={100} />
      </div>
    );
  }
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div
        className={cs(
          'd-flex flex-column justify-content-between',
          classes.customScrollBar,
          classes.activityWrapper
        )}
      >
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMoreActivities}
          hasMore={hasMore}
          loader={<Loading key="loader" size={50} />}
          useWindow={false}
        >
          {orderBy(activities, ['created_at'], 'desc').map((d, i) => (
            <Activity key={i} item={d} showComment={false} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default DocumentActivity;
