import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from 'views/pages/dashboards/Dashboard.module.scss';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import StoryStatus from 'components/Status';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import UserInfo from 'components/UserInfo';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import NoClientTaskImg from 'assets/img/theme/No_Client_Task.png';

const ClientTasks = ({
  getDetails,
  isMobile,
  actionToPerform,
  studyDashboard = false,
  tasksDashboard = false,
  clientDashboard = false,
  ownerKey = 'owner_id',
  reducerKey = 'clientTasks',
  cardHeader = 'Client Tasks',
  showSearch = true,
  dropdownPlaceholder = 'All Staff',
  onRowClick = () => {},
  defaultSort = { dataField: 'name', order: 'asc' },
  filterByEmployees = true,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStoryStatuses());
    dispatch(fetchStoryPriorities());
    dispatch(fetchComplexities());
    dispatch(fetchScoreMatrix());
  }, [dispatch]);

  const getReducerTasks = (field, defaultValue) =>
    getDetails(`${reducerKey}.${field}`, defaultValue);
  const clientTasks = getReducerTasks('data.data', []);
  const metaDetails = getReducerTasks('data.meta');
  const isError = getReducerTasks('isError', false);
  const loading = getReducerTasks('isInProgress', false);
  const lastPage = get(metaDetails, 'last_page', 1);
  const currentPage = get(metaDetails, 'current_page', 1);
  const totalTasks = get(metaDetails, 'total', 0);
  const [showTask, setShowTask] = useState(true);
  const [staff, setStaff] = useState(null);
  const [sortBy, setSortBy] = useState(defaultSort);

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const getTasks = (options = {}) => {
    let searchParams = {};
    const { order, dataField } = sortBy;
    if (order && dataField) {
      searchParams.page = 0;
      searchParams.sort = order === 'asc' ? dataField : `-${dataField}`;
    }
    if (staff) {
      searchParams[ownerKey] = staff.id;
    }
    actionToPerform({ ...searchParams, ...options });
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff, sortBy, dispatch]);

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const rowEvents = {
    onClick: (e, row) => {
      onRowClick(row.id);
    },
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={cx('m-auto w-100', classes.defaultImage)}
              src={NoClientTaskImg}
              alt="No Client Tasks"
            />
          </div>
          <h4
            className={cx(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Client Tasks
          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card className={cx(classes.viewedStories, { 'w-100': clientDashboard })}>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h3
              className="mb-0"
              onClick={() => {
                if (tasksDashboard) setShowTask(!showTask);
              }}
            >
              {tasksDashboard ? (
                <span>
                  <i
                    className={cx(
                      `fas fa-caret-${showTask ? 'down' : 'right'} mr-1`
                    )}
                  ></i>
                </span>
              ) : null}
              {cardHeader}
              <span className={classes.headerBadge}>{totalTasks}</span>
            </h3>
            <div
              className={cx(classes.filterOption, { 'd-none': !showSearch })}
            >
              <RSelectAsync
                url={
                  filterByEmployees
                    ? '/users/list/dropdown'
                    : '/users/list/dropdown?q=&external=true'
                }
                getOptionLabel={option => option.name}
                placeholder={dropdownPlaceholder}
                onChange={value => {
                  setStaff(value);
                }}
                isClearable={true}
                value={staff}
                styles={{
                  width: '300px',
                }}
              />
            </div>
          </div>
        </CardHeader>
        <Collapse isOpen={showTask}>
          <CardBody
            id="recently-viewed-stories-card-body"
            className={cx('p-0', classes.cardBody, !isMobile && classes.scroll)}
          >
            <InfiniteScroll
              useWindow={isMobile}
              hasMore={currentPage < lastPage && !loading && !isError}
              initialLoad={false}
              pageStart={1}
              loadMore={() => {
                getTasks({ page: currentPage + 1 });
              }}
            >
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                remote
                noDataIndication={NoDataIndication}
                search={false}
                bordered={false}
                loading={loading}
                paginationOptions={false}
                data={clientTasks}
                classes="mb-0"
                wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
                onTableChange={(type, { sortOrder, sortField }) => {
                  if (type === 'sort') {
                    setSortBy({
                      dataField: sortField,
                      order: sortOrder,
                    });
                  }
                }}
                columns={[
                  {
                    dataField: 'client_name',
                    text: 'Client',
                    sort: true,
                    hidden: studyDashboard || tasksDashboard || clientDashboard,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <h5
                            id={`recent-story-name-${get(row, 'id')}`}
                            className={cx(
                              'm-0',
                              classes.ellipsis,
                              classes.hoverHand,
                              classes.storyName
                            )}
                          >
                            {cell}
                          </h5>
                        </>
                      );
                    },
                  },
                  {
                    dataField: 'name',
                    text: 'Task Name',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <h5
                            id={`recent-story-name-${get(row, 'id')}`}
                            className={cx(
                              'm-0',
                              classes.ellipsis,
                              classes.hoverHand,
                              classes.storyName
                            )}
                          >
                            {cell}
                          </h5>
                        </>
                      );
                    },
                  },
                  {
                    dataField: 'priority',
                    text: 'Priority',
                    sort: true,
                    sortCaret: renderSortCaret,
                    headerClasses: classes.priorityHeader,
                    formatter: cell => {
                      return (
                        <span
                          className={cx(
                            classes.storyPriority,
                            classes[`storyPriority${cell}`]
                          )}
                        >
                          {cell}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <StoryStatus
                          disabled={true}
                          statuses={statuses}
                          selectedStatus={{
                            status: cell,
                            color: row.status_color,
                          }}
                        />
                      );
                    },
                  },
                  {
                    dataField: 'created_at',
                    text:
                      tasksDashboard || studyDashboard || clientDashboard
                        ? 'Date Received'
                        : 'Date Created',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => {
                      return (
                        <span>
                          {moment(cell)
                            .tz(userTimezone)
                            .format('M/DD/YYYY')}
                        </span>
                      );
                    },
                  },
                  {
                    dataField: 'owner_name',
                    text: 'Assigned To',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => {
                      return (
                        <UserInfo
                          info={{
                            id: row.id,
                            name: cell,
                            avatar: row.owner_avatar,
                          }}
                          showName={false}
                          showToolTip={true}
                        />
                      );
                    },
                  },
                ]}
                rowEvents={rowEvents}
              />
            </InfiniteScroll>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
};

ClientTasks.propTypes = {};

export default ClientTasks;
