import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './RecentlyViewedProjects.module.scss';
import PropTypes from 'prop-types';
import history from 'helpers/history';
import NoViewedProjects from 'assets/img/theme/No_Viewed_Projects.svg';
import useBreakPoint from 'helpers/useBreakPoint';
import UserInfo from 'components/UserInfo';
import Progress from 'components/Progress';
import NoStudy from 'assets/img/theme/NoStudy.png';
import ColorPicker from 'views/pages/Epics/BadgeColorPicker';
import get from 'lodash/get';

const RecentlyViewedProjects = ({
  data,
  loading,
  title,
  isClientProfile = false,
  action = '',
  excuteAction = () => {},
}) => {
  const isMobile = useBreakPoint('sm', 'down');

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/admin/studies/${row.id}`);
    },
  };

  return (
    <Card className={classes['viewed-projects']}>
      <CardHeader className={classes['card-header']}>
        <h3 className="mb-0">{title}</h3>
        {data.length > 0 && (
          <>
            {!isClientProfile && (
              <span className={classes.tableSubHeader}>
                Viewed last 48 hours
              </span>
            )}
          </>
        )}
        {action && excuteAction && (
          <Button size="sm" color="primary" onClick={() => excuteAction(true)}>
            {action}
          </Button>
        )}
      </CardHeader>
      <CardBody
        className={cx(
          'p-0',
          !isMobile && classes['scroll'],
          classes['card-body']
        )}
      >
        <BaseTable
          keyField="id"
          defaultSorted={null}
          noDataIndication={() => (
            <div className="text-center mt-5 mb-5">
              <img src={NoStudy} />

              <div className="mt-1">
                {' '}
                <h3>
                  <b>No Recently Viewed Studies</b>
                </h3>
              </div>
            </div>
          )}
          search={false}
          bordered={false}
          loading={loading}
          paginationOptions={false}
          data={data}
          classes="mb-0"
          rowClasses={classes.row}
          wrapperClasses={classes.tableWrapper}
          rowEvents={rowEvents}
          columns={[
            {
              dataField: 'client',
              text: 'Client',
              sort: false,
              hidden: isClientProfile,
              formatter: cell => {
                return (
                  <div
                    className={cx(
                      'd-flex align-items-center',
                      classes['project-name']
                    )}
                  >
                    <h5 className={cx('m-0')}>{cell?.name || '-'}</h5>
                  </div>
                );
              },
            },
            {
              dataField: 'study_name',
              text: 'Study',
              sort: false,
              formatter: (cell, row) => (
                <>
                  <div
                    className={cx(
                      'd-flex align-items-center',
                      classes['project-name']
                    )}
                    id={`project-name-${row.id}`}
                  >
                    {isClientProfile && (
                      <ColorPicker color={`${row.project_phase.color}`} />
                    )}
                    <span>{cell}</span>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target={`project-name-${row.id}`}
                    placement="bottom"
                    innerClassName={classes['tooltip']}
                    boundariesElement="viewport"
                  >
                    {cell}
                  </UncontrolledTooltip>
                </>
              ),
            },
            {
              dataField: 'project_phase',
              text: 'Phase',
              sort: false,
              hidden: !isClientProfile,
              formatter: (cell, row) => {
                return (
                  <>
                    <div
                      className={cx(
                        'd-flex align-items-center',
                        classes['project-name']
                      )}
                      id={`project-phase-${row.id}`}
                    >
                      <span>{cell?.label || '-'}</span>
                    </div>
                    <UncontrolledTooltip
                      delay={0}
                      target={`project-phase-${row.id}`}
                      placement="bottom"
                      innerClassName={classes['tooltip']}
                      boundariesElement="viewport"
                    >
                      {cell?.label}
                    </UncontrolledTooltip>
                  </>
                );
              },
            },
            {
              dataField: 'phase',
              text: 'Phase',
              sort: false,
              hidden: isClientProfile,
              formatter: (cell = {}, row) => {
                return (
                  <>
                    <h5 className="m-0 d-flex align-items-center">
                      {cell?.label && (
                        <i
                          style={{
                            color: `${cell?.color}`,
                            fontSize: '6px',
                          }}
                          className="fas fa-circle mr-1"
                        />
                      )}
                      {cell?.label || '-'}
                    </h5>
                  </>
                );
              },
            },
            {
              dataField: 'owner',
              text: 'Project Lead',
              sort: false,
              hidden: !isClientProfile,
              formatter: (cell, row) => {
                return (
                  <div className="d-flex align-items-center justify-content-center w-100">
                    {cell && (
                      <UserInfo
                        info={{
                          name: cell.name,
                          id: row.id,
                          avatar: cell.avatar,
                        }}
                        showName={false}
                        showToolTip={true}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </CardBody>
    </Card>
  );
};

RecentlyViewedProjects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  placeHolderImg: PropTypes.any,
  analyticsObj: PropTypes.object,
  action: PropTypes.string,
  excuteAction: PropTypes.func,
};
RecentlyViewedProjects.defaultProps = {
  data: [],
  title: 'Recently Viewed Studies',
  loading: false,
  placeHolderImg: NoViewedProjects,
  analyticsObj: {},
  action: '',
  excuteAction: () => {},
};

export default RecentlyViewedProjects;
