import React from 'react';
import Dropdowns from 'components/Dropdowns';
import { useAccess, permissions } from 'helpers/permission';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const StoryOptions = ({
  id,
  index,
  story,
  deleteAction,
  reorderStories,
  isStoryReorderAllowed,
  isStoryDeleteAllowed,
  isStoryViewAllowed,
  stories,
  fetchStoryAction,
  analyticsConstants,
  analyticsSendEvent,
}) => {
  const moveToTop = index => {
    analyticsSendEvent({ action: analyticsConstants.action.move_story_to_top });
    reorderStories({
      source: {
        index,
        droppableId: `${id}`,
      },
      destination: {
        index: 0,
        droppableId: `${id}`,
      },
    });
  };

  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const isStoryCreator =
    useAccess(permissions.DELETE_MY_STORIES) &&
    get(story, 'created_by.id') === userId;

  const moveToBottom = index => {
    analyticsSendEvent({
      action: analyticsConstants.action.move_story_to_bottom,
    });
    reorderStories({
      source: {
        index,
        droppableId: `${id}`,
      },
      destination: {
        index: stories.length - 1,
        droppableId: `${id}`,
      },
    });
  };

  return (
    <Dropdowns
      dropdownClasses="ml-auto"
      text={<i className="fas fa-ellipsis-v" />}
      className="btn-icon-only m-0 text-light float-right"
      options={[
        ...(isStoryReorderAllowed
          ? [
              {
                text: 'Move to Top',
                onClick: () => moveToTop(index),
              },
              {
                text: 'Move to Bottom',
                onClick: () => moveToBottom(index),
              },
            ]
          : []),
        ...(isStoryViewAllowed
          ? [
              {
                text: 'View Story',
                onClick: () => {
                  fetchStoryAction(story.id);
                },
              },
            ]
          : []),
        ...(isStoryDeleteAllowed || isStoryCreator
          ? [
              {
                text: 'Delete Story',
                onClick: deleteAction(story, id),
              },
            ]
          : []),
      ]}
      caret={false}
      size="sm"
      color=""
    />
  );
};

export default StoryOptions;
