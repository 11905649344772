import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import * as Yup from 'yup';

import { Form, Col, Row } from 'reactstrap';
import { Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import RSelect from 'components/FormFields/RSelect';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';

import classes from './Sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPartnerTypeList,
  getClientsIndustriesList,
} from 'store/actions/partnerDashboard';
import get from 'lodash/get';
import { inviteClient } from 'store/actions/partners';
import { phoneNumberRegex } from 'helpers/constants';

const typeOptions = [
  { label: 'Client', value: 'prospect' },
  { label: 'Partner', value: 'partner' },
];

const SubmitReferralForm = props => {
  const { isOpen, closeModal, openModal, openMeetingModal } = props;
  const dispatch = useDispatch();
  const [dynamicIndustryList, setDynamicIndustryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [referralType, setReferralType] = useState('prospect');
  const [selectType, setSelectType] = useState('industry');

  const clientsIndustryList = useSelector(({ partnerDashboard }) =>
    get(partnerDashboard, 'clientIndustryList.data', [])
  );
  const partnerTypeList = useSelector(({ partnerDashboard }) =>
    get(partnerDashboard, 'partnerTypeList.data', [])
  );

  const formatList = data => {
    return data.map((company, index) => ({
      id: index + 1,
      label: company,
    }));
  };

  useEffect(() => {
    const isClient = referralType === 'prospect';
    if (isClient) {
      setDynamicIndustryList(formatList(clientsIndustryList));
    } else {
      setDynamicIndustryList(formatList(partnerTypeList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralType, clientsIndustryList, partnerTypeList]);

  useEffect(() => {
    dispatch(fetchPartnerTypeList());
    dispatch(getClientsIndustriesList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referralType === 'prospect') {
      setSelectType('industry');
    } else {
      setSelectType('partner_type');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralType]);

  let initialValues = {
    type: referralType,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    note: '',
    [selectType]: '',
  };

  const schema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    phone: Yup.string()
      .matches(phoneNumberRegex, 'Must be a valid number')
      .typeError('Phone is required')
      .required('Phone is required'),
    company: Yup.string().required('Company is required'),
    [selectType]: Yup.string(),
    note: Yup.string(),
  });

  const getPopupByStatus = (
    status = 0,
    leadStatus = '',
    resetForm,
    link = '',
    type = 'prospect'
  ) => {
    let modalData = {
      confirmBtnText: 'Book a Meeting',
      cancelBtnText: 'No thanks',
      type: 'success',
      text: (
        <div>
          <p>Your referral was submitted successfully</p>
          <p className="font-weight-700">
            Your referral was submitted successfully. Book a meeting now with
            your referral and our team to help expedite qualification or leave
            it to us to handle.
          </p>
        </div>
      ),
      title: 'Success!',
      onConfirm: () => {
        resetForm();
        openMeetingModal(link);
      },
    };
    if (status === 1) {
      if (type === 'prospect') {
        AlertPopupHandler.openCustom({
          type: modalData.type,
          showCancel: true,
          title: modalData.title,
          confirmBtnText: modalData.confirmBtnText,
          cancelBtnText: modalData.cancelBtnText,
          confirmBtnBsStyle: 'success',
          text: modalData.text,
          customClass: classes.referralAlert,
          onConfirm: modalData.onConfirm,
        });
      } else {
        AlertPopupHandler.openCustom({
          type: 'warning',
          confirmBtnText: 'Save & Finish Later',
          cancelBtnText: 'Save & Finish Later',
          text: (
            <div>
              <p className="font-weight-400">
                You successfully submitted a new partner!
              </p>
            </div>
          ),
          title: 'Partner submitted',
          showCancel: true,
          confirmBtnBsStyle: 'success',
          customClass: classes.referralAlert,
          onConfirm: () => {
            resetForm();
          },
        });
      }
      closeModal();
    }
    if (status === 0) {
      if (type === 'prospect') {
        switch (leadStatus) {
          case 'untouched':
            modalData = {
              ...modalData,
              title: 'Success! However...',
              type: 'warning',
              text: (
                <div className={classes.modalContent}>
                  <p>
                    This referral is already in our system, but isn’t very
                    active.
                  </p>
                  <p>
                    Can you help get us connected? Click the “book a meeting”
                    button below to schedule a meeting with this referral and
                    our team.
                  </p>
                </div>
              ),
            };
            break;
          case 'closed':
            modalData = {
              ...modalData,
              type: 'warning',
              title: 'Success! However...',
              confirmBtnText: 'Submit another referral',
              cancelBtnText: 'Done',
              text: (
                <div className={classes.modalContent}>
                  <p>This referral is already an active Strike client.</p>
                  <p>
                    We appreciate your excitement to connect Strike with
                    businesses in need. Keep the referrals coming!
                  </p>
                </div>
              ),
              onConfirm: () => openModal(),
            };
            break;
          case 'disqualified':
            modalData = {
              ...modalData,
              type: 'warning',
              title: 'Success! However...',
              confirmBtnText: 'Book a meeting',
              cancelBtnText: 'Done',
              text: (
                <div className={classes.modalContent}>
                  <p>
                    This referral has already been evaluated by our team and was
                    determined not to be qualified for our services.
                  </p>
                  <p>
                    If you have additional information or questions regarding
                    this referral, please book a meeting with our team for us to
                    discuss.
                  </p>
                </div>
              ),
            };
            break;
          case 'silent':
            modalData = {
              ...modalData,
              type: 'warning',
              title: 'Success! However...',
              confirmBtnText: 'Book a meeting',
              cancelBtnText: 'Done',
              text: (
                <div className={classes.modalContent}>
                  <p>
                    This referral has been submitted to Strike before, but
                    despite our efforts the contact has gone silent.
                  </p>
                  <p>
                    We would love to speak with them again. Book a meeting now
                    with your referral and our team to get the ball rolling!
                  </p>
                </div>
              ),
            };
            break;

          default:
            break;
        }
      } else {
        switch (leadStatus) {
          case 'exists':
            modalData = {
              ...modalData,
              title: 'Partner received',
              type: 'warning',
              confirmBtnText: 'Save & Finish Later',
              cancelBtnText: 'OK, got it!',
              cancelBtnCssClass: classes.partnerCancelBtn,
              text: (
                <div className={classes.modalContent}>
                  <p className="font-weight-400">
                    Your partner submission was received and will be reviewed
                    shortly
                  </p>
                </div>
              ),
              onConfirm: () => {
                resetForm();
              },
            };
            break;
          default:
            modalData = {
              ...modalData,
              title: 'Partner received',
              type: 'warning',
              confirmBtnText: 'Save & Finish Later',
              cancelBtnText: 'OK, got it!',
              cancelBtnCssClass: classes.partnerCancelBtn,
              text: (
                <div className={classes.modalContent}>
                  <p className="font-weight-400">
                    Your partner submission was received and will be reviewed
                    shortly
                  </p>
                </div>
              ),
              onConfirm: () => {
                resetForm();
              },
            };
            break;
        }
      }
      AlertPopupHandler.openCustom({
        type: modalData.type,
        showCancel: true,
        title: modalData.title,
        confirmBtnText: modalData.confirmBtnText,
        cancelBtnText: modalData.cancelBtnText,
        confirmBtnBsStyle: 'success',
        text: modalData.text,
        customClass: classes.referralAlert,
        onConfirm: modalData.onConfirm,
        cancelBtnCssClass: modalData.cancelBtnCssClass,
      });
      closeModal();
    }
  };

  const RenderForm = props => {
    const {
      handleSubmit,
      isValid,
      handleReset,
      values,
      setFieldValue,
      errors,
    } = props;

    return (
      <>
        <ModalBody>
          <Form className={classes.referralForm}>
            <h5 className={classes.required}>
              What type of referral are you making?
            </h5>
            <div className={classes.typeWrapper}>
              {typeOptions.map(type => {
                return (
                  <span
                    key={`${type.label}-type`}
                    className={cs(
                      classes.type,
                      referralType === type.value ? classes.active : ''
                    )}
                    onClick={() => {
                      setFieldValue('type', type.value);
                      setReferralType(type.value);
                    }}
                  >
                    {type?.label}
                  </span>
                );
              })}
            </div>
            <Row>
              <Col>
                <h5 className={classes.required}>First Name</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="first_name"
                  placeholder="Enter First Name"
                  type="text"
                />
              </Col>
              <Col>
                <h5 className={classes.required}>Last Name</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="last_name"
                  placeholder="Enter Last Name"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className={classes.required}>Email</h5>
                <FormikInput
                  groupClassName="mb-3"
                  name="email"
                  placeholder="Enter email address"
                  type="text"
                />
              </Col>
              <Col>
                <h5 className={classes.required}>Phone</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="phone"
                  placeholder="Enter phone"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className={classes.required}>Company Name</h5>
                <FormikInput
                  groupClassName="mb-4"
                  name="company"
                  placeholder="Enter company name"
                  type="text"
                />
              </Col>
              <Col>
                <h5>
                  {referralType === 'prospect' ? 'Industry' : 'Partner Type'}
                </h5>
                <RSelect
                  name={selectType}
                  className="mb-4"
                  value={values[selectType]}
                  getOptionLabel={opt => opt.label}
                  getOptionValue={opt => opt.value}
                  placeholder={
                    referralType === 'prospect'
                      ? 'Enter industry'
                      : 'Enter Partner Type'
                  }
                  options={dynamicIndustryList}
                  error={errors[selectType]}
                  onChange={selectedOption => {
                    setFieldValue(selectType, selectedOption);
                  }}
                />
              </Col>
            </Row>
            <FormikInput
              name="note"
              placeholder="Add notes here (optional)"
              label="Notes"
              type="textarea"
              rows={3}
            />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex pt-0 mt-2 justify-content-between">
          <Button
            onClick={() => {
              handleReset();
              closeModal();
            }}
            color="link"
            className={'float-right'}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            id="inviteUsersBtn"
            color={'primary'}
            className={'float-left'}
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isValid || isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <>
      <Modal
        centered
        id="submitModal"
        title="Submit a Referral"
        size="md"
        isOpen={isOpen}
        toggle={closeModal}
      >
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={schema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setIsLoading(true);
            setSubmitting(false);
            await new Promise(resolve => {
              setTimeout(resolve, 1000);
            });

            const clientSubmitValue = {
              ...values,
              [selectType]: values[selectType].label,
            };
            const resp = await dispatch(inviteClient(clientSubmitValue));
            getPopupByStatus(
              resp?.status,
              resp?.lead_status,
              resetForm,
              resp?.calendly_link,
              referralType
            );
            setIsLoading(false);
            return;
          }}
        >
          {RenderForm}
        </Formik>
      </Modal>
    </>
  );
};

export default SubmitReferralForm;
