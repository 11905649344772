import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Col, Row, FormGroup } from 'reactstrap';
import get from 'lodash/get';
import { useHistory } from 'react-router';

import Modal, { ModalBody } from 'components/FormFields/Modal';
import RSelect from 'components/FormFields/RSelect';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import Button from 'components/Button';
import { getOptions } from 'helpers/clientProfile';
import cls from './StudyModal.module.scss';
import NotificationHandler from 'components/Notifications/NotificationHandler';

const StudyModal = ({ isOpen = false, handleClose }) => {
  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );
  const history = useHistory();
  const yearOptions = getOptions(yearsList);

  const schema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    assignedTo: Yup.string().required('Assignee is required'),
    engagement_years: Yup.array().required('Engagement Year is required'),
    following_years: Yup.array().required('Following Year is required'),
  });

  const initialValues = {
    type: '',
    assignedTo: '',
    engagement_years: [],
    following_years: [],
  };
  const submitForm = () => {
    history.push({
      pathname: `/admin/studies/144/dashboard`,
    });
    NotificationHandler.open({
      message: 'Study created successfully!',
      title: ' ',
      icon: ' ',
      operation: 'success',
    });
  };

  const renderNewStudyForm = ({
    handleSubmit,
    values,
    setFieldValue,
    resetForm,
    isValid,
  }) => {
    const typesList = ['R&D', 'ERTC'];
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="5">
            <RSelect
              placeholder="Select Study Type"
              name="type"
              label="Study Type"
              className="mb-3"
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              value={values.type}
              options={getOptions(typesList)}
              onChange={value => {
                setFieldValue('type', value ? value : null);
              }}
            />
          </Col>
          <Col xs="7">
            <FormGroup className="mb-3">
              <label className={cls.label}>Assigned To</label>
              <RSelectAsync
                url="/users/list/dropdown?status=Active&role=manager"
                getOptionLabel={option => option.name}
                placeholder="Select Assignee"
                onChange={value => {
                  setFieldValue('assignedTo', value);
                }}
                isClearable={true}
                value={values.assignedTo}
              />
            </FormGroup>
          </Col>
        </Row>
        <RSelect
          isMulti
          placeholder="Select Years of Engagement"
          name="engagement_years"
          label="Years of Engagement"
          options={yearOptions}
          className="mb-3"
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          value={values.engagement_years}
          onChange={value => {
            setFieldValue('engagement_years', value);
          }}
        />
        <RSelect
          isMulti
          placeholder="Select Years for Document Requests"
          name="following_years"
          label="Request Documents for the Following Years  "
          options={yearOptions}
          className="mb-5"
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          value={values.following_years}
          onChange={value => {
            setFieldValue('following_years', value);
          }}
        />
        <div className={'d-flex justify-content-between'}>
          <Button
            color="link"
            onClick={() => {
              handleClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={!isValid}
            // loading={isProcessing}
          >
            Create Study
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="md"
      title={'Create New Study'}
    >
      <ModalBody>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={submitForm}
        >
          {renderNewStudyForm}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default StudyModal;
