import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptAndSetupInvite,
  toggleRegisterError,
} from 'store/actions/authActions';
import cs from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './AcceptAndSetup.module.scss';
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { validatePassword } from 'helpers/constants';
import qs from 'query-string';
import { PRIVACY, TERMS_AND_CONDITIONS } from '../Register/RegisterConstants';
import AuthCardHeader from 'components/Headers/AuthCardHeader';

const AcceptAndSetup = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);

  const queryString = qs.parse(get(props, 'location.search', ''));
  const companyName = get(queryString, 'companyName', '');
  const companyId = get(queryString, 'companyId', '');
  const userEmail = get(queryString, 'email', '');
  const token = get(queryString, 'token', '');
  const isError = get(auth, 'register.isError', false);

  useEffect(() => {
    document.getElementById('footer-main').classList.add(classes.footerMain);
    document.getElementById('root').classList.add(classes.rootStyles);
    document
      .getElementsByClassName('main-content')[0]
      .classList.add(classes.mainContent);

    return () => {
      document
        .getElementById('footer-main')
        .classList.remove(classes.footerMain);
      document.getElementById('root').classList.remove(classes.rootStyles);
      document
        .getElementsByClassName('main-content')[0]
        .classList.remove(classes.mainContent);
    };
  }, []);
  const acceptAndSetupSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should contain at least 8 characters.')
      .test(
        'validate-password',
        'Your password should contain a combination of numbers, upper and lower case letters, and special characters.',
        validatePassword
      )
      .required('Your password should contain at least 8 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'The passwords you entered don’t match. ')
      .required('The passwords you entered don’t match. '),
    accepted_tc: Yup.string().required(
      'The terms and conditions must be accepted.'
    ),
  });

  if (isError) {
    setTimeout(() => {
      dispatch(toggleRegisterError());
    }, 5000);
  }

  const renderAcceptAndSetupForm = ({ handleSubmit, values, isValid }) => {
    return (
      <Form>
        <FormikInput
          groupClassName="mb-3"
          name="password"
          placeholder="Create Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />
        <FormikInput
          groupClassName="mb-3"
          name="password_confirmation"
          placeholder="Confirm Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />
        <Row className="my-4">
          <Col xs="12">
            <FormikInput
              id="accepted_tc"
              name="accepted_tc"
              type="checkbox"
              value="yes"
              label={
                <span className="text-muted">
                  I agree with the{' '}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>
                </span>
              }
            />
          </Col>
        </Row>
        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="submit"
            block
            loading={loading}
            onClick={handleSubmit}
            disabled={!isValid || !values.accepted_tc}
          >
            Create account
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <AuthHeader
      // title={`Joining ${companyName}`}
      // lead="Fill out the details below to finalize your account setup"
      />
      <Container className={cs('pb-5', classes.container)}>
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cs('border-0', classes.acceptAndSetup)}>
              <CardBody className="px-lg-5 py-lg-5">
                {isError === true && (
                  <Alert
                    text={auth.register.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                  />
                )}
                <AuthCardHeader
                  head="Create an Account"
                  title="Create a password to set up your Strike account"
                />

                <Formik
                  initialValues={{
                    password: '',
                    password_confirmation: '',
                    accepted_tc: '',
                  }}
                  validationSchema={acceptAndSetupSchema}
                  enableReinitialize
                  validateOnMount
                  onSubmit={async values => {
                    const user = {
                      token,
                      name: values.name,
                      email: userEmail,
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                      accepted_tc: !!values.accepted_tc,
                      terms_and_conditions: TERMS_AND_CONDITIONS,
                      privacy_policy: PRIVACY,
                    };
                    setLoading(true);
                    const status = await dispatch(
                      acceptAndSetupInvite(companyId, user)
                    );
                    if (status !== 1) setLoading(false);
                  }}
                >
                  {renderAcceptAndSetupForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AcceptAndSetup;
