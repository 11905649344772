import React from 'react';
import { Modal } from 'reactstrap';
import { PopupModal } from 'react-calendly';

const CalendlyModal = ({ isOpen, modalClose, isLoading, link }) => {
  return (
    <Modal centered id="submitModal" isOpen={isOpen}>
      {!isLoading && (
        <div>
          {link && (
            <PopupModal
              url={link}
              onModalClose={modalClose}
              open={isOpen}
              rootElement={document.getElementById('root')}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default CalendlyModal;
