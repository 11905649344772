import Types from 'store/types/quickActions';
import { createStory, createClientTask } from 'api/backlogs';
import { get, omitBy, isNil, isNull } from 'lodash';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { addProject } from 'api/projects';

export const createStoryQuickAction = (sectionId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_STORY_FROM_QUICK_ACTIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createStory(sectionId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_STORY_FROM_QUICK_ACTIONS_SUCCESS,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_STORY_FROM_QUICK_ACTIONS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_STORY_FROM_QUICK_ACTIONS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const createClientTaskQuickAction = data => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createClientTask(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_SUCCESS,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const createProjectQuickAction = ({
  projectName: name,
  projectClient,
  projectContract,
  projectDescription: description,
  startDate: date_start,
  endDate: date_end,
  monthlyBudget: total_budget,
  monthlyHours: budget_hours,
}) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_PROJECT_FROM_QUICK_ACTIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const theme_id = isNull(projectContract)
        ? null
        : get(projectContract, 'id');
      const client_id = isNull(projectClient) ? null : get(projectClient, 'id');
      const resp = await addProject(
        omitBy(
          {
            name,
            client_id,
            theme_id,
            description,
            date_start,
            date_end,
            total_budget,
            budget_hours,
          },
          isNil
        )
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_PROJECT_FROM_QUICK_ACTIONS_SUCCESS,
        });
        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_PROJECT_FROM_QUICK_ACTIONS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_PROJECT_FROM_QUICK_ACTIONS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};
