import React from 'react';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import ScheduleMeetingGreen from 'assets/img/icons/common/ScheduleMeetingGreen.svg';
import classes from './QuickActions.module.scss';
import { showSupportForm } from 'store/actions/app';

const ScheduleMeeting = () => {
  const dispatch = useDispatch();

  return (
    <div
      className={classes.actionBox}
      onClick={() => dispatch(showSupportForm(true))}
    >
      <div className={cs(classes.gradient, classes.scheduleMeeting)} />
      <div className={classes.box}>
        <img src={ScheduleMeetingGreen} alt="" />
        <h2>Ask a Question</h2>
      </div>
    </div>
  );
};

export default ScheduleMeeting;
