import React, { useEffect, useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import useBreakPoint from 'helpers/useBreakPoint';
import { getClientTasks } from 'store/actions/userDashboard';

import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import cx from 'classnames';
import PersonalDashboardQuickActions from 'views/pages/QuickActions/PersonalDashboardQuickActions';
import ClientTasks from '../Fields/ClientTasks';
import RecentlyViewedProjects from '../Fields/RecentlyViewedProjects';
import EstimatedDeliveries from '../Fields/EstimatedDeliveries';
import RecentActivity from '../Fields/RecentActivity';
import { useHistory, useLocation } from 'react-router';
import StoryModal from 'views/pages/Backlogs/Stories/StoryModal';
import TaskModal from '../TaskModal';

const StrikeDashboard = ({ commonProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isMobile = useBreakPoint('sm', 'down');
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('userDashboard', field, defaultValue);

  const { search: queryParams } = useLocation();
  const tab = useRef('comment');
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState('');
  const [count, setCount] = useState(0);
  const [projectId, setProjectId] = useState('');
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModal] = useState(false);
  const [comment, setComment] = useState(null);

  const metaDetails = useGetDetails('clientTasks.data.meta');
  const lastPage = get(metaDetails, 'last_page', 1);
  const currentPage = get(metaDetails, 'current_page', 1);

  useEffect(() => {
    if (queryProps.story) {
      setStoryId(toNumber(queryProps.story));
      setIsTaskModal(true);
    }
  }, [queryProps.story]);

  const handleActionToPerform = (params = {}) => {
    dispatch(getClientTasks(params));
  };

  const reload = () => setCount(count + 1);
  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col className={cx(!isMobile && 'pl-0')} md={7}>
          <Row>
            <Col md={12}>
              <PersonalDashboardQuickActions
                {...{ setStoryId, setProjectId, setIsStoryModalOpen }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ClientTasks
                {...commonProps}
                filterByEmployees={false}
                dropdownPlaceholder="Client Users"
                onRowClick={id => {
                  setStoryId(id);
                  setIsTaskModal(true);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={cx(!isMobile && 'pr-0')} md={5}>
          <RecentlyViewedProjects {...commonProps} />
          <EstimatedDeliveries {...commonProps} />
          <RecentActivity
            onStoryClick={url => {
              const params = queryString.parseUrl(url);
              setComment(get(params, 'query.comment'));
              tab.current = get(params, 'query.tab', tab.current);
              setStoryId(get(params, 'query.story'));
              setIsTaskModal(true);
            }}
            {...commonProps}
          />
        </Col>
      </Row>
      {isStoryModalOpen && (
        <StoryModal
          storyId={toNumber(storyId)}
          projectId={toNumber(projectId)}
          isOpen={isStoryModalOpen}
          closeModal={() => {
            setIsStoryModalOpen(false);
            tab.current = 'comment';
            history.push(`?`);
          }}
          currentTab={tab.current}
          onChange={() => reload()}
          comment={comment}
        />
      )}
      {isTaskModalOpen && (
        <TaskModal
          storyId={toNumber(storyId)}
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setIsTaskModal(false);
            if (currentPage === lastPage) {
              handleActionToPerform({ page: lastPage });
            }
            tab.current = 'comment';
            history.push(`?`);
          }}
          currentTab={tab.current}
          comment={comment}
        />
      )}
    </Container>
  );
};

export default StrikeDashboard;
