import React, { useState } from 'react';

import Column from './Column';
import classes from './Kanban.module.scss';
import { DragDropContext } from 'react-beautiful-dnd';
import { reorder, move } from 'helpers/dragDropHelpers';
import get from 'lodash/get';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import greenWarningIcon from 'assets/img/icons/green-warning-icon.svg';
import {
  doPostReOrderAssigments,
  doMoveAssignmentCard,
} from 'store/actions/kanbanActions';

import { useSelector, useDispatch } from 'react-redux';
import useDebounce from 'react-use/lib/useDebounce';
import Loading from 'components/Loading';
import PeriodDropdown from './PeriodDropdown';
import findIndex from 'lodash/findIndex';
import { analyticsConstants } from 'helpers/analytics';

const DragContainer = props => {
  const dispatch = useDispatch();
  const { userId, setPeriod, refresh, analyticsSendEvent, expand } = props;
  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));

  // show data
  const columns = get(props.data, 'columns', []) || [];
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  let defaultAssignments = {};
  columns.map(column => {
    const id = column.status.replace(/\s/g, '').toLowerCase();
    defaultAssignments = { ...defaultAssignments, [id]: column };
    return true;
  });

  const [assignments, setAssigments] = useState(defaultAssignments);

  useDebounce(
    () => {
      let defaultAssignments = {};
      columns.map(column => {
        const id = column.status.replace(/\s/g, '').toLowerCase();
        defaultAssignments = { ...defaultAssignments, [id]: column };
        return true;
      });

      setAssigments(defaultAssignments);
    },
    200,
    [columns, count]
  );

  const reload = () => {
    setCount(count + 1);
  };

  const confirmDrag = data => {
    const { destination, source, draggableId } = data;
    const currentSourceData = source
      ? get(assignments, `${source.droppableId}`)
      : null;
    const currentDestinationData = destination
      ? get(assignments, `${destination.droppableId}`)
      : null;
    if (
      currentSourceData !== currentDestinationData &&
      currentSourceData &&
      currentDestinationData
    ) {
      const dragStudy = currentSourceData.studies.find(
        study => study.id === +draggableId
      );

      if (currentSourceData.id > currentDestinationData.id) {
        AlertPopupHandler.openCustom({
          type: 'warning',
          warning: true,
          showCancel: true,
          cancelBtnText: 'No, Cancel',
          onConfirm: () => {
            onDragEnd(destination, source);
          },
          title: 'Move Study Back?',
          confirmBtnText: 'Yes, Move',
          cancelBtnBsStyle: 'warning',
          confirmBtnCssClass: classes.dragContainerBtnStyle,
          text: `Are you sure you want to move ${dragStudy?.client?.name} - ${dragStudy?.name} to ${currentDestinationData.status}? `,
        });
      } else {
        const sourceData = currentSourceData.studies;
        if (sourceData.length > 0) {
          const isUnChecked =
            dragStudy.tasks.completed !== dragStudy.tasks.total;
          if (isUnChecked) {
            AlertPopupHandler.openCustom({
              type: 'warning',
              warning: true,
              showCancel: true,
              cancelBtnText: 'No, Cancel',
              onConfirm: () => {
                onDragEnd(destination, source);
              },
              title: 'Move Study Forward?',
              confirmBtnText: 'Yes, Move',
              cancelBtnBsStyle: 'warning',
              confirmBtnCssClass: classes.dragContainerBtnStyle,
              text: `Are you sure you want to move ${dragStudy?.client?.name} - ${dragStudy?.name} to ${currentDestinationData.status}? You haven’t completed all tasks yet.`,
            });
          } else {
            AlertPopupHandler.openCustom({
              customIcon: greenWarningIcon,
              showCancel: true,
              cancelBtnText: 'No, Cancel',
              onConfirm: () => {
                onDragEnd(destination, source);
              },
              title: 'Move Study Forward?',
              confirmBtnText: 'Yes, Move',
              confirmBtnBsStyle: 'success',
              icon: 'success',
              reverseButtons: true,
              cancelBtnCssClass: classes.dragContainerBtnStyle,
              text: `Are you sure you want to move ${dragStudy?.client?.name} - ${dragStudy?.name} to ${currentDestinationData.status}? `,
            });
          }
        }
      }
    } else if (currentDestinationData === currentSourceData) {
      onDragEnd(destination, source);
    }
  };

  const onDragEnd = async (destination, source) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    analyticsSendEvent({
      action: analyticsConstants.action.drag_assignment_personal_kanban,
      label: analyticsConstants.label.move_story_assignment,
      // dropped_section: destinationSection,
    });
    const moveLockedAssignmentEvent = () =>
      analyticsSendEvent({
        action:
          analyticsConstants.action.drag_locked_assignment_personal_kanban,
        label: analyticsConstants.label.move_locked_story_assignment,
      });
    const moveIncompleteAssignmentEvent = () =>
      analyticsSendEvent({
        action:
          analyticsConstants.action.drag_incomplete_assignment_personal_kanban,
        label: analyticsConstants.label.move_incomplete_story_assignment,
      });
    const {
      index: destinationIndex,
      droppableId: destinationSection,
    } = destination;

    const { index: sourceIndex, droppableId: sourceSection } = source;

    if (destinationSection === sourceSection) {
      if (destination && destinationIndex !== sourceIndex) {
        const sourceData = get(assignments, `${sourceSection}.studies`, []);
        const destinationData = get(
          assignments,
          `${destinationSection}.studies`,
          []
        );
        const sourceId = get(assignments, `${sourceSection}.id`, []);

        // prevent change order locked assignment
        const task = sourceData[sourceIndex];
        const isLocked = get(task, 'is_locked', false);
        if (isLocked) {
          moveLockedAssignmentEvent();
          AlertPopupHandler.openCustom({
            type: 'warning',
            warning: true,
            showCancel: false,
            onConfirm: () => {},
            title: 'Whoops! You can’t do that yet.',
            confirmBtnText: 'OK',
            confirmBtnBsStyle: 'warning pl-5 pr-5',
            text: `You cannot re-order a locked assignment`,
          });

          return;
        }

        const lockedIndex = findIndex(
          destinationData,
          item => item.is_locked === true
        );

        let index = destinationIndex;
        if (lockedIndex > -1) {
          if (lockedIndex > 0 && destinationIndex >= lockedIndex) {
            moveLockedAssignmentEvent();
            AlertPopupHandler.openCustom({
              type: 'warning',
              warning: true,
              showCancel: false,
              onConfirm: () => {},
              title: 'Whoops! You can’t do that yet.',
              confirmBtnText: 'OK',
              confirmBtnBsStyle: 'warning pl-5 pr-5',
              text: `You cannot re-order a locked assignment`,
            });

            return;
          } else {
            index = destinationIndex;
          }
        }

        const updatedItems = reorder(sourceData, sourceIndex, index);
        setLoading(true);
        const status = await dispatch(
          doPostReOrderAssigments(
            userId,
            sourceId,
            updatedItems,
            props.isGlobal
          )
        );

        if (status !== 1) refresh();

        setLoading(false);
      }
    } else {
      const sourceData = get(assignments, `${sourceSection}.studies`, []);

      const task = sourceData[sourceIndex];
      // prevent drag locked assignment into order column
      const isLocked = get(task, 'is_locked', false);
      if (isLocked) {
        moveLockedAssignmentEvent();
        const assignee = get(task, 'dependency.user.name');
        const assignmentName = get(task, 'dependency.name');
        AlertPopupHandler.openCustom({
          type: 'warning',
          warning: true,
          showCancel: false,
          onConfirm: () => {},
          title: 'Whoops! You can’t do that yet.',
          confirmBtnText: 'OK',
          confirmBtnBsStyle: 'warning pl-5 pr-5',
          text: `You cannot start this assignment until ${assignee} finishes ${assignmentName}`,
        });

        return;
      }

      // verify checklists are checked
      const checklists = get(task, 'checklists', []) || [];

      if (destinationSection === 'done' && checklists.length > 0) {
        const isUnChecked = checklists.find(item => item.status === false);
        if (isUnChecked) {
          moveIncompleteAssignmentEvent();
          AlertPopupHandler.openCustom({
            type: 'warning',
            warning: true,
            showCancel: false,
            onConfirm: () => {},
            title: 'Whoops! You can’t do that yet.',
            confirmBtnText: 'OK',
            confirmBtnBsStyle: 'warning pl-5 pr-5',
            text: `Please make sure all of your checklist items are marked as complete before moving this assignment to ‘Done’.`,
          });
          return;
        }
      }

      const destinationData = get(
        assignments,
        `${destinationSection}.studies`,
        []
      );
      const currentsourceId = get(assignments, `${sourceSection}.id`);
      const destinationId = get(assignments, `${destinationSection}.id`);

      const lockedIndex = findIndex(
        destinationData,
        item => item.is_locked === true
      );

      let index = destinationIndex;
      if (lockedIndex > -1) {
        if (lockedIndex > 0 && destinationIndex > lockedIndex) {
          moveLockedAssignmentEvent();
          AlertPopupHandler.openCustom({
            type: 'warning',
            warning: true,
            showCancel: false,
            onConfirm: () => {},
            title: 'Whoops! You can’t do that yet.',
            confirmBtnText: 'OK',
            confirmBtnBsStyle: 'warning pl-5 pr-5',
            text: `You cannot re-order a locked assignment`,
          });

          return;
        } else {
          index = destinationIndex;
        }
      }

      const newResult = move(sourceData, destinationData, sourceIndex, index);

      setLoading(true);
      const status = await dispatch(
        doMoveAssignmentCard(
          userId,
          sourceData[sourceIndex].id,
          currentsourceId,
          destinationId,
          newResult.sourceClone,
          newResult.destClone,
          props.isGlobal,
          companyId
        )
      );
      reload();
      if (status !== 1) refresh();

      setLoading(false);
    }
  };

  return (
    <DragDropContext onDragEnd={confirmDrag}>
      {loading && <Loading wrapperClass={classes.loading} size="100px" />}

      {columns.map(column => {
        const id = column.status.replace(/\s/g, '').toLowerCase();
        return (
          <Column
            isGlobal={props.isGlobal}
            name={column.status}
            id={id}
            data={column.studies}
            key={id}
            expand={expand}
            filter={
              column.status === 'Done' ? (
                <PeriodDropdown
                  onChange={data => {
                    analyticsSendEvent({
                      action: props.isGlobal
                        ? analyticsConstants.action.filter_done_global_kanban
                        : analyticsConstants.action.filter_done_personal_kanban,
                      label:
                        analyticsConstants.label.kanban_done_time_period_switch,
                      // period: data.text,
                    });
                    setPeriod(data.id);
                  }}
                />
              ) : (
                ''
              )
            }
          />
        );
      })}
    </DragDropContext>
  );
};

export default DragContainer;
