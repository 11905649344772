import React from 'react';
import { getCurrentStepForTour } from 'components/QuickStartTour/TourHelpers';
import { updateActiveTourStatus } from 'store/actions/profile';
import { useDispatch } from 'react-redux';

const useStartTour = () => {
  const dispatch = useDispatch();

  return documents => {
    const [{ documents: myDocuments = [] }] = documents;
    const tourKey = myDocuments.length
      ? 'tax_returns'
      : 'general_ledger_payroll';
    const step = getCurrentStepForTour(tourKey, {
      assignedProjects: '',
      runningTime: '',
    });
    dispatch(
      updateActiveTourStatus({
        activeTour: tourKey,
        step,
      })
    );
  };
};

export default useStartTour;
