import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import QuickActionViewDocuments from 'assets/img/icons/common/QuickActionViewDocuments.svg';
import history from 'helpers/history';
import classes from './QuickActions.module.scss';

const ViewDocuments = ({ projectId }) => {
  const redirectToDocuments = () => {
    history.push(`/admin/studies/${projectId}/documents`);
  };

  return (
    <>
      <div className={classes.actionBox} onClick={redirectToDocuments}>
        <div className={cs(classes.gradient, classes.viewDocuments)} />
        <div className={classes.box}>
          <img src={QuickActionViewDocuments} alt="" />
          <h2>View Documents</h2>
        </div>
      </div>
    </>
  );
};

export default ViewDocuments;
