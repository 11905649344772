import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import RecentlyViewedProjectsWidget from 'views/widgets/RecentlyViewedProjects';
import get from 'lodash/get';
import NoProjectsAssigned from 'assets/img/theme/No_Assigned_Studies.svg';
import StudyModal from 'components/StudyModal';
const RecentlyViewedProjects = () => {
  const recentlyViewedProjects = useSelector(({ clientProfile }) =>
    get(clientProfile, 'viewedProjects.data', [])
  );
  const loading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'recentlyViewedProjects.isInProgress', false)
  );
  const [showStudyModal, setShowStudyModal] = useState(false);
  return (
    <>
      <RecentlyViewedProjectsWidget
        title="Studies"
        placeHolderImg={NoProjectsAssigned}
        data={recentlyViewedProjects}
        loading={loading}
        isClientProfile
        action="Create New Study"
        excuteAction={setShowStudyModal}
      />
      <StudyModal
        isOpen={showStudyModal}
        handleClose={() => setShowStudyModal(false)}
      ></StudyModal>
    </>
  );
};

export default RecentlyViewedProjects;
