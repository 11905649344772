import React from 'react';
import classes from './earnings.module.scss';
import { Bar, Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { formatCurrency } from './constants';
import get from 'lodash/get';
const EstimatedChart = ({ futureData = {}, comparedData = {} }) => {
  const { not_invoiced = 0, unpaid = 0, paid = 0 } = futureData;
  const { earnings = 0, payouts = 0 } = comparedData;
  const barData = {
    options: {
      layout: {
        padding: 10,
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#8898AA',
              fontSize: '14',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
              color: '#E9ECEF',
            },
            ticks: {
              callback(value) {
                let counted = value / 1000;
                if (!(counted % 100)) {
                  return '$' + counted + 'k';
                }
              },
              fontColor: '#8898AA',
              fontSize: '14',
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title(item) {
            const title = get(item, '0.label', '');
            return title.replace(/,/g, ' ');
          },
          label(item, data) {
            const label = data.datasets[item.datasetIndex].label || '';
            const { yLabel } = item;
            let content = '';
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return formatCurrency(content, false, 0);
          },
        },
      },
      plugins: {
        labels: {
          render: args => {
            return formatCurrency(args.value, false, 0);
          },
          fontSize: 14,
        },
      },
    },
    data: {
      labels: [['Invoiced', '(unpaid)'], 'Invoice Paid'],
      datasets: [
        {
          data: [unpaid, paid],
          backgroundColor: ['#32325D', '#F2994A'],
          barThickness: 150,
        },
      ],
    },
  };

  const amountData = [earnings, payouts];
  const labels = ['Estimated earnings', 'Payouts'];
  const customLabels = labels.map(
    (label, index) => `${label} (${formatCurrency(amountData[index], true)})`
  );
  const pieData = {
    options: {
      responsive: false,
      max: 100,
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        labels: {
          render: args => {
            return formatCurrency(args.value, true);
          },
          fontColor: 'white',
        },
      },
      tooltips: {
        callbacks: {
          label(item, data) {
            const index = item.index;
            const label = data.labels[index]?.replace(/\(\S+\)/g, '');
            let value = get(data, `datasets[0].data[${index}]`, '');
            value = formatCurrency(value, false, 0);
            return `${label}: ${value}`;
          },
        },
      },
    },
    data: {
      labels: customLabels,
      datasets: [
        {
          data: amountData,
          backgroundColor: ['#4FB3C9', '#7D7882'],
          label: 'Dataset 1',
          borderWidth: 0,
        },
      ],
    },
  };
  return (
    <div className={classes.chartWrapper}>
      <div className={classes.barChart}>
        <p>Estimated Future Earnings by Client Status</p>
        <Bar
          height={90}
          data={barData.data}
          options={barData.options}
          className="chart-canvas"
          id="bar-chart"
        />
      </div>
      <div className={classes.pieChart}>
        <p>Estimated Earnings vs. Payouts</p>
        <Pie
          data={pieData.data}
          options={pieData.options}
          className="chart-canvas"
          id="pie-chart"
        />
      </div>
    </div>
  );
};
export default EstimatedChart;
