import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import classes from './PartnerDashboard.module.scss';
import StatusChart from 'views/pages/dashboards/PartnerDashboard/StatusChart';
import PartnersTable from 'views/pages/dashboards/PartnerDashboard/PartnersTable';
import Dropdowns from 'components/Dropdowns';
import cs from 'classnames';
import { fetchPartnerDashboard } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import millify from 'millify';
import Loading from 'components/Loading';

const PartnerDashboard = () => {
  const dispatch = useDispatch();

  const loading = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.isInProgress', false)
  );

  const inProgressData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.active_referrals', [])
  );
  const closedData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.closed', [])
  );
  const needsAttentionData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.needs_attention', [])
  );
  const disqualifiedData = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.leads.disqualified', [])
  );
  const counts = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.counts', {})
  );
  const estimatedEarnings = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.estimated_earnings', '$0')
  );
  const lifetimeEarnings = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.lifetime_earnings', '$0')
  );
  const opportunity_types = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.opportunity_types', {})
  );
  const showEarning = useSelector(({ partner }) =>
    get(partner, 'partnerDashboard.data.show_earning', true)
  );
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const [period, setPeriod] = useState({
    value: '',
    text: 'All time',
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPartnerDashboard());
  }, [dispatch]);

  const onToggle = () => setOpen(!open);

  const periodOptions = [
    {
      value: '',
      text: 'All time',
      onClick: async () => {
        setPeriod({
          value: '',
          text: 'All time',
        });
      },
    },
    {
      value: 'last_year',
      text: 'Last 365 days',
      onClick: async () => {
        setPeriod({
          value: 'last_year',
          text: 'Last 365 days',
        });
      },
    },
    {
      value: 'last_six_months',
      text: 'Last 6 months',
      onClick: async () => {
        setPeriod({
          value: 'last_six_months',
          text: 'Last 6 months',
        });
      },
    },
    {
      value: 'last_month',
      text: 'Last 30 Days',
      onClick: async () => {
        setPeriod({
          value: 'last_month',
          text: 'Last 30 days',
        });
      },
    },
    {
      value: 'last_week',
      text: 'Last 7 Days',
      onClick: async () => {
        setPeriod({
          value: 'last_week',
          text: 'Last 7 Days',
        });
      },
    },
  ];

  const getFormattedCurrency = value => {
    return millify(parseFloat(value.substring(1).replaceAll(',', '')), {
      lowercase: true,
      precision: 0,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container fluid className={classes.partnerDashboard}>
        <div className={classes.header}>
          <span className={classes.title}>Referral Tracker</span>
          {/*<div className={classes.dropdownWrapper}>*/}
          {/*  <span className={classes.dropdownLabel}>DATE RANGE</span>*/}
          {/*  <Dropdowns*/}
          {/*    dropdownClasses={cs('ml-auto', classes.customDropdown)}*/}
          {/*    className="m-0 p-1 ml-1"*/}
          {/*    text={*/}
          {/*      <div className={classes.caret}>*/}
          {/*        <span>{period.text}</span> <i className="fas fa-caret-down" />{' '}*/}
          {/*      </div>*/}
          {/*    }*/}
          {/*    active={period.value}*/}
          {/*    size="sm"*/}
          {/*    role="button"*/}
          {/*    caret={false}*/}
          {/*    color=""*/}
          {/*    options={periodOptions}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className={classes.infoWrapper}>
          <div>
            <p>Total Active Referrals</p>
            <p>{counts?.active_referrals || 0}</p>
          </div>
          <div>
            <p>Total Opportunities</p>
            <p>{counts?.opportunities || 0}</p>
          </div>
          {showEarning && !isProduction && (
            <div>
              <p>Estimated Earnings</p>
              <p>${getFormattedCurrency(estimatedEarnings)}</p>
            </div>
          )}
          {showEarning && !isProduction && (
            <div>
              <p>Lifetime Earnings</p>
              <p>${getFormattedCurrency(lifetimeEarnings)}</p>
            </div>
          )}
          <div>
            <p>Days since last referral</p>
            <p>{counts?.days_since_last_referral}</p>
          </div>
        </div>
        <StatusChart counts={counts} opportunityTypes={opportunity_types} />
        <div className={classes.tables}>
          <PartnersTable
            type="in_progress_type"
            headerText="Active Referrals"
            data={inProgressData}
          />
          <PartnersTable
            type="needs_attention_type"
            headerText="Needs Attention"
            data={needsAttentionData}
          />
          <PartnersTable
            type="closed_type"
            headerText="Closed"
            data={closedData}
          />
          <PartnersTable
            type="disqualified_type"
            headerText="Disqualified"
            data={disqualifiedData}
          />
        </div>
      </Container>
    </>
  );
};
export default PartnerDashboard;
