import React, { useState, useEffect } from 'react';
import classes from './Kanban.module.scss';
import fullScreenIcon from 'assets/img/icons/full-screen.svg';
import fullScreenCloseIcon from 'assets/img/icons/full-screen-close.svg';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody, CardTitle, Container } from 'reactstrap';
import cs from 'classnames';
import Input from 'components/FormFields/Input';

import { doGetDataKanban } from 'store/actions/kanbanActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { useDebounce, useEffectOnce } from 'react-use';
import useBreakpoint from 'helpers/useBreakPoint';
import DragContainer from './DragContainer';

import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Button from 'components/Button';
import StoryModal from 'views/pages/Backlogs/Stories/StoryModal';
import toNumber from 'lodash/toNumber';
import { openFullscreen, closeFullscreen } from 'helpers/fullscreen';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import analytics, { analyticsConstants } from 'helpers/analytics';
import isEmpty from 'lodash/isEmpty';
import Loading from 'components/Loading';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import replace from 'lodash/replace';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { useAccess, permissions } from 'helpers/permission';
import Dropdowns from 'components/Dropdowns';
import { filter, reduce } from 'lodash';

const Kanban = ({
  projectId,
  isAllowedEdit,
  isMockUp,
  childMockUpData: mockUpData,
}) => {
  const analyticsSendEvent = ({ ...rest }) => {
    if (!isMockUp) {
      analytics.sendEvent({
        category: analyticsConstants.category.project_boards,
        ...rest,
      });
    }
  };
  const isAllowedKanbanEdit = useAccess([
    permissions.CUSTOMIZE_ALL_PROJECTS_KANBAN,
    permissions.CUSTOMIZE_MY_PROJECTS_KANBAN,
  ]);
  const dispatch = useDispatch();
  const isMobile = useBreakpoint('xs', 'down');
  const isSmallerThanTablet = useBreakpoint('sm', 'down');
  const [keyword, setKeyword] = useState('');
  const [count, setCount] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const [isFullScreen, setFullScreen] = useState(false);
  const [totalStoriesCanBeExpanded, setTotalStoriesCanBeExpanded] = useState(0);
  const [dataRetrievalCount, setDataRetrievalCount] = useState(0);
  const [columnModification, setColumnModification] = useState(false);

  useEffectOnce(() => {
    const footerElement = document.getElementById('admin-footer');
    dispatch(fetchStoryStatuses());
    const parent = get(
      document.getElementById('admin-footer'),
      'parentElement'
    );
    if (parent) parent.removeChild(footerElement);
    if (!isMockUp)
      analyticsSendEvent({
        action: analyticsConstants.action.view_project_board,
      });
    return () => {
      if (parent) parent.appendChild(footerElement);
    };
  });
  // show modal story detail

  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const queryStory = get(queryProps, 'story', '');
  const storyId = useGetFieldFromObject('story', 'details.data.id', '');
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const useGetSelectedKanbanProperty = (property, defaultValue) =>
    useSelector(({ kanban }) => {
      return !isMockUp
        ? get(kanban, `getKanban.data.${property}`, defaultValue) ||
            defaultValue
        : get(mockUpData, property, defaultValue) || defaultValue;
    }, isEqual);

  const columns = useGetSelectedKanbanProperty('columns', []);
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  useEffect(() => {
    let count = 0;
    forEach(columns, col => {
      const stories = get(col, 'stories', []);
      if (stories && stories.length) {
        forEach(stories, s => {
          if (s.tasks && s.tasks.length) {
            count++;
          }
        });
      }
    });
    setTotalStoriesCanBeExpanded(count);
  }, [columns]);

  useEffect(() => {
    if (toNumber(queryStory) === storyId) {
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'Project Kanban',
      });
      setIsStoryModalOpen(true);
    }
  }, [queryStory, storyId]);

  useDebounce(
    () => {
      const getData = async (projectId, keyword = '') => {
        await dispatch(doGetDataKanban(projectId, keyword));
        setDataRetrievalCount(currentCount => currentCount + 1);
      };

      if (projectId && !isMockUp) getData(projectId, keyword);
    },
    500,
    [projectId, keyword, dispatch, count]
  );

  const onColumnModification = () => {
    dispatch(doGetDataKanban(projectId, keyword));
  };

  useDebounce(
    () => {
      if (!isEmpty(keyword))
        analyticsSendEvent({
          action: analyticsConstants.action.search_project_board,
        });
    },
    1000,
    [keyword]
  );

  const reload = () => setCount(count + 1);

  const storiesSummary = useGetSelectedKanbanProperty('stories_summary');
  const hoursSummary = useGetSelectedKanbanProperty('hours_summary');
  const dateStart = useGetSelectedKanbanProperty('date_start');
  const dateEnd = useGetSelectedKanbanProperty('date_end');

  const toggleAllStories = e => {
    e.stopPropagation();
    let updatedExpanded = [];
    if (expanded.length < totalStoriesCanBeExpanded) {
      columns.forEach(col => {
        if (col.stories && col.stories.length) {
          col.stories.forEach(s => {
            if (s.tasks && s.tasks.length) {
              updatedExpanded.push(s.id);
            }
          });
        }
      });
    }
    if (!isEmpty(updatedExpanded))
      analyticsSendEvent({
        action: analyticsConstants.action.expand_all_stories_on_board,
      });
    setExpanded([...updatedExpanded]);
  };

  const toggleExpanded = id => e => {
    e.stopPropagation();
    if (expanded.includes(id)) {
      setExpanded([...expanded.filter(c => c !== id)]);
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.expand_single_story_on_board,
      });
      setExpanded([...expanded, id]);
    }
  };

  const renderProjectTime = () => {
    if (!dateStart || !dateEnd) return '';

    return (
      (isMobile ? '' : ' - ') +
      `${moment(dateStart, 'YYYY-MM-DD').format('MMM DD, YYYY')} - ${moment(
        dateEnd,
        'YYYY-MM-DD'
      ).format('MMM DD, YYYY')}`
    );
  };

  return (
    <div
      id="kanban-page"
      className={cs(classes.root, {
        [classes.fullscreen]: isFullScreen,
      })}
    >
      {dataRetrievalCount === 0 && <Loading wrapperClass={classes.loading} />}
      <Container fluid>
        <div className={classes.header}>
          <Card>
            <CardBody>
              <CardTitle className="h3 d-flex justify-content-between mb-3 align-items-center">
                <div>
                  Study Kanban
                  <span className={classes.subText}>{renderProjectTime()}</span>
                </div>
                <div className="d-flex">
                  <Button
                    className={isFullScreen && 'p-0 m-0'}
                    size="sm"
                    color={
                      isFullScreen || isSmallerThanTablet ? 'link' : 'primary'
                    }
                    type="button"
                    onClick={() => {
                      setFullScreen(!isFullScreen);
                      if (isFullScreen) closeFullscreen();
                      else {
                        analyticsSendEvent({
                          action:
                            analyticsConstants.action.expand_project_board,
                        });
                        openFullscreen(document.documentElement);
                      }
                    }}
                  >
                    {isFullScreen ? (
                      <img src={fullScreenCloseIcon} alt="Close Fullscreen" />
                    ) : isSmallerThanTablet ? (
                      <img src={fullScreenIcon} alt="Close Fullscreen" />
                    ) : (
                      'Fullscreen'
                    )}
                  </Button>
                  {isAllowedKanbanEdit && (
                    <Dropdowns
                      onClick={event => event.stopPropagation()}
                      dropdownClasses={classes.dropdown}
                      text={<i className="fas fa-ellipsis-v" />}
                      className="btn-icon-only m-0 text-light float-right"
                      options={[
                        {
                          text: 'Add Column',
                          onClick: event => {
                            event.stopPropagation();
                            setColumnModification('new');
                          },
                        },
                      ]}
                      caret={false}
                      size="sm"
                      color=""
                    />
                  )}
                </div>
              </CardTitle>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-start align-items-center mb-1"
                >
                  Search:
                  <Input
                    className={classes.searchBox}
                    value={keyword}
                    onChange={e => {
                      setKeyword(e.target.value);
                    }}
                  />
                  <div
                    className={cs('ml-4', classes.expand)}
                    onClick={toggleAllStories}
                  >
                    {expanded.length > 0 &&
                    expanded.length === totalStoriesCanBeExpanded
                      ? 'Collapse'
                      : 'Expand'}{' '}
                    All Stories
                    <i
                      className={cs(
                        'ml-2 fas',
                        {
                          'fa-chevron-down':
                            expanded.length === 0 ||
                            expanded.length !== totalStoriesCanBeExpanded,
                        },
                        {
                          'fa-chevron-up':
                            expanded.length > 0 &&
                            expanded.length === totalStoriesCanBeExpanded,
                        }
                      )}
                    />
                  </div>
                </Col>
                <Col
                  md={6}
                  className="d-flex justify-content-md-end justify-content-sm-start  align-items-center mt-1"
                >
                  {!isMobile && (
                    <div className={classes.statis}>
                      <b>{get(storiesSummary, 'active')}</b> Active Stories
                    </div>
                  )}

                  <div className={classes.statis}>
                    <b>
                      {get(storiesSummary, 'completed')}/
                      {get(storiesSummary, 'total')}
                    </b>{' '}
                    Stories Completed
                  </div>

                  {!isMobile && (
                    <div className={classes.statis}>
                      <b>
                        {get(hoursSummary, 'consumed', 0)
                          ? Math.round(get(hoursSummary, 'consumed', 0) * 10) /
                            10
                          : '0'}
                        /{get(hoursSummary, 'total')}
                      </b>{' '}
                      Hrs
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <DragContainer
          {...{
            projectId,
            expanded,
            toggleExpanded,
            isAllowedEdit,
            isAllowedKanbanEdit,
            analyticsSendEvent,
            columns,
            setColumnModification,
            columnModification,
            onColumnModification,
          }}
        />
      </Container>
      <StoryModal
        storyId={toNumber(queryStory)}
        projectId={toNumber(projectId)}
        isOpen={isStoryModalOpen}
        closeModal={() => {
          setIsStoryModalOpen(false);
          history.replace(history.location.pathname);
        }}
        currentTab={get(queryProps, 'tab', 'comments')}
        onTabChange={tab => history.push(`?story=${queryStory}&tab=${tab}`)}
        onChange={() => reload()}
      />
    </div>
  );
};

Kanban.propTypes = {
  isMockUp: PropTypes.bool,
};
Kanban.defaultProps = {
  isMockUp: false,
};

export default Kanban;
