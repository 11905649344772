import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import BaseTable from 'components/Table';
import { formatCurrency } from './constants';
import classes from './earnings.module.scss';
import { getCurrencyStringToNumber } from 'helpers/constants';
import EarningsModal from './EarningsModal/EarningsModal';

const EarningTable = props => {
  const {
    header,
    type,
    data,
    paginationOptions = false,
    sizePerPage = 50,
  } = props;
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const [isModal, setIsModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  const closeModal = () => setIsModal(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    setSortBy({
      dataField: sortField,
      order: sortOrder,
    });
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const noData = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div
          className={cx(
            'd-flex justify-content-between align-items-center flex-column w-100'
          )}
        >
          <div className={classes.emptyStateHeader}></div>
          <div className={classes.noData}>
            <div className={classes.iconWrapper}>
              <i className="fa fa-address-card" aria-hidden="true"></i>
            </div>
          </div>
          <p className={cx(classes.defaultText)}>Nothing to see here</p>
        </div>
      </div>
    );
  };

  const referralColumns = [
    {
      dataField: 'client',
      text: 'Referred Client',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: (cell, row, index) => {
        return (
          <>
            <div className={cx(classes.paymentWrapper, 'text-break')}>
              <span id={`earning-tooltip-${index}`}>{cell ? cell : '-'}</span>
            </div>
            <UncontrolledTooltip
              target={`earning-tooltip-${index}`}
              className={cx('custom-tooltip', classes.tooltipCustom)}
              modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            >
              <div>
                <span>Invoice Number:</span>
                <span className="font-weight-bolder progress-heading  ml-2">
                  {row?.invoice_id ? row?.invoice_id : '-'}
                </span>
              </div>
            </UncontrolledTooltip>
          </>
        );
      },
    },
    {
      dataField: 'service',
      text: 'Service',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'tax_year',
      text: 'Tax Year',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'invoiced',
      text: 'Strike Invoice',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: cell => {
        return <>{cell ? cell : '-'}</>;
      },
    },
    {
      dataField: 'received',
      text: 'Strike Received',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: cell => {
        return <>{cell ? cell : '-'}</>;
      },
    },
    {
      dataField: 'referral_fee',
      text: 'Referral %',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'unpaid',
      text: 'Unpaid',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'pending',
      text: 'Pending',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'paid',
      text: 'Paid',
      sort: true,
      sortCaret: renderSortCaret,
    },
  ];

  const receivedColumns = [
    {
      dataField: 'payment_sent',
      text: 'Payment Sent',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <div
              id={`referral-date-${get(row, 'payment_id')}`}
              className={cx('m-0')}
            >
              {cell ? cell : '-'}
            </div>
          </>
        );
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-content-center justify-content-between">
            <div
              id={`recent-story-name-${get(row, 'id')}`}
              className={cx('m-0')}
            >
              {cell ? cell : '-'}
            </div>
            <div
              onClick={() => {
                setModalDetails(row);
                setIsModal(true);
              }}
              className={cx('mx-2', classes.addIcon)}
            >
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </div>
          </div>
        );
      },
    },
  ];

  const formatter = cell => {
    if (cell.indexOf('[b]') === 0) {
      return (
        <>
          <b>{cell.replace('[b]', '')}</b>
        </>
      );
    }
    return <>{cell ? cell : '-'}</>;
  };

  const pendingColumns = [
    {
      dataField: 'client',
      text: 'Referred Client',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: cell => {
        return <div className="text-break">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'tax_year',
      text: 'Tax Year',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'received',
      text: 'Strike Collected',
      sort: true,
      sortCaret: renderSortCaret,
      formatter,
    },
    {
      dataField: 'referral_fee',
      text: 'Referral %',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'unpaid',
      text: 'Est. Payout',
      sort: true,
      sortCaret: renderSortCaret,
      formatter,
    },
  ];

  const disbursementColumns = [
    {
      dataField: 'client',
      text: 'Referred Client',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: cell => {
        return <div className="text-break">{cell ? cell : '-'}</div>;
      },
    },
    {
      dataField: 'invoice',
      text: 'Invoice #',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'received',
      text: 'Strike Received',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: cell => {
        return <>{cell ? cell : '-'}</>;
      },
    },
    {
      dataField: 'client_payment_date',
      text: 'Client Payment Date',
      sort: true,
      sortCaret: renderSortCaret,
      formatter: (cell, row) => {
        return <>{cell ? cell : '-'}</>;
      },
    },
    {
      dataField: 'referral_fee',
      text: 'Referral %',
      sort: true,
      sortCaret: renderSortCaret,
    },
    {
      dataField: 'paid',
      text: 'Paid',
      sort: true,
      sortCaret: renderSortCaret,
    },
  ];

  const mappingColumn = {
    earning: referralColumns,
    payment_received: receivedColumns,
    pending_payouts: pendingColumns,
    disbursement_details: disbursementColumns,
  };

  return (
    <div>
      {isModal ? (
        <EarningsModal
          data={modalDetails}
          isOpen={isModal}
          closeModal={() => {
            setIsModal(false);
          }}
        />
      ) : null}
      <p className={classes.title}>{header}</p>
      <div className={classes.tableView}>
        <Card>
          <CardBody className={cx('p-0')}>
            <BaseTable
              keyField="id"
              search={false}
              bordered={false}
              paginationOptions={paginationOptions}
              sizePerPage={sizePerPage}
              data={data}
              noDataIndication={noData}
              onTableChange={handleTableChange}
              columns={mappingColumn[type] || []}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default EarningTable;
