import React, { useState } from 'react';
import cs from 'classnames';
import classes from '../QuickActions.module.scss';
import TaskForm from './CreateTask.Form';
import { createClientTaskQuickAction } from 'store/actions/quickActions';
import { useDispatch } from 'react-redux';
import { fetchClientProfileTasks } from 'store/actions/clientProfile';

const CreateTask = ({ clientId, clientName, companyId }) => {
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async values => {
    setIsSubmitting(true);
    const { status } = await dispatch(createClientTaskQuickAction(values));
    if (status === 1) dispatch(fetchClientProfileTasks(clientId));
    setIsSubmitting(false);
    setShowCreateTaskModal(false);
  };

  return (
    <>
      <div
        className={classes.actionBox}
        onClick={() => {
          setShowCreateTaskModal(true);
        }}
      >
        <div className={cs(classes.box, classes.createProject)}>
          <i className="fas fa-cubes text-success text-xl" />
          <h2>Create Client Task</h2>
        </div>
      </div>
      <TaskForm
        isModalOpen={showCreateTaskModal}
        isLoading={isSubmitting}
        closeModal={() => {
          setShowCreateTaskModal(false);
        }}
        submitValues={handleSubmit}
        clientId={clientId}
        clientName={clientName}
        companyId={companyId}
      />
    </>
  );
};

export default CreateTask;
