import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import classes from './AboutCompany.module.scss';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import GeneralDetails from 'views/pages/ClientProfile/AboutCompany/GeneralDetails';
import Address from 'views/pages/ClientProfile/AboutCompany/Address';
import CompanyDates from 'views/pages/ClientProfile/AboutCompany/CompanyDates';
import Notes from 'views/pages/ClientProfile/AboutCompany/Notes';
import className from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchIndustries,
  fetchAccountTypes,
  fetchCompanySizes,
  fetchLeadSources,
  fetchYears,
  fetchAccountLevels,
} from 'store/actions/metadata';
import { getOptions } from 'helpers/clientProfile';
import * as Yup from 'yup';
import {
  getMultiSelectValueFromOptions,
  getValueFromOptions,
} from 'helpers/dropdownHelpers';
import Button from 'components/Button';

const AboutCompany = ({ submitForm }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIndustries());
    dispatch(fetchAccountTypes());
    dispatch(fetchCompanySizes());
    dispatch(fetchLeadSources());
    dispatch(fetchYears());
    dispatch(fetchAccountLevels());
  }, [dispatch]);
  const clientProfileData = useSelector(({ clientProfile }) =>
    get(clientProfile, 'clientProfile.data', {})
  );
  const industries = useSelector(({ metadata }) =>
    get(metadata, 'industries.data', {})
  );
  const industryDropdownOptions = industries ? Object.values(industries) : [];

  const accountTypes = useSelector(({ metadata }) =>
    get(metadata, 'accountTypes.data', {})
  );

  const companySize = useSelector(({ metadata }) =>
    get(metadata, 'companySize.data', {})
  );
  const leadSources = useSelector(({ metadata }) =>
    get(metadata, 'leadSource.data', {})
  );

  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const accountLevels = useSelector(({ metadata }) =>
    get(metadata, 'accountLevels.data', {})
  );

  const schema = Yup.object().shape({
    associated_entity: Yup.array().nullable(true),
    name: Yup.string().nullable(true),
    website: Yup.string().nullable(true),
    account_type: Yup.string().nullable(true),
    payroll_provider: Yup.string().nullable(true),
    entity_hash_1: Yup.string().nullable(true),
    entity_hash_1_ein: Yup.string().nullable(true),
    phone: Yup.string().nullable(true),
    phone_alt: Yup.string().nullable(true),
    email_addresses: Yup.array().nullable(true),
    fax: Yup.string().nullable(true),
    billing_street: Yup.string().nullable(true),
    billing_city: Yup.string().nullable(true),
    billing_state: Yup.string().nullable(true),
    billing_country: Yup.string().nullable(true),
    billing_zip: Yup.number().nullable(true),
    shipping_street: Yup.string().nullable(true),
    shipping_city: Yup.string().nullable(true),
    shipping_state: Yup.string().nullable(true),
    shipping_country: Yup.string().nullable(true),
    shipping_zip: Yup.number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(true),
    industry: Yup.string().nullable(true),
    size: Yup.string().nullable(true),
    filing_deadline: Yup.date().nullable(true),
    founding_year: Yup.string().nullable(true),
    first_year_qres_gr: Yup.string().nullable(true),
    project_tax_yearends: Yup.string().nullable(true),
    company_yearend: Yup.string().nullable(true),
    estimated_benefit: Yup.string().nullable(true),
    engagement_years: Yup.array().nullable(true),
    lead_source: Yup.string().nullable(true),
    description: Yup.string().nullable(true),
    notes: Yup.string().nullable(true),
  });

  const initialValues = {
    ...clientProfileData,
    associated_entity:
      clientProfileData.associated_entity &&
      clientProfileData.associated_entity.length
        ? clientProfileData.associated_entity?.split(',')
        : [],
    account_level: clientProfileData.account_level
      ? getValueFromOptions(accountLevels, clientProfileData.account_level)
      : '',
    name: clientProfileData.name ? clientProfileData.name : '',
    website: clientProfileData.website ? clientProfileData.website : '',
    account_type: clientProfileData.account_type
      ? getValueFromOptions(accountTypes, clientProfileData.account_type)
      : null,
    payroll_provider: clientProfileData.payroll_provider
      ? clientProfileData.payroll_provider
      : '',
    entity_hash_1: clientProfileData.entity_hash_1
      ? clientProfileData.entity_hash_1
      : '',
    entity_hash_1_ein: clientProfileData.entity_hash_1_ein
      ? clientProfileData.entity_hash_1_ein
      : '',
    phone: clientProfileData.phone ? clientProfileData.phone : '',
    phone_alt: clientProfileData.phone_alt ? clientProfileData.phone_alt : '',
    email_addresses:
      clientProfileData.email_addresses?.length > 0
        ? clientProfileData.email_addresses
        : [
            {
              email: '',
              is_primary: 1,
            },
            {
              email: '',
              is_primary: 0,
            },
          ],
    fax: clientProfileData.fax ? clientProfileData.fax : '',
    billing_street: clientProfileData.billing_street
      ? clientProfileData.billing_street
      : '',
    billing_city: clientProfileData.billing_city
      ? clientProfileData.billing_city
      : '',
    billing_state: clientProfileData.billing_state
      ? clientProfileData.billing_state
      : '',
    billing_country: clientProfileData.billing_country
      ? clientProfileData.billing_country
      : '',
    billing_zip: clientProfileData.billing_zip
      ? clientProfileData.billing_zip
      : '',
    shipping_street: clientProfileData.shipping_street
      ? clientProfileData.shipping_street
      : '',
    shipping_city: clientProfileData.shipping_city
      ? clientProfileData.shipping_city
      : '',
    shipping_state: clientProfileData.shipping_state
      ? clientProfileData.shipping_state
      : '',
    shipping_country: clientProfileData.shipping_country
      ? clientProfileData.shipping_country
      : '',
    shipping_zip: clientProfileData.shipping_zip
      ? clientProfileData.shipping_zip
      : '',
    industry: clientProfileData.industry
      ? {
          label: clientProfileData.industry.industry,
          value: clientProfileData.industry.id,
        }
      : null,
    size: clientProfileData.size
      ? getValueFromOptions(companySize, clientProfileData.size)
      : null,
    filing_deadline: clientProfileData.filing_deadline
      ? clientProfileData.filing_deadline
      : '',
    founding_year: clientProfileData.founding_year
      ? clientProfileData.founding_year
      : '',
    first_year_qres_gr: clientProfileData.first_year_qres_gr
      ? clientProfileData.first_year_qres_gr
      : '',
    first_year_of_revenues: clientProfileData.first_year_of_revenues
      ? clientProfileData.first_year_of_revenues
      : '',
    project_tax_yearends: clientProfileData.project_tax_yearends
      ? clientProfileData.project_tax_yearends
      : '',
    company_yearend: clientProfileData.company_yearend
      ? clientProfileData.company_yearend
      : '',
    estimated_benefit: clientProfileData.estimated_benefit
      ? clientProfileData.estimated_benefit
      : '',
    engagement_years: clientProfileData.engagement_years
      ? getMultiSelectValueFromOptions(
          yearsList,
          clientProfileData.engagement_years.split(',').map(v => parseInt(v))
        )
      : [],
    // initial_study_years: clientProfileData.initial_study_years
    //   ? getMultiSelectValueFromOptions(
    //       yearsList,
    //       clientProfileData.initial_study_years
    //         .split(',')
    //         .map(v => parseInt(v))
    //     )
    //   : [],
    assigned_user: clientProfileData.assigned_user
      ? clientProfileData.assigned_user
      : '',
    business_development_rep: clientProfileData.business_development_rep
      ? clientProfileData.business_development_rep
      : '',
    lead_source: clientProfileData.lead_source
      ? getValueFromOptions(leadSources, clientProfileData.lead_source)
      : null,
    business_development_manager: clientProfileData.business_development_manager
      ? clientProfileData.business_development_manager
      : '',
    referred_by: clientProfileData.referred_by
      ? clientProfileData.referred_by
      : '',
    description: clientProfileData.description
      ? clientProfileData.description
      : '',
    notes: clientProfileData.notes ? clientProfileData.notes : '',
  };

  const renderAboutCompanyForm = ({
    handleSubmit,
    values,
    setFieldValue,
    isValid,
    errors,
  }) => {
    const validation = JSON.stringify(values) === JSON.stringify(initialValues);
    return (
      <Form onSubmit={handleSubmit}>
        <GeneralDetails
          values={values}
          setFieldValue={setFieldValue}
          accountLevels={accountLevels}
        />
        <Address title="Billing" name="billing" values={values} />
        <Address title="Shipping" name="shipping" values={values} />
        <CompanyDates
          industryDropdownOptions={getOptions(industryDropdownOptions)}
          companySizeOptions={getOptions(companySize)}
          yearsList={getOptions(yearsList)}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Notes
          options={getOptions(leadSources)}
          setFieldValue={setFieldValue}
          values={values}
        />
        <div className="w-100 d-flex justify-content-end">
          <Button disabled={validation} color="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div className="ml-4 mr-4">
      <Card className={classes.card}>
        <CardHeader className={className('h3', classes.cardHeader)}>
          About {clientProfileData.name || ''}
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={submitForm}
          >
            {renderAboutCompanyForm}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default AboutCompany;
