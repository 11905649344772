import React, { useCallback } from 'react';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import classes from './EarningsModal.module.scss';
import useBreakPoint from 'helpers/useBreakPoint';
import PropTypes from 'prop-types';
import EarningTable from '../EarningTable';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

const EarningsModal = ({ isOpen, closeModal, data = {} }) => {
  const isMobile = useBreakPoint('xs', 'down');
  const referralEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.received_payouts', [])
  );

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <div className={classes.scrollBar} id="storyModal-leftColumn">
          {children}
        </div>
      );
    },
    [isMobile]
  );

  const handleModalClose = () => {
    closeModal();
  };

  return (
    <Modal
      size="sm"
      scrollable
      fade={false}
      title="Disbursement Details"
      isOpen={isOpen}
      toggle={handleModalClose}
      className={classes.modalWrapper}
    >
      <ModalBody id="storyModal-body">
        <>
          <ConditionallyRenderScrollBar>
            <div className={classes.totalWrapper}>
              <div>
                <h4>Disbursement Total</h4>
                <p>{data?.disbursement_total || '-'}</p>
              </div>
              <div>
                <h4>Disbursement Date</h4>
                <p>{data?.disbursement_date || '-'}</p>
              </div>
            </div>
            <div className={classes.disbursementTable}>
              <EarningTable
                type="disbursement_details"
                data={data?.details || []}
              />
            </div>
          </ConditionallyRenderScrollBar>
        </>
      </ModalBody>
    </Modal>
  );
};

EarningsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
};

EarningsModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
};
export default EarningsModal;
