import React, { useState } from 'react';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import RSelect from 'components/FormFields/RSelect';
import { getOptions } from 'helpers/clientProfile';
import { isEmpty } from 'lodash';

const EngagementYears = ({
  getFieldValue,
  onChange,
  isEditAllowed,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
  placeholder = 'Years',
  name = 'engagement_years',
  title = 'Engagement Years',
  leftIcon = '',
  fieldKey,
  viewModeIcon,
  isMulti = false,
  options,
}) => {
  const value = getFieldValue(fieldKey);
  const [showSelect, setShowSelect] = useState(false);
  const [newValue, setNewValue] = useState([]);

  const changeValue = () => {
    setShowSelect(false);
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_value_$,
    });
    const labels = newValue?.map(element => element.label).sort();
    onChange(name, labels || []);
  };

  const getFormattedValue = () => {
    return value ? value : isEditAllowed ? '-' : defaultValue;
  };

  const onBlurAction = () => {
    changeValue();
  };

  return (
    <>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        {title}
      </h6>
      {showSelect ? (
        <div className={classes.selectMenu}>
          <RSelect
            isMulti={isMulti}
            value={newValue.length > 0 ? newValue : ''}
            leftIcon={leftIcon}
            focused
            options={
              newValue.length > 0
                ? options.filter(values => !value.includes(values.label))
                : options
            }
            getOptionLabel={options => options.label}
            getOptionValue={options => options.value}
            onChange={values => {
              setNewValue(values || []);
            }}
            autoFocus
            placeholder={placeholder}
            closeMenuOnSelect={false}
            onBlur={onBlurAction}
          />
        </div>
      ) : (
        <p
          className={cx(
            isEditAllowed && classes.hoverHand,
            classes.dollarIcon,
            !value && 'text-muted',
            classes.valueItems
          )}
          onClick={() => {
            if (isEditAllowed) {
              const newValue = value.split(', ').map(element => {
                if (element.length > 4) {
                  let newArray = [];
                  const array = element.split('-');
                  for (let i = array[0]; i <= array[1]; i++) {
                    newArray.push(i);
                  }
                  return newArray;
                }
                return element;
              });
              const updatedValue = newValue.flat();
              if (!isEmpty(value)) setNewValue(getOptions(updatedValue));
              setShowSelect(true);
            }
          }}
        >
          {viewModeIcon && (
            <i className={cx('fa', viewModeIcon, classes.viewModeIcon)} />
          )}
          {getFormattedValue()}
        </p>
      )}
    </>
  );
};

export default EngagementYears;
