import React from 'react';
import moment from 'moment';
import DatePicker from 'components/FormFields/DatePicker';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';

const StartDate = ({
  getFieldValue,
  isEditAllowed,
  onChange,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
  title = '',
  fieldKey,
  showIcon = false,
  placeholder = 'Add a Start Date',
}) => {
  const startDate = moment(getFieldValue(fieldKey)).format('M/D/YY');
  const endDate = moment(getFieldValue('date_end')).format('M/D/YY');
  const Values = getFieldValue(fieldKey);
  return (
    <>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        {title}
      </h6>
      <div className="d-flex align-items-center">
        {showIcon && <i className="far fa-calendar mr-2" />}
        <DatePicker
          dateFormat="MM/DD/YY"
          placeholder="Start Date"
          renderInput={(props, openCalendar, closeCalendar) => (
            <p
              onClick={() => isEditAllowed && openCalendar()}
              onBlur={closeCalendar}
              className={cx(
                isEditAllowed && classes.hoverHand,
                classes.valueItems,
                !Values && 'text-muted'
              )}
            >
              {moment(props.value).isValid()
                ? props.value
                : isEditAllowed
                ? `+ ${placeholder}`
                : defaultValue}
            </p>
          )}
          isValidDate={current =>
            // current.isAfter(moment().subtract(1, 'day')) &&
            // moment(endDate).isValid() ? current.isBefore(endDate) :
            true
          }
          value={startDate}
          name="startDate"
          onChange={date => {
            analyticsSendEvent({
              action: analyticsConstants.action.edit_project_start_date,
            });
            moment(date).isValid()
              ? onChange(fieldKey, moment(date).format('YYYY/MM/DD'))
              : onChange(fieldKey, '');
            if (moment(endDate).isValid() && date.isAfter(endDate)) {
              analyticsSendEvent({
                action: analyticsConstants.action.edit_project_end_date,
              });

              onChange('endDate', null);
            }
          }}
          className={classes['calendar']}
        />
      </div>
    </>
  );
};

export default StartDate;
