import React from 'react';
import { Form, FormGroup, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import FormModal from 'components/FormFields/FormModal';
import { updateActiveTourStatus } from 'store/actions/profile';
import RSelect from 'components/FormFields/RSelect';
import cls from './clients.module.scss';
import get from 'lodash/get';
import { getOptions } from 'helpers/clientProfile';
import RSelectAsync from 'components/FormFields/RSelectAsync';

const dropDownStyle = {
  singleValue: base => ({
    ...base,
    color: '#333333 !important',
  }),
  multiValue: base => ({
    ...base,
    background: '#F4F5F7',
    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
    borderRadius: '4px',
    padding: '4px 5px',
  }),
  multiValueLabel: base => ({
    ...base,
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#32325d',
  }),
};

const ClientForm = ({
  closeModal,
  submitValues,
  isModalOpen,
  quickStartTour,
  loading,
}) => {
  const dispatch = useDispatch();
  let initialValues = {
    name: '',
    assignedTo: '',
    start_year: '',
    end_year: '',
  };

  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const ClientSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    assignedTo: Yup.object().required('assignedTo is required'),
    start_year: Yup.number().required('Starting Year is required'),
    end_year: Yup.number()
      .min(Yup.ref('start_year'))
      .required('Ending Year is required'),
  });

  const handleCloseModal = () => {
    closeModal();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'client_creation'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: 1,
        })
      );
    }
  };

  const RenderForm = ({
    handleSubmit,
    isValid,
    values,
    touched,
    errors,
    setFieldValue,
  }) => {
    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        size="md"
        id="createClientModal"
        heading={'Create a Client'}
        submit={{
          id: 'createClientBtn',
          onClick: handleSubmit,
          loading: loading,
          isValid: isValid,
          buttonText: `Create Client`,
        }}
        cancel={{
          onClick: handleCloseModal,
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <label className={cls.formLabel}>Client Name</label>
            <FormikInput
              inputId="clientNameInput"
              groupClassName="mb-3"
              name="name"
              placeholder="Enter client name"
              type="text"
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <label className={cls.formLabel}>Assigned To</label>
            <RSelectAsync
              url="/users/list/dropdown?status=Active&role=manager"
              getOptionLabel={option => option.name}
              placeholder="Select Assignee"
              onChange={value => {
                setFieldValue('assignedTo', value);
              }}
              isClearable={true}
              value={values.assignedTo}
              touched={touched.assignedTo}
              error={errors.assignedTo}
              styles={dropDownStyle}
            />
          </FormGroup>
          <label className={cls.detailLabel}>Study Details</label>
          <br />
          <label className={cls.desc}>
            Request Documents for the Following Years
          </label>
          <Row className={cls.row}>
            <div className={cls.textInputLeftMargin}>
              <RSelect
                name="client_start_year"
                className="mb-3"
                placeholder="Select Starting Year"
                value={
                  values.start_year && {
                    label: values.start_year,
                    value: values.start_year,
                  }
                }
                options={getOptions(yearsList)}
                getOptionLabel={opt => opt.label}
                getOptionValue={opt => opt.value}
                styles={dropDownStyle}
                onChange={value => {
                  setFieldValue('start_year', value?.label);
                }}
              />
            </div>
            <div className={cls.middleMargin}>
              <p>to</p>
            </div>
            <div className={cls.textInputRightMargin}>
              <RSelect
                name="client_end_year"
                className="mb-3"
                placeholder="Select Ending Year"
                value={
                  values.end_year && {
                    label: values.end_year,
                    value: values.end_year,
                  }
                }
                options={getOptions(yearsList)}
                getOptionLabel={opt => opt.label}
                getOptionValue={opt => opt.value}
                styles={dropDownStyle}
                onChange={value => {
                  setFieldValue(`end_year`, value?.label);
                }}
              />
            </div>
          </Row>
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={ClientSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        submitValues(values);
        resetForm();
      }}
    >
      {RenderForm}
    </Formik>
  );
};

export default ClientForm;
