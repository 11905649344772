export const formatCurrency = (
  value,
  isThousandShorted = false,
  minimumFractionDigits = 2
) => {
  if (value === 0 || value === '0') return '$0';

  value = +value;
  if (value) {
    if (isThousandShorted && Math.abs(value) >= 1000) {
      const result = (value - (value % 1000)) / 1000;
      return `${result < 0 ? '-' : ''}$${Math.abs(result)}k`;
    }
    const result = new Intl.NumberFormat('en-Us', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: minimumFractionDigits,
    }).format(value);
    return `$${result}`;
  }
  return '';
};

export const referralData = [
  {
    id: 1,
    referral_name: 'Banyan Architects',
    created_date: '2022-10-28T19:16:04Z',
    strike_collected: 35200.13,
    referral_fee: '8',
    amount_due: 220000.12,
    payment_id: '2210245',
    tax_year: '2020-20201',
    client_invoiced: 345678.23,
    amt_collected: 123678.23,
    invoice_status: 'Partial payment',
    payout_future: 17760.0,
    payout_sent: 32000.12,
  },
  {
    id: 2,
    referral_name: 'Banyan Architects',
    created_date: '2022-10-28T19:16:04Z',
    strike_collected: 64121.09,
    referral_fee: '8',
    amount_due: 0,
    payment_id: '2210245',
    tax_year: '2020',
    client_invoiced: 64121.09,
    amt_collected: 64121.09,
    invoice_status: 'Paid in full',
    payout_future: 0,
    payout_sent: 5129.69,
  },
  {
    id: 3,
    referral_name: 'BoatMan LLC',
    created_date: '2022-10-28T19:16:04Z',
    strike_collected: 4311.02,
    referral_fee: '8',
    amount_due: 0,
    payment_id: '2210245',
    tax_year: '2020-2021',
    client_invoiced: 4311.02,
    amt_collected: 4311.02,
    invoice_status: 'Paid in full',
    payout_future: 0,
    payout_sent: 6789.0,
  },
  {
    id: 4,
    referral_name: 'Custom Computers, Inc.',
    created_date: '2022-04-22T19:16:04Z',
    strike_collected: 38115.0,
    referral_fee: '8',
    amount_due: 12000.0,
    payment_id: '2204789',
    tax_year: 2021,
    client_invoiced: 22886.87,
    amt_collected: 16341.8,
    invoice_status: 'Partial payment',
    payout_future: 1067.9,
    payout_sent: 36300.0,
  },

  {
    id: 5,
    referral_name: 'Flowers Delivered LLC',
    created_date: '2022-04-22T19:16:04Z',
    strike_collected: 118878.98,
    referral_fee: '8',
    amount_due: 0,
    payment_id: '2204789',
    tax_year: '2020-2021',
    client_invoiced: 118878.98,
    amt_collected: 118878.98,
    invoice_status: 'Paid in full',
    payout_future: 0,
    payout_sent: 13509.9,
  },
  {
    id: 6,
    referral_name: 'SoloStove Inc.',
    created_date: '2022-04-22T19:16:04Z',
    strike_collected: 68008.1,
    referral_fee: '8',
    amount_due: 0,
    payment_id: '2204789',
    tax_year: '2020',
    client_invoiced: 68008.1,
    amt_collected: 68008.1,
    invoice_status: 'Paid in full',
    payout_future: 0,
    payout_sent: 6789.9,
  },
];

export const historyData = [
  {
    id: 1,
    created_date: '2022-10-28T19:16:04Z',
    amount: 38789.12,
    tax_year: 2022,
    payment_id: '2210245',
  },
  {
    id: 2,
    created_date: '2022-04-22T19:16:04Z',
    amount: 49809.9,
    tax_year: 2021,
    payment_id: '2204789',
  },
  {
    id: 3,
    created_date: '2022-11-25T19:16:04Z',
    amount: 9442.0,
    tax_year: 2020,
    payment_id: '2111567',
  },
  {
    id: 4,
    created_date: '2021-07-07T19:16:04Z',
    amount: 22223.34,
    tax_year: 2020,
    payment_id: '2107945',
  },
  {
    id: 5,
    created_date: '2021-03-19T19:16:04Z',
    amount: 13023.98,
    tax_year: 2020,
    payment_id: '2103023',
  },
  {
    id: 6,
    created_date: '2021-01-28T19:16:04Z',
    amount: 65009.0,
    tax_year: 2020,
    payment_id: '2101401',
  },
];

export const payoutsData = [
  {
    id: 1,
    referral_name: 'Banyan Architects',
    tax_year: '2020-2021',
    amt_collected: 13678.23,
    est_payouts: 1023.44,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 123000,
    invoice_status: 'pending',
  },
  {
    id: 2,
    referral_name: 'SoloStove Inc.',
    tax_year: '2020',
    amt_collected: 68008.1,
    est_payouts: 838.21,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 123000,
    invoice_status: 'pending',
  },
  {
    id: 3,
    referral_name: 'BoatMan LLC',
    tax_year: '2020-2021',
    amt_collected: 4311.02,
    est_payouts: 1009.93,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 123000,
    invoice_status: 'pending',
  },
  {
    id: 4,
    referral_name: 'Flowers Delivered LLC',
    tax_year: '2020-2021',
    amt_collected: 3678.23,
    est_payouts: 10454.29,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 130678.23,
    invoice_status: 'unpaid',
  },
  {
    id: 5,
    referral_name: 'Custom Computers, Inc.',
    tax_year: '2020',
    amt_collected: 3678.23,
    est_payouts: 838.21,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 54008.1,
    invoice_status: 'unpaid',
  },
  {
    id: 6,
    referral_name: 'BoatMan LLC',
    tax_year: '2020-2021',
    amt_collected: 4311.02,
    est_payouts: 1009.93,
    strike_collected: 13678.23,
    referral_fee: 8,
    amount_invoiced: 62311.02,
    invoice_status: 'unpaid',
  },
];
