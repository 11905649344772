import React, { useState } from 'react';
import cs from 'classnames';
import QuickActionInviteTeammate from 'assets/img/icons/common/QuickActionInviteTeammate.svg';
import classes from './QuickActions.module.scss';
import InviteUserFromSidebar from 'components/Sidebar/InviteUserFromSidebar';

const InviteTeammate = ({ projectId, studyName }) => {
  const [isOpen, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={classes.actionBox} onClick={openModal}>
        <div className={cs(classes.gradient, classes.inviteTeammate)} />
        <div className={classes.box}>
          <img src={QuickActionInviteTeammate} alt="" />
          <h2>Invite Teammate</h2>
        </div>
      </div>
      {isOpen && (
        <InviteUserFromSidebar
          projectId={projectId}
          studyName={studyName}
          closeModal={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default InviteTeammate;
