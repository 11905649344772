import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import Description from 'views/pages/Backlogs/Stories/StoryModal/Fields/Description';
import FileDropZone from 'components/FormFields/FileDropZone';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import RSelect from 'components/FormFields/RSelect';
import FormikInput from 'components/FormFields/Input/FormikInput';
import PropTypes from 'prop-types';
import useTraceUpdate from 'helpers/useTraceUpdate';
import Col from 'reactstrap/es/Col';

import classes from './CreateTask.module.scss';
import PeopleSelector from 'components/PeopleSelector';
import UserInfo from 'components/UserInfo';
import classNames from 'classnames';
import each from 'lodash/each';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import warning from 'react-redux/lib/utils/warning';

const dropDownStyle = {
  singleValue: base => ({
    ...base,
    color: '#333333 !important',
  }),
  multiValue: base => ({
    ...base,
    background: '#F4F5F7',
    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
    borderRadius: '4px',
    padding: '4px 5px',
  }),
  multiValueLabel: base => ({
    ...base,
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#32325d',
  }),
};

const CreateTaskForm = ({
  closeModal,
  submitValues,
  isModalOpen,
  isLoading,
  clientId,
  companyId,
  clientName,
}) => {
  useTraceUpdate('hello', {
    closeModal,
    submitValues,
    isModalOpen,
    isLoading,
  });
  const hasValues = useRef();
  const [editField, setEditField] = useState(null);

  const priorities = useSelector(({ story }) =>
    get(story, 'priorityOptions.data', [])
  );

  const taskTypeOptions = useSelector(({ story }) =>
    get(story, 'taskTypes.data', [])
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = e => {
    if (isModalOpen && e.key === 'Escape') {
      handleClose();
    }
  };

  const handleClose = () => {
    if (hasValues.current) {
      return AlertPopupHandler.openCustom({
        onConfirm: () => {
          closeModal();
        },
        type: 'warning',
        title: 'Cancel task creation?',
        confirmBtnText: `Yes, Cancel Task`,
        confirmBtnCssClass: classes.taskAlertConfirmButton,
        cancelBtnText: `No, Go Back`,
        cancelBtnCssClass: classes.taskAlertCancelBtn,
        reverseButtons: true,
        text: (
          <div className="d-flex flex-column">
            <div className={classes.taskAlertPopperText}>
              Are you sure you want to close the “create a task” window? You
              will lose any information you have already entered.
            </div>
          </div>
        ),
      });
    }
    return closeModal();
  };

  const getDisplayValue = taskOwner => {
    return (
      <UserInfo info={taskOwner} showName={false} avatarClassName="medium" />
    );
  };

  const CreateTaskSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Task name is required'),
    client: Yup.object().required('Please select client'),
    study: Yup.object().required('Please select study'),
    type: Yup.object().required('Please select task type'),
    // epic: Yup.object().required('Please select epic'),
    taskOwner: Yup.object().required('Please select the owner'),
    year: Yup.object().when('type', {
      is: val => val?.text === 'Document Request',
      then: Yup.object().required('Please select the year'),
      otherwise: Yup.object().nullable(),
    }),
    folder: Yup.object().when('type', {
      is: val => val?.text === 'Document Request',
      then: Yup.object().required('Please select the folder'),
      otherwise: Yup.object().nullable(),
    }),
  });

  const CustomOption = ({ innerProps, selectProps, data }) => {
    return (
      <div className="d-flex flex-column p-2">
        <span
          onClick={innerProps.onClick}
          className={classNames('storyPriority', `priority${data.text}`)}
        >
          {data.text}
        </span>
      </div>
    );
  };

  const RenderForm = ({
    handleSubmit,
    closeModal,
    isValid,
    handleReset,
    values,
    touched,
    errors,
    setFieldValue,
  }) => {
    hasValues.current = Object.values(values).some(val => {
      if (typeof val === 'object') {
        return val?.length > 0;
      }
      return !!val;
    });

    const onFileChange = attachments => {
      setFieldValue('attachments', attachments);
    };

    return (
      <>
        <ModalBody className="pb-0">
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <FormikInput
                groupClassName={classes.formField}
                name="name"
                placeholder="Task Name"
                type="text"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <RSelectAsync
                url="/clients/list/dropdown"
                getOptionLabel={option => option.name}
                placeholder="Select Client"
                onChange={value => {
                  setFieldValue('study', null);
                  setFieldValue('year', null);
                  setFieldValue('folder', null);
                  setFieldValue('taskOwner', null);
                  // setFieldValue('epic', null);
                  setFieldValue('client', value);
                }}
                isClearable={true}
                value={values.client}
                touched={touched.client}
                error={errors.client}
                styles={dropDownStyle}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <RSelectAsync
                url={
                  values.client
                    ? `/initiatives/list/dropdown?client=${values.client.id}&status=active`
                    : ''
                }
                getOptionLabel={option => option.name}
                placeholder="Select Study"
                onChange={value => {
                  setFieldValue('year', null);
                  setFieldValue('folder', null);
                  // setFieldValue('epic', null);
                  setFieldValue('study', value);
                }}
                isClearable={true}
                isDisabled={!values.client}
                value={values.study}
                touched={touched.study}
                error={errors.study}
                styles={dropDownStyle}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <RSelect
                options={Object.keys(taskTypeOptions).map(k => ({
                  text: taskTypeOptions[k],
                  id: k,
                }))}
                placeholder="Select Task Type"
                onChange={value => {
                  setFieldValue('year', null);
                  setFieldValue('folder', null);
                  setFieldValue('type', value);
                  setFieldValue('taskOwner', null);
                }}
                value={values.type}
                touched={touched.type}
                error={errors.type}
                isClearable={true}
                styles={dropDownStyle}
              />
            </FormGroup>
            {values?.type?.text === 'Document Request' && (
              <FormGroup className="row mb-3">
                <Col md={6}>
                  <RSelectAsync
                    url={
                      values.study
                        ? `/financial-years?initiative=${values.study.id}`
                        : ''
                    }
                    isDisabled={!values.study}
                    getOptionLabel={option => option.year}
                    placeholder="Select Year"
                    onChange={value => {
                      setFieldValue('folder', null);
                      setFieldValue('year', value);
                    }}
                    isClearable={true}
                    value={values.year}
                    touched={touched.year}
                    error={errors.year}
                    styles={dropDownStyle}
                  />
                </Col>
                <Col md={6}>
                  <RSelectAsync
                    url={
                      values.year
                        ? `/financial-years/${values.year.id}/document-types/list/dropdown?company_id=${values.client.company_id}`
                        : ''
                    }
                    getOptionLabel={option => option.name}
                    isDisabled={!values.year}
                    placeholder="Select Folder"
                    onChange={value => {
                      setFieldValue('folder', value);
                      setFieldValue('taskOwner', null);
                    }}
                    isClearable={true}
                    value={values.folder}
                    touched={touched.folder}
                    error={errors.folder}
                    styles={dropDownStyle}
                  />
                </Col>
              </FormGroup>
            )}
            {/*<FormGroup className="mb-3">*/}
            {/*  <RSelectAsync*/}
            {/*    url={*/}
            {/*      values.study*/}
            {/*        ? `/initiatives/${values.study.id}/epics/dropdown`*/}
            {/*        : ''*/}
            {/*    }*/}
            {/*    getOptionLabel={option => option.name}*/}
            {/*    isDisabled={!values.study}*/}
            {/*    placeholder="Select Phase"*/}
            {/*    onChange={value => {*/}
            {/*      setFieldValue('epic', value);*/}
            {/*    }}*/}
            {/*    isClearable={true}*/}
            {/*    value={values.epic}*/}
            {/*    touched={touched.epic}*/}
            {/*    error={errors.epic}*/}
            {/*    styles={dropDownStyle}*/}
            {/*  />*/}
            {/*</FormGroup>*/}
            <FormGroup className="row">
              <Col md={6} className="d-flex flex-column">
                <label className={classes.formLabel}>Priority</label>
                <div className={classes.priorityWrapper}>
                  <RSelect
                    options={Object.keys(priorities).map(k => ({
                      text: priorities[k],
                      id: k,
                    }))}
                    placeholder="Select Task Type"
                    onChange={value => {
                      setFieldValue('priority', value);
                    }}
                    value={values.priority}
                    touched={touched.priority}
                    error={errors.priority}
                    isClearable={true}
                    isSearchable={false}
                    styles={{
                      ...dropDownStyle,
                      singleValue: (base, { data }) => {
                        switch (data.text) {
                          case 'Low':
                            return {
                              ...base,
                              padding: '2px 8px',
                              borderRadius: '11px',
                              fontSize: '10px',
                              fontWeight: 600,
                              color: '#1aae6f !important',
                              backgroundColor: '#b0eed3',
                            };
                          case 'Medium':
                            return {
                              ...base,
                              padding: '2px 8px',
                              borderRadius: '11px',
                              fontSize: '10px',
                              fontWeight: 600,
                              color: '#fb6340 !important',
                              backgroundColor: '#ffe3b8',
                            };
                          case 'High':
                            return {
                              ...base,
                              padding: '2px 8px',
                              borderRadius: '11px',
                              fontSize: '10px',
                              fontWeight: 600,
                              color: '#f80031 !important',
                              backgroundColor: '#fdd1da',
                            };
                        }
                      },
                    }}
                    components={{
                      Option: CustomOption,
                    }}
                  />
                </div>
              </Col>
              <Col md={6} className="d-flex flex-column">
                <label className={classes.formLabel}>Assign</label>
                <PeopleSelector
                  id="task-assign"
                  onChange={selectedOwner => {
                    setFieldValue('taskOwner', selectedOwner);
                  }}
                  url={
                    values.client
                      ? values.folder?.name === 'Other Documents' ||
                        values?.type?.text === 'Question or Action Item' ||
                        !values.folder?.name
                        ? `/users/list/dropdown?company=${values.client.company_id}`
                        : `/users/list/dropdown?company=${values.client.company_id}&exclude_roles=Client_Administrator`
                      : ''
                  }
                  isDisabled={!values.client}
                  placeholder="Assign a Task Owner"
                >
                  <div>
                    {values.taskOwner ? (
                      getDisplayValue(values.taskOwner)
                    ) : (
                      <Badge
                        className={classNames(classes.plusIcon, classes.badge)}
                      >
                        +
                      </Badge>
                    )}
                  </div>
                </PeopleSelector>
              </Col>
            </FormGroup>
            <FormGroup>
              <div className={classes.descriptionWrapper}>
                <Description
                  editField={editField}
                  description={values.description}
                  isEditAllowed={true}
                  onClick={() => {
                    if (editField !== 'description') {
                      setEditField('description');
                    }
                  }}
                  onCancel={() => {
                    setEditField();
                  }}
                  changeDescription={desc => {
                    setFieldValue('description', desc);
                    setEditField();
                  }}
                  style={{ color: '#333333' }}
                />
              </div>
            </FormGroup>
            <FormGroup className="d-flex flex-column">
              <label className={classes.formLabel}>Attachment</label>
              <FileDropZone
                handleFileChange={onFileChange}
                onRemove={onFileChange}
                attachment={values.attachments}
                inputProps={{
                  multiple: true,
                }}
                shouldAppendFiles={true}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between pt-0">
          <Button onClick={handleClose} color="link" className={'float-right'}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || isLoading}
            color={!isValid || isLoading ? 'secondary' : 'primary'}
            className={'float-left'}
            loading={isLoading}
          >
            Create Task
          </Button>
        </ModalFooter>
      </>
    );
  };

  const initialValues = {
    client: null,
    name: '',
    study: null,
    type: null,
    year: null,
    folder: null,
    // epic: null,
    description: null,
    priority: null,
    taskOwner: null,
    attachments: [],
  };

  if (clientId) {
    initialValues.client = {
      id: clientId,
      name: clientName,
      company_id: companyId,
    };
  }

  const handleCreateTask = async (values, { setSubmitting, resetForm }) => {
    const {
      name,
      client,
      study,
      // epic,
      type,
      year,
      folder,
      description,
      priority,
      taskOwner,
      attachments,
    } = values;
    setSubmitting(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('client_id', client.id);
    formData.append('initiative_id', study.id);
    // formData.append('epic_id', epic.id);
    formData.append('task_type', type.text);
    if (year) formData.append('financial_year_id', year.id);
    if (folder) formData.append('document_type_id', folder.id);
    if (priority) formData.append('priority_id', priority.id);
    formData.append('owner_id', taskOwner.id);
    if (description) formData.append('description', description);
    if (attachments.length > 0) {
      each(attachments, (attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    await submitValues(formData);
    resetForm();
  };

  return (
    <Modal
      toggle={handleClose}
      centered
      isOpen={isModalOpen}
      title="Create a Task"
      size="md"
      backdrop={false}
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={CreateTaskSchema}
        enableReinitialize
        onSubmit={handleCreateTask}
      >
        {props => <RenderForm {...{ ...props, closeModal }} />}
      </Formik>
    </Modal>
  );
};

CreateTaskForm.propTypes = {
  closeModal: PropTypes.func,
  submitValues: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CreateTaskForm.defaultProps = {
  closeModal: () => {},
  submitValues: () => {},
  isModalOpen: false,
  isLoading: false,
};

export default CreateTaskForm;
