import Request from './request';

export const fetchList = async (page, sort, query, limit, projectId) => {
  let url = `/users`;
  if (projectId) {
    url = `/initiatives/${projectId}/team-members`;
  }
  return Request.call({
    url,
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      limit,
    },
  });
};

export const getRoleOptions = async () => {
  return Request.call({
    url: `/roles/list/dropdown`,
    method: 'GET',
  });
};

export const inviteClientRoleOptions = async () => {
  return Request.call({
    url: `/roles/list/dropdown?inviting_client=true`,
    method: 'GET',
  });
};

export const updateRole = async (id, data) => {
  return Request.call({
    url: `/users/${id}/roles`,
    method: 'POST',
    data,
  });
};

export const sendInvitations = async data => {
  return Request.call({
    url: `/users/invitations/send`,
    method: 'POST',
    data,
  });
};

export const resendInvitation = async (id, params) => {
  return Request.call({
    url: `/users/invitations/resend/${id}`,
    method: 'POST',
    params,
  });
};

export const reInvite = async (id, data, params) => {
  return Request.call({
    url: `/users/invitations/invite-deactivated/${id}`,
    method: 'POST',
    data,
    params,
  });
};

export const deactivate = async (id, params) => {
  return Request.call({
    url: `/users/${id}/deactivate`,
    method: 'POST',
    params,
  });
};

export const cancel = async (id, data = {}) => {
  return Request.call({
    url: `/users/invitations/cancel/${id}`,
    method: 'POST',
    data,
  });
};

export const editUser = (user, data) => {
  return Request.call({
    url: `/users/${user}`,
    method: 'PUT',
    data,
  });
};

export const deleteUser = async (id, params) => {
  return Request.call({
    url: `/users/${id}/delete`,
    method: 'POST',
    params,
  });
};

export const impersonateUser = async id => {
  return Request.call({
    url: `/user/${id}/impersonate`,
    method: 'POST',
  });
};
