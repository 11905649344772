import React from 'react';
import PropTypes from 'prop-types';
import classes from './Progress.module.scss';
import { Progress as BaseProgress } from 'reactstrap';
import cs from 'classnames';
const Progress = ({ type, className, ...rest }) => {
  return (
    <BaseProgress
      color={type}
      className={cs(classes[type], className)}
      {...rest}
    />
  );
};

Progress.propTypes = {
  type: PropTypes.oneOf(['default', 'error', 'success']),
};
Progress.defaultProps = {
  type: 'default',
  className: '',
};
export default Progress;
