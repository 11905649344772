import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import RecentlyViewedProjects from 'views/pages/ClientProfile/RecentlyViewedProjects';
import PersonalDashboardQuickActions from 'views/pages/QuickActions/PersonalDashboardQuickActions';
import ClientTasks from 'views/pages/dashboards/Fields/ClientTasks';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import RecentActivity from 'views/pages/ClientProfile/RecentActivity';
import Deliverables from 'views/pages/Project/Dashboard/Fields/Deliverables';
import DeliverablesModal from 'components/DeliverablesModal';
import { addDeliverableToStudy } from 'store/actions/projectDashboard';
import {
  fetchDeliverablesList,
  doUpdateDeliverable,
  doResetActiveDeliverables,
  getDeliverable as getDeliverableData,
} from 'store/actions/clientProfile';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import ClientTeams from 'views/pages/ClientProfile/ClientTeams';
import FileViewer from 'components/FileViewer';
import { useDebounce } from 'react-use';

const AccountOverview = ({
  clientId,
  actionToPerform,
  handleClientTeamAction,
  onStoryClick,
  onClientTaskRowClick,
  getDeliverable,
  clientName,
  companyId,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakPoint('sm', 'down');
  const [showDeliverablesModal, setShowDeliverablesModal] = useState(false);
  const [viewMode, setViewMode] = useState('');
  const [viewDeliverable, setViewDeliverable] = useState(false);
  const [file, setFile] = useState();
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('clientProfile', field, defaultValue);
  const handleEditDeliverables = async deliverable => {
    await getDeliverable(deliverable.id);
    setViewMode('edit');
    setShowDeliverablesModal(true);
  };

  const handleDeliverableModalClose = () => {
    setShowDeliverablesModal(false);
    dispatch(doResetActiveDeliverables());
  };

  useDebounce(
    () => {
      if (showDeliverablesModal === false) {
        setViewMode('');
      }
    },
    1500,
    [showDeliverablesModal]
  );

  const handleDeleteFile = (file, id) => {
    AlertPopupHandler.open({
      onConfirm: async () => {
        const formData = new FormData();
        formData.append(`deleted_attachments[0]`, file.id);
        formData.append('_method', 'PUT');
        await dispatch(doUpdateDeliverable(formData, id));
        setShowDeliverablesModal(false);
      },
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>
            You are about to delete “{file.name}”. Do you want to continue?
          </div>
        </div>
      ),
    });
  };

  const handleViewDeliverableToggle = () => {
    setViewDeliverable(!viewDeliverable);
    dispatch(doResetActiveDeliverables());
  };

  const handleViewDeliverable = async id => {
    const fileData = await dispatch(getDeliverableData(id));
    setFile(fileData);
    setViewDeliverable(true);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4}>
          <RecentlyViewedProjects />
          <Deliverables
            reducerKey="clientProfile"
            setShowDeliverablesModal={() => setShowDeliverablesModal(true)}
            setEditDeliverables={handleEditDeliverables}
            setViewDeliverable={handleViewDeliverable}
            onDeleted={() => {
              dispatch(fetchDeliverablesList(clientId));
            }}
          />
        </Col>
        <Col md={8}>
          <PersonalDashboardQuickActions
            clientId={clientId}
            clientName={clientName}
            companyId={companyId}
          />
          <ClientTeams
            companyId={companyId}
            clientId={clientId}
            isMobile={isMobile}
            getDetails={useGetDetails}
            handleClientTeamAction={handleClientTeamAction}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ClientTasks
            actionToPerform={actionToPerform}
            studyDashboard={true}
            isMobile={isMobile}
            getDetails={useGetDetails}
            onRowClick={onClientTaskRowClick}
            filterByEmployees={false}
          />
        </Col>
        <Col md={6}>
          <RecentActivity clientId={clientId} onStoryClick={onStoryClick} />
        </Col>
      </Row>
      <DeliverablesModal
        reducerKey="clientProfile"
        clientId={clientId}
        handleClose={handleDeliverableModalClose}
        isOpen={showDeliverablesModal}
        handleDeleteFile={handleDeleteFile}
        handleSubmit={async (values, { resetForm }) => {
          const formData = new FormData();
          const { name, description, attachment, study } = values;
          if (attachment.size > 51200000) {
            return AlertPopupHandler.open({
              onConfirm: () => {},
              confirmBtnText: `Ok`,
              showCancel: false,
              title: 'Upload Unsuccessful',
              text: (
                <div className="d-flex flex-column">
                  <div>Maximum allowed file size is 50 MB</div>
                </div>
              ),
            });
          }
          formData.append('name', name);
          formData.append('client_id', clientId);
          formData.append(`attachment`, attachment);
          formData.append('study_id', study.id);
          if (viewMode === 'edit') {
            formData.append('_method', 'PUT');
            await dispatch(doUpdateDeliverable(formData, values.id));
          } else {
            formData.append('description', description);
            await dispatch(addDeliverableToStudy(formData));
          }
          dispatch(fetchDeliverablesList(clientId));
          resetForm();
          setShowDeliverablesModal(false);
        }}
        viewMode={viewMode}
      />
      {viewDeliverable && (
        <FileViewer
          isOpen={viewDeliverable}
          toggle={handleViewDeliverableToggle}
          fileData={file?.attachment}
          title="Preview"
        />
      )}
    </Container>
  );
};

export default AccountOverview;
