import React, { useState } from 'react';
import cx from 'classnames';
import { Card, CardBody, Row, Col } from 'reactstrap';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import Owner from './Fields/ProjectDetails/Owner';
import TextField from './Fields/ProjectDetails/TextField';
import StartDate from './Fields/ProjectDetails/StartDate';
import { useAccess, permissions } from 'helpers/permission';
import find from 'lodash/find';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import {
  editProject,
  duplicateProject,
  addProjectMember,
  deleteProjectMember,
} from 'store/actions/projects';
import useBreakPoint from 'helpers/useBreakPoint';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Budget from './Fields/ProjectDetails/Budget';
import Collaborators from 'views/pages/Project/Dashboard/Fields/ProjectDetails/Collaborators';
import StoryStatus from 'components/Status';
import Button from 'components/Button';
import EngagementYears from 'views/pages/Project/Dashboard/Fields/ProjectDetails/EnagagmentYears';
import { getOptions } from 'helpers/clientProfile';

const Overview = () => {
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_dashboard,
      ...rest,
    });
  };
  const dispatch = useDispatch();
  const useGetFieldValue = (field, nullValue) =>
    useGetFieldFromObjects(
      'project',
      `getProject.data.data.${field}`,
      nullValue
    );
  const isMobile = useBreakPoint('xs', 'down');
  const projectMemberDetails = useGetFieldValue('team_members', []);
  const studyStatus = useGetFieldValue('status');
  const studyPhase = useGetFieldValue('project_phase');
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const showNextYearStudyOption = useGetFieldValue(
    'show_next_study_option',
    false
  );
  const isClient = useSelector(({ auth }) => auth.user.is_client, false);
  const userRoles = useSelector(({ auth }) => auth.user.roles, []);

  const isClientAdministrator =
    isClient && userRoles.some(role => role === 'Client_Administrator');

  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );
  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_PROJECTS,
    {
      permission: permissions.EDIT_MY_PROJECTS,
      value: isUserMemberOfTeam,
    },
  ]);
  const statusOptions = useSelector(({ project }) =>
    get(project, 'initiative_statuses.data', {})
  );
  const studyPhases = useSelector(({ metadata }) =>
    get(metadata, 'studyPhases.data', {})
  );
  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const projectId = useGetFieldValue('id');
  const name = useGetFieldValue('name');
  const onChange = (field, value) => {
    if (field === 'staff_members' || field === 'lead_members') {
      dispatch(addProjectMember(projectId, value, field));
    } else {
      dispatch(editProject(projectId, { projectName: name, [field]: value }));
    }
  };
  const handleCancelStaff = (type, id) => {
    dispatch(deleteProjectMember(projectId, id, type));
  };

  const [isLoading, setIsLoading] = useState(false);

  const commonProps = {
    getFieldValue: useGetFieldValue,
    isEditAllowed: isUserAllowedEdit,
    defaultValue: '--',
    onChange,
    isMobile,
    analyticsSendEvent,
    analyticsConstants,
  };

  const handleStatusChange = data => {
    onChange('status', data);
  };

  const handlePhaseChange = data => {
    onChange('project_phase', data);
  };

  const handleDuplicateProject = async () => {
    setIsLoading(true);
    await dispatch(duplicateProject(projectId));
    setIsLoading(false);
  };

  return (
    <Card>
      <CardBody className={classes['overview']}>
        <h3>Study Overview</h3>
        <div className={classes.projectOverview}>
          <Row>
            <Col xs={12}>
              <TextField
                {...commonProps}
                placeholder="Set study name"
                name="study_name"
                fieldKey="study_name"
                title="STUDY NAME"
                noDataValue="-"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Owner
                {...commonProps}
                fieldKey="lead_members"
                name="lead_members"
                title="PROJECT LEAD"
                isMulti
                onCancel={handleCancelStaff}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Owner
                {...commonProps}
                fieldKey="staff_members"
                name="staff_members"
                title="PROJECT STAFF"
                placeholder="Assign Project Staff"
                isMulti
                onCancel={handleCancelStaff}
                userRole=""
              />
            </Col>
          </Row>
          {!isClientAdministrator && (
            <Row>
              <Col xs={12}>
                <EngagementYears
                  {...commonProps}
                  isMulti
                  fieldKey="engagement_years"
                  name="engagement_years"
                  title="YEARS OF ENGAGEMENT"
                  placeholder="Years"
                  options={getOptions(yearsList)}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <EngagementYears
                {...commonProps}
                isMulti
                fieldKey="current_study"
                name="current_study"
                title="CURRENT STUDY"
                placeholder="Years"
                options={getOptions(yearsList)}
              />
              {/*<Budget
                {...commonProps}
                isEditAllowed={false}
                type="text"
                fieldKey="current_study"
                name="current_study"
                title="CURRENT STUDY"
              />*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
                CURRENT PHASE
              </h6>
              <StoryStatus
                statuses={studyPhases}
                selectedStatus={studyPhase}
                handleChange={handlePhaseChange}
                labelField="label"
                disabled={isClient}
                storyDashboard
              />
            </Col>
          </Row>
          {!isClientAdministrator && (
            <>
              <Row>
                <Col xs={12}>
                  <h6
                    className={cx(
                      'text-uppercase',
                      'text-muted',
                      classes.title
                    )}
                  >
                    STUDY STATUS
                  </h6>
                  <StoryStatus
                    statuses={statusOptions}
                    selectedStatus={studyStatus}
                    handleChange={handleStatusChange}
                    labelField="label"
                    disabled={isClient}
                    storyDashboard
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <StartDate
                    {...commonProps}
                    showIcon={true}
                    placeholder="Add a Kick Off Date"
                    fieldKey="kick_of_date"
                    title="KICK OFF DATE"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <StartDate
                    {...commonProps}
                    showIcon={true}
                    fieldKey="filing_deadline"
                    title="FILING DEADLINE"
                    placeholder="Add a Filling Deadline"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <StartDate
                    {...commonProps}
                    showIcon={true}
                    fieldKey="estimated_delivery_date"
                    title="ESTIMATED DELIVERY DATE"
                    placeholder="Add an Estimated Delivery Date"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Budget
                    {...commonProps}
                    isEditAllowed={!isClient}
                    placeholder="Current Credit Estimate"
                    leftIcon="$"
                    name="current_credit_estimate"
                    viewModeIcon="fa-dollar-sign"
                    fieldKey="current_credit_estimate"
                    title="CURRENT CREDIT ESTIMATE"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Budget
                    {...commonProps}
                    isEditAllowed={!isClient}
                    placeholder="Federal Credits Delivered"
                    leftIcon="$"
                    name="federal_credits_delivered"
                    viewModeIcon="fa-dollar-sign"
                    fieldKey="federal_credits_delivered"
                    title="FEDERAL CREDITS DELIVERED"
                    noDataValue="-"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Budget
                    {...commonProps}
                    isEditAllowed={!isClient}
                    placeholder="State Credits Delivered"
                    leftIcon="$"
                    name="state_credits_delivered"
                    viewModeIcon="fa-dollar-sign"
                    fieldKey="state_credits_delivered"
                    title="STATE CREDITS DELIVERED"
                    noDataValue="-"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Budget
                    {...commonProps}
                    isEditAllowed={false}
                    defaultValue={commonProps.getFieldValue(
                      'total_credits_delivered'
                    )}
                    placeholder="Total Credits Delivered"
                    leftIcon="$"
                    name="total_credits_delivered"
                    viewModeIcon="fa-dollar-sign"
                    fieldKey="total_credits_delivered"
                    title="TOTAL CREDITS DELIVERED"
                    noDataValue="-"
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={12}>
              <Collaborators {...commonProps} />
            </Col>
          </Row>
          {!isClient &&
            showNextYearStudyOption &&
            studyStatus?.label === 'Completed' && (
              <Row className="mt-4">
                <Col xs={12}>
                  <div className={classes.roleTitle}>
                    <span>Ready to start next year’s study?</span>
                  </div>
                  <Button
                    className="w-100"
                    onClick={handleDuplicateProject}
                    color={isLoading ? 'secondary' : 'primary'}
                    loading={isLoading}
                  >
                    Let’s Get Started
                  </Button>
                </Col>
              </Row>
            )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Overview;
