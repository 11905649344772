import React from 'react';
import { CardBody, Card } from 'reactstrap';
import InviteTeamMember from './InviteTeamMember';
import CreateTask from './CreateTask';
import classes from 'views/pages/QuickActions/QuickActions.module.scss';
import cs from 'classnames';
import history from 'helpers/history';
import ViewDocuments from 'assets/img/icons/common/ViewDocuments.svg';
import ViewKanban from 'views/pages/QuickActions/ViewKanban';

const PersonalDashboardQuickActions = ({ clientId, clientName, companyId }) => {
  return (
    <Card className="d-flex">
      <CardBody className="d-flex flex-column position-relative pt-3">
        <h5 className="text-uppercase text-muted mb-0 card-title">
          Quick Actions
        </h5>
        <div className="d-flex w-100 mt-2 justify-content-between flex-wrap">
          <CreateTask
            clientId={clientId}
            clientName={clientName}
            companyId={companyId}
          />
          {!clientId && <ViewKanban />}
          {!clientId && <InviteTeamMember isPersonalDashboard />}
          {clientId && (
            <div
              className={classes.actionBox}
              onClick={() =>
                history.push(`/admin/accounts/${clientId}/documents`)
              }
            >
              <div className={cs(classes.gradient, classes.createStory)} />
              <div className={classes.box}>
                <img src={ViewDocuments} alt="" />
                <h2>View All Documents</h2>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

PersonalDashboardQuickActions.propTypes = {};

export default PersonalDashboardQuickActions;
