import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import {
  fetchActiveStudy,
  getMyOpenTasks,
  fetchDeliverables,
  fetchCompletedStudies,
} from 'store/actions/userDashboard';
import ActiveStudy from 'views/widgets/ActiveStudy';
import RecentActivity from '../Fields/RecentActivity';
import queryString from 'query-string';
import { toNumber, get } from 'lodash';
import { useLocation } from 'react-router';
import TaskModal from 'views/pages/dashboards/TaskModal';
import history from 'helpers/history';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import InviteTeammate from 'views/pages/Project/Dashboard/Fields/QuickActions/InviteTeammate';
import ScheduleMeeting from 'views/pages/Project/Dashboard/Fields/QuickActions/ScheduleMeeting';
import Deliverables from 'views/pages/Project/Dashboard/Fields/Deliverables';
import MyOpenTasks from 'views/pages/dashboards/Fields/MyOpenTasks';
import { getDeliverable } from 'store/actions/clientProfile';
import { clearDocumentUrl, downloadDocument } from 'store/actions/documents';
import FileViewer from 'components/FileViewer';

const ClientDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchActiveStudy());
    dispatch(fetchCompletedStudies());
    dispatch(fetchDeliverables());
  }, [dispatch]);
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const [storyId, setStoryId] = useState('');
  const [comment, setComment] = useState(null);
  const tab = useRef('comment');
  const [isTaskModalOpen, setIsTaskModal] = useState(false);
  const [file, setFile] = useState();
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');
  const [isRenderDeliverables, setIsRenderDeliverable] = useState(false);
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('userDashboard', field, defaultValue);
  const recentActivityMarginTop = { marginTop: '3rem' };
  const recentActivityMarginLeft = {
    marginTop: '0.25rem',
  };
  const flexHeight = { height: '190px' };
  const boxHeight = { height: '98px' };
  const pageHeading = {
    'font-weight': 'bold',
    'font-size': '26px',
    'font-family': 'Open Sans',
    'font-style': 'normal',
    'margin-top': '20px',
    'margin-bottom': '24px',
  };

  useEffect(() => {
    if (queryProps.story) {
      setStoryId(toNumber(queryProps.story));
      setIsTaskModal(true);
    }
  }, [queryProps.story]);

  const handleActionToPerform = (params = {}) => {
    params.userId = currentUser.id;
    dispatch(getMyOpenTasks(params));
  };

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    dispatch(clearDocumentUrl());
  };

  const handleDownload = async id => {
    const fileData = await dispatch(getDeliverable(id));
    dispatch(downloadDocument(fileData.attachment));
  };

  const viewFileHandle = async id => {
    const fileData = await dispatch(getDeliverable(id));
    setFile(fileData);
    setFileViewerOpen(true);
  };

  const commonProps = {
    isMobile,
    getDetails: useGetDetails,
    isTablet,
    actionToPerform: handleActionToPerform,
  };

  const activeStudy = useGetFieldFromObject(
    'userDashboard',
    'activeStudy.data',
    null
  );

  const isInProgress = useGetFieldFromObject(
    'userDashboard',
    'activeStudy.isInProgress',
    true
  );

  useEffect(() => {
    setIsRenderDeliverable(false);
  }, []);
  useEffect(() => {
    const disablePhase = ['Onboarding', 'Document Gathering'];
    const idProjectPhase = get(activeStudy, 'project_phase.label', '');
    const check =
      !isInProgress && // Dashboard still keep the old data when retreiving new data for Active study
      idProjectPhase && // Ensure that idProjectPhase has value (not Empty => It will be true if role != Administrator)
      currentUser?.roles[0] !== 'Client_Administrator' &&
      !disablePhase.includes(idProjectPhase);
    setIsRenderDeliverable(check);
  }, [activeStudy, currentUser, isInProgress]);

  return (
    <>
      {isFileViewerOpen && (
        <FileViewer
          isOpen={isFileViewerOpen}
          toggle={handleFileViewer}
          fileData={file?.attachment}
          title="Preview"
        />
      )}
      <Container fluid>
        <h4 style={pageHeading}>Dashboard</h4>
        <Row className="mt-2">
          <Col md="8" className="p-0">
            {activeStudy?.id && (
              <ActiveStudy content={activeStudy} isInProgress={isInProgress} />
            )}
            <div className="mt-2">
              <MyOpenTasks
                {...commonProps}
                cardHeader="My Open Tasks"
                showSearch={false}
                clientDashboard
                onRowClick={id => {
                  setStoryId(id);
                  setIsTaskModal(true);
                }}
                userId={currentUser.id}
              />
            </div>
          </Col>
          <Col md="4" style={recentActivityMarginLeft}>
            <Card className="d-flex" style={flexHeight}>
              <CardBody className="d-flex flex-column position-relative pt-3">
                <h5 className="text-uppercase text-muted mb-0 card-title">
                  Quick Actions
                </h5>
                <div
                  className="d-flex w-100 mt-3 justify-content-between flex-wrap"
                  style={boxHeight}
                >
                  <ScheduleMeeting />
                  <InviteTeammate />
                </div>
              </CardBody>
            </Card>
            {isRenderDeliverables && (
              <Card className="d-flex" style={flexHeight}>
                <Deliverables
                  reducerKey="userDashboard"
                  setShowDeliverablesModal={() => {}}
                  setEditDeliverables={() => {}}
                  setViewDeliverable={viewFileHandle}
                  onDeleted={() => {}}
                  setDownloadDeliverable={handleDownload}
                />
              </Card>
            )}
            {/* -- Do not remove this comment section ---- */}
            {/* Commenting for now as we need to place Completed Studies section somewhere else, Once place is decide it will move there. */}
            {/* -- Section start ---- */}
            {/* {completedStudies.length > 0 && (
          <Col md>
            <h2>Completed Studies</h2>
            <Row className="overflow-auto flex-nowrap">
              {map(completedStudies, completedStudy => {
                return (
                  <Col md="6">
                    <CompletedStudy content={completedStudy} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        )} */}
            {/* -- Section end ---- */}
            <div style={recentActivityMarginTop}>
              <RecentActivity
                onStoryClick={url => {
                  const params = queryString.parseUrl(url);
                  setComment(get(params, 'query.comment'));
                  tab.current = get(params, 'query.tab', tab.current);
                  setStoryId(get(params, 'query.story'));
                  setIsTaskModal(true);
                }}
                {...commonProps}
              />
            </div>
          </Col>
        </Row>

        {isTaskModalOpen && (
          <TaskModal
            storyId={toNumber(storyId)}
            isOpen={isTaskModalOpen}
            closeModal={() => {
              setIsTaskModal(false);
              tab.current = 'comment';
              history.push(`?`);
            }}
            currentTab={tab.current}
            comment={comment}
          />
        )}
      </Container>
    </>
  );
};

export default ClientDashboard;
