import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStoryStatus } from 'store/actions/backlogs';
import get from 'lodash/get';
import StoryStatus from 'components/Status';
import { analyticsConstants } from 'helpers/analytics';
const StoryStatusDropdown = ({
  story,
  sectionId,
  isStoryEditAllowed,
  analyticsSendEventStory,
  labelField = 'status',
}) => {
  const dispatch = useDispatch();

  const handleStatusChange = status => {
    analyticsSendEventStory({
      action: analyticsConstants.action.update_story_status,
      update_from: 'Project Backlog',
    });
    dispatch(updateStoryStatus(story.id, status, sectionId));
  };
  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );
  return (
    <StoryStatus
      statuses={statuses}
      selectedStatus={story.status}
      handleChange={handleStatusChange}
      disabled={!isStoryEditAllowed}
      labelField={labelField}
    />
  );
};

export default StoryStatusDropdown;
