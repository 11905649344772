import Alternative from 'views/pages/dashboards/Alternative.js';
// import Buttons from 'views/pages/components/Buttons.js';
// import Calendar from 'views/pages/Calendar.js';
// import Cards from 'views/pages/components/Cards.js';
// import Charts from 'views/pages/Charts.js';
// import Components from 'views/pages/forms/Components.js';
import Dashboard from 'views/pages/dashboards/Dashboard';
import ClientDashboard from 'views/pages/dashboards/ClientDashboard';
import Clients from 'views/pages/Clients';
import ClientProfile from './views/pages/ClientProfile';
import Projects from 'views/pages/Project';
// import Elements from 'views/pages/forms/Elements.js';
// import Google from 'views/pages/maps/Google.js';
// import Grid from 'views/pages/components/Grid.js';
// import Icons from 'views/pages/components/Icons.js';
// import Lock from 'views/pages/examples/Lock.js';
import ViewProject from 'views/pages/Project/ViewProject';
import Login from 'views/pages/Auth/Login';
import InviteUsers from 'views/pages/Auth/InviteUsers';
import InitialRegister from 'views/pages/Auth/InitialRegister/InitialRegister';
import AcceptAndSetup from 'views/pages/Auth/AcceptAndSetup';
import Invalid from 'views/pages/Auth/Invalid';
// import Notifications from 'views/pages/components/Notifications.js';
// import Pricing from 'views/pages/examples/Pricing.js';
import Profile from 'views/pages/Profile';
import EditProfile from 'views/pages/Profile/EditProfile';
// import ReactBSTables from 'views/pages/tables/ReactBSTables.js';
import Register from 'views/pages/Auth/Register';
import AuthFlowCompanySettings from 'views/pages/Auth/CompanyDetails';
import VerifyEmail from 'views/pages/Auth/VerifyEmail';
import ForgotPassword from 'views/pages/Auth/ForgotPassword';
import ResetPassword from 'views/pages/Auth/ResetPassword';
import SubscriptionOutdated from 'views/pages/Auth/SubscriptionOutdated';
// import RTLSupport from 'views/pages/examples/RTLSupport.js';
// import Sortable from 'views/pages/tables/Sortable.js';
// import Tables from 'views/pages/tables/Tables.js';
// import Timeline from 'views/pages/examples/Timeline.js';
// import Typography from 'views/pages/components/Typography.js';
// import Validation from 'views/pages/forms/Validation.js';
// import Vector from 'views/pages/maps/Vector.js';
// import Widgets from 'views/pages/Widgets.js';
import BadgePro from 'components/BadgePro';
import KnowledgeBase from 'views/pages/KnowledgeBase';
import KnowledgeBaseArticle from 'views/pages/KnowledgeBaseArticle';
import TimelogReport from 'views/pages/TimelogReport';
import Users from 'views/pages/Users';
import CompanySettings from 'views/pages/CompanySettings';
import Billing from 'views/pages/Billing';
import AssingmentTypeCustomizations from 'views/pages/AssingmentTypeCustomizations';
import { permissions } from 'helpers/permission';
import MyKanban from 'views/pages/Kanban/MyKanban';
import GlobalKanban from 'views/pages/Kanban/GlobalKanban';
import UserKanban from 'views/pages/Kanban/UserKanban';
import MyDocuments from 'views/pages/MyDocuments';
import CodatCallback from 'views/pages/CodatCallback';
import Partners from 'views/pages/Partners';
import PartnerClients from 'views/pages/PartnerClients';
import Earnings from 'views/pages/Earnings';

const userRoutes = [
  {
    path: '/users/:id/edit',
    name: 'Profile',
    component: EditProfile,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/:id/kanban',
    name: 'Personal Kanban',
    miniName: 'PK',
    component: UserKanban,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/:id',
    name: 'Profile',
    miniName: 'P',
    component: Profile,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/users/',
    name: 'User Management',
    component: Users,
    layout: '/admin',
    hide: true,
  },
];

export const adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    miniName: 'D',
    component: Dashboard,
    layout: '/admin',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
  },
  {
    path: '/my-documents',
    name: 'My Documents',
    icon: 'fas fa-file icon-gradient icon-documents',
    component: MyDocuments,
    layout: '/admin',
    hideForAdmin: true,
  },
  {
    path: '/studies',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    component: Projects,
    layout: '/admin',
    isExact: true,
    permission: permissions.LIST_PROJECTS,
  },
  {
    path: '/partners',
    name: 'Partners',
    icon: 'fas fa-gem text-success icon-gradient icon-diamond',
    component: Partners,
    layout: '/admin',
    isExact: true,
    forAdminOnly: true,
  },
  {
    path: '/accounts',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-purple',
    component: Clients,
    layout: '/admin',
    permission: permissions.LIST_CLIENTS,
    forAdminOnly: true,
  },
  {
    collapse: true,
    name: 'Kanban',
    icon: 'ni ni-chart-bar-32 rotate-180 text-red ml--3 mr-3',
    state: 'kanbanCollapse',
    permission: [
      permissions.ACCESS_GLOBAL_KANBAN,
      permissions.ACCESS_GLOBAL_KANBAN,
    ],
    views: [
      {
        isExact: true,
        path: '/kanban/company',
        name: 'Global Kanban',
        miniName: '',
        component: GlobalKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
      {
        isExact: true,
        path: '/kanban/personal',
        name: 'Personal Kanban',
        miniName: '',
        component: MyKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
    ],
    forAdminOnly: true,
  },
  {
    path: '/timelog',
    name: 'Timelog',
    icon: 'fas fa-clock text-yellow',
    component: TimelogReport,
    layout: '/admin',
    permission: permissions.LIST_TIMELOGS,
    hide: true,
  },
  {
    path: '/studies/:id/:page?',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success',
    component: ViewProject,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/company',
    name: 'Company Settings',
    component: CompanySettings,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/assignment-types/:modalOpen?',
    name: 'Assignment Types',
    component: AssingmentTypeCustomizations,
    layout: '/admin',
    hide: true,
    permission: permissions.CREATE_TASK_TYPES,
  },
  ...userRoutes,
];

export const partnerRoutes = [
  {
    path: '/dashboard',
    name: 'Referral',
    miniName: 'D',
    component: Dashboard,
    layout: '/admin',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
  },
  {
    path: '/clients',
    name: 'Clients',
    component: PartnerClients,
    icon: 'fas fa-address-card text-primary icon-gradient icon-dashboard',
    layout: '/admin',
  },
  {
    path: '/earnings',
    name: 'Earnings',
    component: Earnings,
    icon: 'fas fa-usd-circle text-primary icon-gradient icon-dashboard',
    layout: '/admin',
  },
  ...userRoutes,
];

export const partnerManagerRoutes = [
  {
    path: '/partners',
    name: 'Partners',
    icon: 'fas fa-gem text-success icon-gradient icon-diamond',
    component: Partners,
    layout: '/admin',
    isExact: true,
    forAdminOnly: true,
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
  },
  ...userRoutes,
];

export const clientRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    miniName: 'D',
    component: Dashboard,
    layout: '/admin',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
  },

  {
    path: '/my-documents',
    name: 'My Documents',
    icon: 'fas fa-file icon-gradient icon-documents',
    component: MyDocuments,
    layout: '/admin',
    hideForAdmin: true,
  },
  {
    path: '/studies',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    component: Projects,
    layout: '/admin',
    isExact: true,
    permission: permissions.LIST_PROJECTS,
  },
  {
    path: '/company',
    name: 'Company Settings',
    component: CompanySettings,
    layout: '/admin',
    hide: true,
  },
  ...userRoutes,
];

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    miniName: 'D',
    component: Dashboard,
    layout: '/admin',
    icon: 'fas fa-th-large text-primary icon-gradient icon-dashboard',
  },
  {
    path: '/my-documents',
    name: 'My Documents',
    icon: 'fas fa-file icon-gradient icon-documents',
    component: MyDocuments,
    layout: '/admin',
    hideForAdmin: true,
  },
  {
    path: '/studies',
    name: 'Studies',
    icon: 'fas fa-home text-success icon-gradient icon-studies',
    component: Projects,
    layout: '/admin',
    isExact: true,
    permission: permissions.LIST_PROJECTS,
  },
  {
    path: '/partners',
    name: 'Partners',
    icon: 'fas fa-gem text-success icon-gradient icon-diamond',
    component: Partners,
    layout: '/admin',
    isExact: true,
    forAdminOnly: true,
  },
  {
    path: '/accounts',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-purple',
    component: Clients,
    layout: '/admin',
    permission: permissions.LIST_CLIENTS,
    forAdminOnly: true,
  },
  {
    path: '/codat/callback',
    name: 'Codat Callback',
    isExact: false,
    // icon: 'fas fa-handshake text-primary',
    component: CodatCallback,
    layout: '/auth',
    hide: true,
  },
  {
    path: '/accounts/:id/:page',
    name: 'Clients',
    isExact: true,
    icon: 'fas fa-handshake text-primary',
    component: ClientProfile,
    layout: '/admin',
    hide: true,
  },
  {
    collapse: true,
    name: 'Kanban',
    icon: 'ni ni-chart-bar-32 rotate-180 text-red ml--3 mr-3',
    state: 'kanbanCollapse',
    permission: [
      permissions.ACCESS_GLOBAL_KANBAN,
      permissions.ACCESS_GLOBAL_KANBAN,
    ],
    views: [
      {
        isExact: true,
        path: '/kanban/company',
        name: 'Global Kanban',
        miniName: '',
        component: GlobalKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
      {
        isExact: true,
        path: '/kanban/personal',
        name: 'Personal Kanban',
        miniName: '',
        component: MyKanban,
        layout: '/admin',
        permission: permissions.ACCESS_GLOBAL_KANBAN,
      },
    ],
    forAdminOnly: true,
  },
  {
    path: '/timelog',
    name: 'Timelog',
    icon: 'fas fa-clock text-yellow',
    component: TimelogReport,
    layout: '/admin',
    permission: permissions.LIST_TIMELOGS,
    hide: true,
  },
  {
    path: '/studies/:id/:page?',
    isExact: true,
    name: 'Studies',
    icon: 'fas fa-cubes text-success',
    component: ViewProject,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/company',
    name: 'Company Settings',
    component: CompanySettings,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base/:id',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBaseArticle,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/knowledge-base',
    name: 'Knowledge Base',
    icon: 'fas fa-clock text-yellow',
    component: KnowledgeBase,
    layout: '/admin',
    hide: true,
  },
  {
    path: '/company',
    name: 'AuthFlowCompanySettings',
    component: VerifyEmail,
    layout: '/auth',
    hide: true,
  },
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invitations/accept-and-Setup',
    name: 'Accept and setup',
    miniName: 'A',
    component: AcceptAndSetup,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invitations/invalid',
    name: 'Invalid',
    miniName: 'I',
    component: Invalid,
    layout: '/auth',
    redirect: true,
    hide: true,
  },
  {
    path: '/login/google/callback',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/login/microsoft/callback',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/register',
    name: 'Register',
    miniName: 'R',
    component: Register,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/email/verify',
    name: 'Set Password',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/invite',
    name: 'Invite Users',
    miniName: 'SP',
    component: VerifyEmail,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/password/reset',
    name: 'Reset Password',
    miniName: 'RP',
    component: ResetPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/email/reset-password',
    name: 'Reset Password',
    miniName: 'SP',
    component: ForgotPassword,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/subscription/outdated',
    name: 'Subscription outdated',
    miniName: 'SO',
    component: SubscriptionOutdated,
    layout: '/auth',
    redirect: true,
  },
  {
    path: '/assignment-types/:modalOpen?',
    name: 'Assignment Types',
    component: AssingmentTypeCustomizations,
    layout: '/admin',
    hide: true,
    permission: permissions.CREATE_TASK_TYPES,
  },
  ...userRoutes,
];

export default routes;
