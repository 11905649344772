import React, { useState } from 'react';
import UserInfo from 'components/UserInfo';
import PeopleSelector from 'components/PeopleSelector';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import isEmpty from 'lodash/isEmpty';

const Owner = ({
  getFieldValue,
  isEditAllowed,
  defaultValue,
  onChange,
  fieldKey = 'owner',
  name = 'owner',
  title = 'PROJECT LEAD',
  isMulti = false,
  url = '/users/list/dropdown?status=Active',
  placeholder = 'Assign a Study Owner',
  onCancel = () => {},
  analyticsSendEvent,
  analyticsConstants,
  userRole = 'manager',
}) => {
  const value = getFieldValue(fieldKey, isMulti ? [] : {});

  const getDisplayValue = field => {
    if (isEmpty(field) && !isEditAllowed) {
      return defaultValue;
    }

    if (!isMulti || field?.length === 0)
      return <UserInfo info={value} altText={placeholder} />;
    return field?.map((user, index) => (
      <div key={index} className={classes.owner}>
        <UserInfo info={user} altText={placeholder} />
      </div>
    ));
  };

  const handleChange = (selectedValue, action) => {
    switch (action.action) {
      case 'remove-value':
        return onCancel(name, action.removedValue.id);
      case 'select-option':
      default:
        return onChange(name, action.option);
    }
  };

  let apiUrl = userRole != '' ? url + '&role=' + userRole : url;

  return (
    <>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        {title}
      </h6>
      <PeopleSelector
        isMulti={isMulti}
        id="project-value-target"
        disabled={!isEditAllowed}
        onChange={(selectedOwner, action) => {
          analyticsSendEvent({
            action: analyticsConstants.action.edit_project_value,
          });
          !isMulti && onChange(name, selectedOwner);
          handleChange(selectedOwner, action);
        }}
        url={apiUrl}
        placeholder={placeholder}
        defaultValue={isMulti && value?.length > 0 && value}
        menuPosition="absolute"
        menuPlacement="top"
        openMenuFromTop
      >
        <div className={cx(isEditAllowed && classes.hoverHand)}>
          {getDisplayValue(value)}
        </div>
      </PeopleSelector>
    </>
  );
};

export default Owner;
