import Types from 'store/types/AuthTypes';
import get from 'lodash/get';

const initialState = {
  loading: false,
  isError: false,
  message: '',
  status: null,
  isLoggedIn: false,
  isActiveSession: null,
  lastCallRefresh: null,
  isRegistrationFlow: false,
  socialLoginLoading: false,
  user: null,
  register: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: {},
  },
  passwordReset: {
    loading: false,
    isError: false,
    status: null,
    message: '',
    data: {},
  },
  inviteValidityCheck: {
    loading: false,
    isError: false,
    status: null,
    message: '',
    data: {},
  },
  resendInvite: {
    loading: false,
    isError: false,
    status: null,
    message: '',
    data: {},
  },
  emailVerify: {
    loading: false,
    isError: false,
    message: '',
    data: {},
  },
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN_INPROGRESS:
      return {
        ...state,
        loading: true,
        isError: false,
        message: '',
        status: null,
      };

    case Types.LOGIN_SUCCESS: {
      const lastCallRefresh = Date.now();
      localStorage.setItem('expiresAt', lastCallRefresh);
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        isLoggedIn: true,
        lastCallRefresh,
        user: action.user,
      };
    }

    case Types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };
    case Types.LOGIN_DEACTIVATED:
      return {
        ...state,
        user: action.user,
      };
    case Types.LOGOUT_SUCCESS:
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.setItem('expiresAt', null);
      localStorage.setItem('lastActivity', null);
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: null,
        message: action.message,
        isLoggedIn: false,
        user: null,
      };

    case Types.REGISTER_INPROGRESS:
      return {
        ...state,
        register: { loading: true, isError: false, message: '' },
      };

    case Types.REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          loading: false,
          isError: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.REGISTER_FAILURE:
      return {
        ...state,
        register: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.VERIFICATION_INPROGRESS:
      return {
        ...state,
        emailVerify: {
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };

    case Types.VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerify: {
          loading: false,
          isError: false,
          status: action.status,
        },
      };

    case Types.VERIFICATION_FAILURE:
      return {
        ...state,
        emailVerify: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.PASSWORD_RESET_INPROGRESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: true,
          isError: false,
          message: '',
          status: null,
        },
      };

    case Types.RESEND_PASSWORD_LINK_INPROGRESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.PASSWORD_RESET_COMPLETE:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: false,
          status: action.status,
          data: action.data,
          message: action.message,
        },
      };

    case Types.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.DEACTIVATE_USER:
      return {
        ...state,
        isActiveSession: false,
      };

    case Types.GET_PROFILE_INPROGRESS:
      return {
        ...state,
        loading: true,
        isError: false,
        status: 0,
      };

    case Types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isActiveSession: true,
        isError: false,
        status: 1,
        user: {
          ...state.user,
          ...action.user,
        },
      };

    case Types.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        status: 0,
      };

    case Types.UPDATE_TOKEN: {
      const lastCallRefresh = Date.now();
      localStorage.setItem('expiresAt', lastCallRefresh);
      return {
        ...state,
        user: {
          ...state.user,
          auth_token: action.data,
        },
        lastCallRefresh,
      };
    }

    case Types.ACCEPT_INVITE_INPROGRESS:
      return {
        ...state,
        acceptAndSetup: {
          ...state.acceptAndSetup,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.ACCEPT_INVITE_SUCCESS: {
      const lastCallRefresh = Date.now();
      localStorage.setItem('expiresAt', lastCallRefresh);
      localStorage.setItem('isLoggedIn', 'true');
      return {
        ...state,
        acceptAndSetup: {
          ...state.acceptAndSetup,
          loading: false,
        },
        isActiveSession: true,
        isLoggedIn: true,
        lastCallRefresh,
        user: action.user,
      };
    }

    case Types.ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        acceptAndSetup: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.CHECK_INVITE_STATUS_INPROGRESS:
      return {
        ...state,
        checkInvite: {
          ...state.checkInvite,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.CHECK_INVITE_STATUS_SUCCESS:
      return {
        ...state,
        checkInvite: {
          ...state.checkInvite,
          loading: false,
          isError: false,
          message: '',
        },
      };

    case Types.CHECK_INVITE_STATUS_FAILURE:
      return {
        ...state,
        checkInvite: {
          ...state.checkInvite,
          loading: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.RESEND_VERIFY_EMAIL_INPROGRESS:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.RESEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          loading: false,
          isError: false,
          message: '',
        },
      };

    case Types.RESEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        resendInvite: {
          ...state.resendInvite,
          loading: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.VERIFICATION_VALIDITY_INPROGRESS:
      return {
        ...state,
        inviteValidityCheck: {
          ...state.inviteValidityCheck,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.VERIFICATION_VALIDITY_SUCCESS:
      return {
        ...state,
        inviteValidityCheck: {
          ...state.inviteValidityCheck,
          loading: false,
          isError: false,
          message: '',
        },
      };

    case Types.VERIFICATION_VALIDITY_FAILURE:
      return {
        ...state,
        inviteValidityCheck: {
          ...state.inviteValidityCheck,
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.UPDATE_USER_COMPANY: {
      const company = action.payload || {};
      return {
        ...state,
        user: {
          ...(state.user || {}),
          company: {
            ...get(state, 'user.company', {}),
            ...company,
          },
        },
      };
    }
    case Types.SET_REGISTRATION_FLOW:
      return {
        ...state,
        isRegistrationFlow: get(action, 'data', false),
      };

    case Types.SOCIAL_LOGIN_INPROGESS:
      return {
        ...state,
        socialLoginLoading: true,
      };

    case Types.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        socialLoginLoading: false,
      };

    case Types.SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        socialLoginLoading: false,
      };

    case Types.TOGGLE_AUTH_ERROR:
      return {
        ...state,
        isError: false,
      };

    case Types.TOGGLE_RESET_PASSWORD_ERROR:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isError: false,
        },
      };

    case Types.TOGGLE_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: 0,
        },
      };

    case Types.TOGGLE_EMAIL_VERIFY_ERROR:
      return {
        ...state,
        emailVerify: {
          ...state.emailVerify,
          isError: false,
        },
      };

    case Types.TOGGLE_REGISTER_ERROR:
      return {
        ...state,
        register: {
          ...state.register,
          isError: false,
        },
      };

    default:
      return state;
  }
}
