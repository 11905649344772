import React, { useState } from 'react';
import FileUpload from 'components/FormFields/FileUpload';
import { downloadDocument } from 'store/actions/documents';
import { useDispatch, useSelector } from 'react-redux';
import FileViewer from 'components/FileViewer';
import get from 'lodash/get';

const DocumentDropzone = ({
  document,
  index,
  activeYear,
  documentModal,
  showIntegrate,
  isIntegrated,
  handleIntegration,
  setDisconnectInt,
}) => {
  const dispatch = useDispatch();
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleViewClick = e => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(downloadDocument(document.documents));
  };

  const onFileViewerOpen = id => {
    setFileData(document.documents[id]);
    setIsFileViewerOpen(true);
  };

  const onFileViewerClose = () => {
    setFileData(null);
    setIsFileViewerOpen(false);
  };

  const onTitleClick = async () => {
    if (quickStartTour && quickStartTour.activeTour) {
      const { activeTour } = quickStartTour;
      if (['tax_returns', 'general_ledger_payroll'].includes(activeTour))
        return false;
    }
    documentModal(document);
  };

  const getTaxType = () => {
    const { short_name, extra_info, status } = document;
    if (short_name !== 'Tax Returns' || !status) return null;
    if (!extra_info) return;
    const { tax_types = [] } = extra_info;
    if (tax_types.length === 1) {
      return `Includes ${tax_types[0]} only`;
    }
    return `Includes Federal & State`;
  };

  return (
    <>
      {isFileViewerOpen && (
        <FileViewer
          isOpen={isFileViewerOpen}
          toggle={onFileViewerClose}
          fileData={fileData}
          title={document.short_name}
        />
      )}
      <FileUpload
        index={index}
        folder={document}
        activeYear={activeYear}
        description={
          document.short_name.includes('Tax') ? (
            <div className="d-flex flex-column">
              <div className="font-weight-bold">
                Please upload any 2020 tax returns, including both federal and
                state, if applicable.{' '}
              </div>
              <br />
              <div>{document.description}</div>
            </div>
          ) : (
            document.description
          )
        }
        handleViewClick={handleViewClick}
        showIntegrate={showIntegrate}
        titleClick={onTitleClick}
        fileView={onFileViewerOpen}
        handleIntegration={handleIntegration}
        isQuickStartTour={
          quickStartTour &&
          quickStartTour.activeTour &&
          (quickStartTour.activeTour === 'general_ledger_payroll' ||
            quickStartTour.activeTour === 'tax_returns')
        }
        pendingReview={
          document.status && document.status.label === 'Pending Review'
        }
        verified={document.status && document.status.label === 'Verified'}
        taxType={getTaxType()}
        isIntegrated={isIntegrated}
        setDisconnectInt={setDisconnectInt}
      />
    </>
  );
};

export default DocumentDropzone;
