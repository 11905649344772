import React from 'react';
import classes from './OnboardingWalkThrough.module.scss';
import Modal from 'components/FormFields/Modal';
import Button from 'components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveTourStatus } from 'store/actions/profile';
import get from 'lodash/get';
import Tours from 'components/QuickStartTour/Tours';
import split from 'lodash/split';
import { useLocation, useHistory } from 'react-router-dom';
import useStartTour from 'components/QuickStartTour/useStartTour';

const OnboardingWalkThrough = ({ isOpen, toggle }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const startOnboardingTour = useStartTour();
  const history = useHistory();

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const userName = useSelector(({ auth }) => {
    return (
      get(auth, 'user.first_name', '') || split(get(auth, 'user.name'), ' ')[0]
    );
  });

  const documents = useSelector(({ documents }) =>
    quickStartTour.activeYear && documents
      ? get(
          documents,
          `documentTypes.data.${quickStartTour.activeYear}.data.data`,
          []
        )
      : []
  );
  const handleTourClick = async () => {
    toggle();
    const html = document.getElementsByTagName('html')[0];
    if (html && !html.classList.contains('hide-scrollbar')) {
      html.classList.add('hide-scrollbar');
    }
    if (pathname !== '/admin/my-documents') {
      history.push('/admin/my-documents');
      dispatch(
        updateActiveTourStatus({
          ...quickStartTour,
          isRedirected: true,
        })
      );
    } else {
      if (!documents.length) return;
      startOnboardingTour(documents);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={classes.modalWrapper}
        noHeader
      >
        <div className={classes.title}>Welcome, {userName}!</div>
        <div className={classes.description}>
          We understand uploading documents can sometimes be a lot of work. Let
          us show you how easy the process is!
        </div>
        <div className={classes.buttonGroup}>
          <Button color="primary" outline onClick={toggle}>
            No Thanks
          </Button>
          <Button color="primary" onClick={handleTourClick}>
            Yes, Let’s Go!
          </Button>
        </div>
      </Modal>
      <Tours />
    </>
  );
};

export default OnboardingWalkThrough;
