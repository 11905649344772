import React, { useState, useRef } from 'react';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';
import Input from 'components/FormFields/Input';
import { useClickAway } from 'react-use';

const TextField = props => {
  const {
    getFieldValue,
    isEditAllowed,
    defaultValue = 'R&D',
    onChange,
    fieldKey = 'study_name',
    name = 'study_name',
    title = 'STUDY NAME',
    placeholder = '',
    onCancel = () => {},
    analyticsSendEvent,
    analyticsConstants,
  } = props;
  const value = getFieldValue(fieldKey);
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const inputRef = useRef(null);

  const changeValue = () => {
    setShowInput(false);
    // analyticsSendEvent({
    //   action: analyticsConstants.action.edit_project_budget_$,
    // });

    onChange(name, newValue);
  };

  useClickAway(inputRef, () => {
    changeValue();
  });

  return (
    <>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        {title}
      </h6>
      {showInput ? (
        <Input
          value={newValue || ''}
          type="text"
          innerRef={inputRef}
          alternative={false}
          focused
          onChange={e => {
            setNewValue(e.target.value);
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              changeValue();
            }
          }}
          autoFocus
          placeholder={placeholder}
        />
      ) : (
        <p
          className={cx(
            isEditAllowed && classes.hoverHand,
            !value && 'text-muted',
            classes.valueItems
          )}
          onClick={() => {
            if (isEditAllowed) {
              setNewValue(value);
              setShowInput(true);
            }
          }}
        >
          {newValue}
        </p>
      )}
    </>
  );
};

export default TextField;
