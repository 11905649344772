import Types from '../types/documents';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

const initialState = {
  yearsState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  documentTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  documentUpload: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  downloadDoc: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  previewDoc: {
    isInProgress: false,
    isError: false,
    status: 0,
    url: '',
  },
  submitYear: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  verifyYear: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  undoVerifyYear: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  syncDocument: {
    isInProgress: false,
    syncInProgress: [],
    refetchYear: null,
    isError: false,
    status: 0,
    message: '',
  },
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  moreActivities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Documents(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_YEARS_INPROGRESS:
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          isInProgress: true,
        },
      };
    case Types.FETCH_YEARS_SUCCESS:
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_YEARS_FAILURE:
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_DOCUMENT_FOR_YEAR_INPROGRESS:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          data: {
            ...get(state, 'documentTypes.data', {}),
            [action.data.year]: {
              ...get(state, `documentTypes.data.${action.data.year}`, {}),
              isInProgress: true,
            },
          },
        },
      };
    case Types.FETCH_DOCUMENT_FOR_YEAR_SUCCESS:
      return {
        ...state,
        syncDocument: {
          ...state.syncDocument,
          refetchYear: action.data.clearRefetch
            ? null
            : state.syncDocument.refetchYear,
        },
        documentTypes: {
          ...state.documentTypes,
          status: 1,
          data: {
            ...get(state, 'documentTypes.data', {}),
            [action.data.year]: {
              ...get(state, `documentTypes.data.${action.data.year}`, {}),
              data: action.data,
              isInProgress: false,
            },
          },
        },
      };
    case Types.FETCH_DOCUMENT_FOR_YEAR_FAILURE:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          isError: true,
          message: action.message,
          data: {
            ...get(state, 'documentTypes.data', {}),
            [action.data.year]: {
              ...get(state, `documentTypes.data.${action.data.year}`, {}),
              isInProgress: false,
            },
          },
        },
      };
    case Types.DOCUMENT_UPLOAD_INPROGRESS:
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          isInProgress: true,
        },
      };
    case Types.DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          isInProgress: false,
        },
      };
    case Types.DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          isInProgress: false,
          isError: true,
        },
      };

    case Types.DOWNLOAD_DOCUMENT_INPROGRESS:
      return {
        ...state,
        downloadDoc: {
          ...state.downloadDoc,
          isInProgress: true,
        },
      };
    case Types.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadDoc: {
          ...state.downloadDoc,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadDoc: {
          ...state.downloadDoc,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.PREVIEW_DOCUMENT_INPROGRESS:
      return {
        ...state,
        previewDoc: {
          ...state.previewDoc,
          isInProgress: true,
        },
      };

    case Types.PREVIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        previewDoc: {
          ...state.previewDoc,
          isInProgress: false,
          url: action.url,
        },
      };

    case Types.PREVIEW_DOCUMENT_FAILURE:
      return {
        ...state,
        previewDoc: {
          ...state.previewDoc,
          isInProgress: false,
          isError: true,
        },
      };

    case Types.SUBMIT_YEAR_INPROGRESS:
      return {
        ...state,
        submitYear: {
          ...state.submitYear,
          isInProgress: true,
        },
      };

    case Types.SUBMIT_YEAR_SUCCESS: {
      const financialYears = [...state.yearsState.data];
      const updatedYearIndex = financialYears.findIndex(year => {
        return year.id === action.year;
      });
      if (updatedYearIndex !== -1) {
        financialYears[updatedYearIndex].status = 'Pending Review';
      }
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          data: financialYears,
        },
        submitYear: {
          ...state.submitYear,
          isInProgress: false,
        },
      };
    }

    case Types.SUBMIT_YEAR_FAILURE:
      return {
        ...state,
        submitYear: {
          ...state.submitYear,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.VERIFY_YEAR_INPROGRESS:
      return {
        ...state,
        verifyYear: {
          ...state.verifyYear,
          isInProgress: true,
        },
      };

    case Types.VERIFY_YEAR_SUCCESS: {
      const financialYears = [...state.yearsState.data];
      const updatedYearIndex = financialYears.findIndex(year => {
        return year.id === action.year;
      });
      if (updatedYearIndex !== -1) {
        financialYears[updatedYearIndex].status = 'Verified';
      }
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          data: financialYears,
        },
        verifyYear: {
          ...state.verifyYear,
          isInProgress: false,
        },
      };
    }

    case Types.VERIFY_YEAR_FAILURE:
      return {
        ...state,
        verifyYear: {
          ...state.submitYear,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.UNDO_VERIFY_YEAR_INPROGRESS:
      return {
        ...state,
        undoVerifyYear: {
          ...state.undoVerifyYear,
          isInProgress: true,
        },
      };

    case Types.UNDO_VERIFY_YEAR_SUCCESS: {
      const financialYears = [...state.yearsState.data];
      const updatedYearIndex = financialYears.findIndex(year => {
        return year.id === action.year;
      });
      if (updatedYearIndex !== -1) {
        financialYears[updatedYearIndex].status = 'Pending Review';
      }
      return {
        ...state,
        yearsState: {
          ...state.yearsState,
          data: financialYears,
        },
        undoVerifyYear: {
          ...state.undoVerifyYear,
          isInProgress: false,
        },
      };
    }

    case Types.UNDO_VERIFY_YEAR_FAILURE:
      return {
        ...state,
        undoVerifyYear: {
          ...state.submitYear,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.SYNC_DOCUMENT_INPROGRESS:
      return {
        ...state,
        syncDocument: {
          ...state.syncDocument,
          isInProgress: true,
        },
      };

    case Types.SYNC_DOCUMENT_SUCCESS:
      return {
        ...state,
        syncDocument: {
          ...state.syncDocument,
          isInProgress: false,
          syncInProgress: [
            ...state.syncDocument.syncInProgress,
            { id: action.id, type: action.integrationType },
          ],
        },
      };

    case Types.SYNC_DOCUMENT_FAILURE:
      return {
        ...state,
        syncDocument: {
          ...state.syncDocument,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.SYNC_DOCUMENT_COMPLETED: {
      const {
        sync_info: { id, year },
      } = action.data;
      const currentlySyncing = state.syncDocument.syncInProgress;
      const index = currentlySyncing.findIndex(sync => +sync.id === id);
      if (index !== -1) {
        currentlySyncing.splice(index, 1);
      }
      return {
        ...state,
        syncDocument: {
          ...state.syncDocument,
          syncInProgress: [...currentlySyncing],
          refetchYear: year,
        },
      };
    }

    case Types.CLEAR_PREVIEW_DOCUMENT:
      return {
        ...state,
        previewDoc: {
          ...state.previewDoc,
          isInProgress: false,
          url: null,
        },
      };

    case Types.FETCH_DOCUMENT_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };

    case Types.FETCH_DOCUMENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_DOCUMENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_MORE_DOCUMENT_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: true,
        },
      };

    case Types.FETCH_MORE_DOCUMENT_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          status: 1,
        },
        activities: {
          ...state.activities,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }

    case Types.FETCH_MORE_DOCUMENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
