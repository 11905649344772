import React, { useEffect, useState } from 'react';

import Modal from 'components/FormFields/Modal';
import UserImage from 'assets/img/theme/user_groups.svg';

import classes from './Sidebar.module.scss';
import * as moment from 'moment';
import navPrevIcon from '../../assets/img/icons/navPrev.svg';
import navNextIcon from '../../assets/img/icons/navNext.svg';
import { DayPickerRangeController } from 'react-dates';
import { useDispatch, useSelector } from 'react-redux';

import Dropdowns from 'components/Dropdowns';

import get from 'lodash/get';
import { fetchTimezones } from 'store/actions/metadata';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

const meetingData = {
  avatar: UserImage,
  name: 'Tyler Kem',
  meetingTitle: '30 Minute Partner Discussion',
  time: '30 min',
  about: 'Web conferencing details provided upon confirmation.',
  description:
    'Please join my meeting from your computer, tablet or smartphone.',
};
const dateFormat = 'MM-DD-YYYY';
const today = moment();

const ScheduleMeetingForm = props => {
  const { isOpen, closeModal } = props;
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [focusedInput, setFocusInput] = useState('startDate');
  const [dates, setDates] = useState({
    startDate: moment('11-20-2022', dateFormat),
    endDate: moment('11-27-2022', dateFormat),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);
  const timezones = useSelector(({ metadata }) =>
    get(metadata, 'timezones.data', {})
  );

  const timezonesData = Object.entries(timezones).map(([id, name]) => ({
    value: id,
    text: name,
    onClick: () => setSelectedTimeZone(name),
  }));

  const handleDateSelect = date => {
    if (date?.startDate?._i && date?.endDate?._i) {
      AlertPopupHandler.openCustom({
        type: 'success',
        showCancelButton: false,
        showCancel: false,
        title: 'Your meeting has been scheduled',
        confirmBtnText: 'Got it',
        confirmBtnBsStyle: 'success',
        text: 'You successfully booked a meeting',
        onConfirm: () => {},
      });
      closeModal();
    }
  };

  return (
    <>
      <Modal
        centered
        id="submitModal"
        size="lg"
        noHeader={true}
        isOpen={isOpen}
        toggle={closeModal}
        title=""
      >
        <div className={classes.meetingModalWrapper}>
          <div className={classes.meetingDescription}>
            <div className={classes.header}>
              <img
                alt="logo"
                src={require('assets/img/brand/strike-logo.png')}
              />
            </div>
            <div className={classes.content}>
              <div className="avatar avatar-lg rounded-circle">
                <img
                  alt="..."
                  src={require('assets/img/theme/user_groups.svg')}
                />
              </div>
              <h5>{meetingData.name}</h5>
              <h1>{meetingData.meetingTitle}</h1>
              <h5>
                <i className="fa fa-exclamation-circle" />
                {meetingData.time}
              </h5>
              <h5>
                <i className="fa fa-exclamation-circle" />
                {meetingData.about}
              </h5>
              <p>{meetingData.description}</p>
              <a href="#">Cookie Settings</a>
            </div>
          </div>
          <div className={classes.dateSelection}>
            <h1>Select a Date & Time</h1>

            <DayPickerRangeController
              firstDayOfWeek={1}
              numberOfMonths={1}
              minimumNights={0}
              isOutsideRange={day => {}}
              startDate={dates.startDate} // momentPropTypes.momentObj or null,
              endDate={dates.endDate} // momentPropTypes.momentObj or null,
              onDatesChange={handleDateSelect} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput =>
                setFocusInput(focusedInput || 'startDate')
              } // PropTypes.func.isRequired,
              navPrev={
                <div className="navPrev">
                  <img src={navPrevIcon} alt="previous" />
                </div>
              }
              navNext={
                <div className="navNext">
                  <img src={navNextIcon} alt="next" />
                </div>
              }
            />
            <div className={classes.timeZone}>
              <h3>Time Zone</h3>
              <p>
                <i className="fa fa-globe mr-1" aria-hidden="true"></i> Mountain
                Time - US & Canada(12:45pm){' '}
                <span className="d-flex align-items-center h-100">
                  <Dropdowns
                    text={<i className="fa fa-caret-down" />}
                    dropdownClasses={classes.dropdownCaret}
                    options={timezonesData}
                    caret={false}
                    size="sm"
                    color=""
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScheduleMeetingForm;
