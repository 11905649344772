import Types from 'store/types/userDashboard';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

const initialState = {
  viewedProjects: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  viewedStories: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  activeStudy: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  completedStudies: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  deliverables: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  clientTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  myOpenTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  estimatedDeliveries: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  timeLoggedThisWeek: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  openAssignments: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  timeLogGraph: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_RECENTLY_VIEWED_PROJECTS_INPROGRESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: true,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_PROJECTS_SUCCESS:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_PROJECTS_FAILURE:
      return {
        ...state,
        viewedProjects: {
          ...state.viewedProjects,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STORIES_INPROGRESS:
      return {
        ...state,
        viewedStories: {
          ...state.viewedStories,
          isInProgress: true,
        },
      };
    case Types.UPDATE_STORY:
      return {
        ...state,
        viewedStories: {
          ...state.viewedStories,
          isInProgress: false,
          data: {
            ...state.viewedStories.data,
            data: action.data,
          },
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STORIES_SUCCESS:
      return {
        ...state,
        viewedStories: {
          ...initialState.viewedStories,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'viewedStories.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_RECENTLY_VIEWED_STORIES_FAILURE:
      return {
        ...state,
        viewedStories: {
          ...state.viewedStories,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_WEEKLY_USER_TIME_LOGGED_INPROGRESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: true,
        },
      };
    case Types.FETCH_WEEKLY_USER_TIME_LOGGED_SUCCESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_WEEKLY_USER_TIME_LOGGED_FAILURE:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_OPEN_ASSIGNMENTS_INPROGRESS:
      return {
        ...state,
        openAssignments: {
          ...state.openAssignments,
          isInProgress: true,
        },
      };
    case Types.FETCH_OPEN_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        openAssignments: {
          ...state.openAssignments,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_OPEN_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        openAssignments: {
          ...state.openAssignments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TIME_SPENT_GRAPH_INPROGRESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_SPENT_GRAPH_SUCCESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_SPENT_GRAPH_FAILURE:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_DASHBOARD_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_DASHBOARD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DASHBOARD_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_DASHBOARD_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_DASHBOARD_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          status: 1,
        },
        activities: {
          ...state.activities,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }
    case Types.FETCH_MORE_DASHBOARD_ACTIVITIES_FAILURE:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_TASKS_INPROGRESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_TASKS_SUCCESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'clientTasks.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_CLIENT_TASKS_FAILURE:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MY_OPEN_TASKS_INPROGRESS:
      return {
        ...state,
        myOpenTasks: {
          ...state.myOpenTasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_MY_OPEN_TASKS_SUCCESS:
      return {
        ...state,
        myOpenTasks: {
          ...state.myOpenTasks,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'myOpenTasks.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_MY_OPEN_TASKS_FAILURE:
      return {
        ...state,
        myOpenTasks: {
          ...state.myOpenTasks,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ESTIMATED_DELIVERIES_INPROGRESS:
      return {
        ...state,
        estimatedDeliveries: {
          ...state.estimatedDeliveries,
          isInProgress: true,
        },
      };
    case Types.FETCH_ESTIMATED_DELIVERIES_SUCCESS:
      return {
        ...state,
        estimatedDeliveries: {
          ...initialState.estimatedDeliveries,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'estimatedDeliveries.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_ESTIMATED_DELIVERIES_FAILURE:
      return {
        ...state,
        estimatedDeliveries: {
          ...state.estimatedDeliveries,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ACTIVE_STUDY_INPROGRESS:
      return {
        ...state,
        activeStudy: {
          ...state.activeStudy,
          isInProgress: true,
        },
      };
    case Types.FETCH_ACTIVE_STUDY_SUCCESS:
      return {
        ...state,
        activeStudy: {
          ...state.activeStudy,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ACTIVE_STUDY_FAILURE:
      return {
        ...state,
        activeStudy: {
          ...state.activeStudy,
          data: {},
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_COMPLETED_STUDIES_INPROGRESS:
      return {
        ...state,
        completedStudies: {
          ...state.completedStudies,
          isInProgress: true,
        },
      };
    case Types.FETCH_COMPLETED_STUDIES_SUCCESS:
      return {
        ...state,
        completedStudies: {
          ...state.completedStudies,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_COMPLETED_STUDIES_FAILURE:
      return {
        ...state,
        completedStudies: {
          ...state.completedStudies,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_DASHBOARD_DELIVERABLES_INPROGRESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: true,
        },
      };
    case Types.FETCH_DASHBOARD_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DASHBOARD_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
