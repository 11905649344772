import Request from './request';

export const postAddAssignment = async storyId => {
  return Request.call({
    url: `/stories/${storyId}/tasks`,
    method: 'POST',
  });
};

export const patchUpdateAssignment = async (storyId, taskId, data) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/${taskId}`,
    method: 'PATCH',
    data,
  });
};

export const getStory = async id => {
  return Request.call({
    url: `/stories/${id}`,
    method: 'GET',
  });
};

export const updateStory = async (id, data) => {
  return Request.call({
    url: `/stories/${id}`,
    method: 'PUT',
    data,
  });
};

export const uploadStoryAttachment = async (id, data) => {
  return Request.call({
    url: `/stories/${id}/attachments`,
    method: 'POST',
    data,
  });
};

export const deleteStoryAttachment = async mediaId => {
  return Request.call({
    url: `/media/${mediaId}`,
    method: 'DELETE',
  });
};

export const fetchComments = async (
  storyId,
  messageId,
  type = 'DocumentType'
) => {
  return Request.call({
    url: '/comments',
    method: 'GET',
    params: {
      parent_type: type,
      parent_id: storyId,
      ...(messageId ? { message_id: messageId } : {}),
    },
  });
};

export const postComment = async (storyId, comment, type = 'DocumentType') => {
  return Request.call({
    url: `/comments`,
    method: 'POST',
    data: {
      parent_type: type,
      parent_id: storyId,
      message: comment,
    },
  });
};

export const updateComment = async (commentId, comment) => {
  return Request.call({
    url: `/comments/${commentId}`,
    method: 'PUT',
    data: {
      message: comment,
    },
  });
};

export const deleteComment = async commentId => {
  return Request.call({
    url: `/comments/${commentId}`,
    method: 'DELETE',
  });
};

export const readComments = async data => {
  return Request.call({
    url: `/comments/update/read`,
    method: 'POST',
    data: data,
  });
};

export const postAddChecklist = async (taskId, data) => {
  return Request.call({
    url: `/tasks/${taskId}/checklists`,
    method: 'POST',
    data,
  });
};

export const putUpdateChecklist = async (taskId, checkListId, data) => {
  return Request.call({
    url: `/tasks/${taskId}/checklists/${checkListId}`,
    method: 'PUT',
    data,
  });
};

export const deleteChecklist = async (taskId, checkListId) => {
  return Request.call({
    url: `/tasks/${taskId}/checklists/${checkListId}`,
    method: 'DELETE',
  });
};

export const postReOrderChecklist = async (taskId, data) => {
  return Request.call({
    url: `/tasks/${taskId}/checklists/prioritize-checklists`,
    method: 'POST',
    data,
  });
};

export const postReOrderAssignments = async (storyId, data) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/prioritize-tasks`,
    method: 'POST',
    data,
  });
};

export const deleteAssigment = async (storyId, taskId) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/${taskId}`,
    method: 'DELETE',
  });
};

export const postDuplicateAssigment = async (storyId, taskId) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/${taskId}/duplicate`,
    method: 'POST',
  });
};

export const postAddDependency = async (taskId, dependencyId) => {
  return Request.call({
    url: `/tasks/${taskId}/dependency`,
    method: 'POST',
    data: {
      dependency_id: dependencyId,
    },
  });
};

export const deleteDependency = async taskId => {
  return Request.call({
    url: `/tasks/${taskId}/dependency`,
    method: 'DELETE',
  });
};

export const postUpdateChecklistStatus = async (
  taskId,
  checkListId,
  status
) => {
  return Request.call({
    url: `/tasks/${taskId}/checklists/${checkListId}/status`,
    method: 'POST',
    data: {
      status,
    },
  });
};

export const postCompleteAssignment = async (storyId, taskId) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/${taskId}/complete`,
    method: 'POST',
  });
};

export const postRestoreAssignment = async (storyId, taskId) => {
  return Request.call({
    url: `/stories/${storyId}/tasks/${taskId}/restore`,
    method: 'POST',
  });
};

export const getStoryActivity = async (id, page) => {
  return Request.call({
    url: `/stories/${id}/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};
