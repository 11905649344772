import React from 'react';
import PropTypes from 'prop-types';
import RecentlyViewedProjectsWidget from 'views/widgets/RecentlyViewedProjects';

const RecentlyViewedProjects = ({ getDetails, gaCategory }) => {
  const getViewedProjects = (field, defaultValue) =>
    getDetails(`viewedProjects.${field}`, defaultValue);

  const recentlyViewedProject = getViewedProjects('data', []);
  const loading = getViewedProjects('isInProgress', false);

  return (
    <RecentlyViewedProjectsWidget
      data={recentlyViewedProject}
      loading={loading}
      analyticsObj={{
        category: gaCategory,
        action: 'View Study on Recently Viewed Studies',
      }}
    />
  );
};

RecentlyViewedProjects.propTypes = {
  getDetails: PropTypes.func,
};

export default RecentlyViewedProjects;
