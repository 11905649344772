import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import Editor from 'components/FormFields/Editor';
import get from 'lodash/get';
import FileDropZone from 'components/FormFields/FileDropZone';
import { isEmpty } from 'lodash';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Loading from 'components/Loading';

const dropDownStyle = {
  singleValue: base => ({
    ...base,
    color: '#333333 !important',
  }),
  multiValue: base => ({
    ...base,
    background: '#F4F5F7',
    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
    borderRadius: '4px',
    padding: '4px 5px',
  }),
  multiValueLabel: base => ({
    ...base,
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#32325d',
  }),
};

const DeliverablesModal = ({
  clientId,
  handleSubmit,
  handleClose,
  isOpen = true,
  viewMode,
  reducerKey = 'clientProfile',
}) => {
  const deliverable = useGetFieldFromObjects(
    `${reducerKey}`,
    'activeDeliverable.data',
    {}
  );

  let initialValues = {
    id: deliverable.id,
    name: '',
    description: '',
    study: '',
    attachment: [],
    // deleted_attachments: deliverable.deleted_attachments || [],
  };

  if (viewMode === 'edit') {
    initialValues = {
      ...initialValues,
      name: deliverable.name,
      attachment: deliverable.attachment,
      study: deliverable.study,
    };
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    descriptions: Yup.string(),
    attachment: Yup.string().required('File is required'),
    study: Yup.object().when('clientId', {
      is: () => !!clientId,
      then: Yup.object().required('Please select Study'),
      otherwise: Yup.object().nullable(),
    }),
  });

  const editSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    attachment: Yup.string().required('File is required'),
  });

  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsProcessing(false);
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      size="lg"
      title={viewMode === 'edit' ? 'Edit File' : 'Upload File'}
    >
      <ModalBody>
        <Formik
          enableReinitialize
          validateOnMount
          validationSchema={viewMode === 'edit' ? editSchema : schema}
          initialValues={initialValues}
          onSubmit={async (...rest) => {
            setIsProcessing(true);
            await handleSubmit(...rest);
            setIsProcessing(false);
          }}
        >
          {({ values, setFieldValue, resetForm, touched, errors }) => {
            const handleDeleteFile = files => {
              AlertPopupHandler.open({
                onConfirm: async () => {
                  setFieldValue('attachment', files);
                },
                confirmBtnText: `Delete`,
                text: (
                  <div className="d-flex flex-column">
                    <div>
                      You are about to delete a file. Do you want to continue?
                    </div>
                  </div>
                ),
              });
            };
            return (
              <Form>
                <FormikInput name="name" placeholder="File Name" />
                {clientId && viewMode !== 'edit' && (
                  <FormGroup className="mb-3">
                    <RSelectAsync
                      url={`/initiatives/list/dropdown?client=${clientId}&status=active`}
                      getOptionLabel={option => option.name}
                      placeholder="Select a Study"
                      onChange={value => {
                        setFieldValue('study', value);
                      }}
                      isClearable={true}
                      value={values.client}
                      touched={touched.client}
                      error={errors.client}
                      styles={dropDownStyle}
                      disabled={viewMode === 'edit'}
                    />
                  </FormGroup>
                )}
                {viewMode !== 'edit' && (
                  <FormGroup className="mb-3">
                    <Editor
                      placeholder="Add a description"
                      value={values.description}
                      onChange={content => {
                        setFieldValue('description', content);
                      }}
                      onFocus={() => setIsDescriptionFocused(true)}
                      shouldAllowMention={false}
                      onBlur={() => setIsDescriptionFocused(false)}
                      assignRef={ref => {
                        if (
                          ref &&
                          !get(ref, 'editor').getSelection() &&
                          isDescriptionFocused
                        ) {
                          const editor = ref.getEditor();
                          editor.focus();
                          editor.setSelection(editor.getLength());
                        }
                      }}
                    />
                  </FormGroup>
                )}
                <FormGroup className="mb-3">
                  {viewMode === 'edit' && !deliverable.attachment ? (
                    <Loading key="loader" size={100} />
                  ) : (
                    <FileDropZone
                      shouldAllowDownload={true}
                      handleFileChange={files => {
                        setFieldValue('attachment', files[0]);
                      }}
                      onRemove={files => {
                        if (viewMode === 'edit') {
                          handleDeleteFile(files);
                        } else {
                          setFieldValue('attachment', files);
                        }
                      }}
                      inputProps={{
                        multiple: false,
                      }}
                      shouldAppendFiles={false}
                      attachment={values.attachment}
                      placeholder="Drop the file here to upload"
                    />
                  )}
                </FormGroup>
                <div className={'d-flex justify-content-between'}>
                  <Button
                    color="link"
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!isEmpty(errors) || isProcessing}
                    loading={isProcessing}
                  >
                    {viewMode === 'edit' ? 'Save Changes' : 'Upload File'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default DeliverablesModal;
