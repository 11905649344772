import {
  getBudgetSpent as getBudgetSpentApi,
  getTimeLoggedThisWeek as getTimeLoggedThisWeekApi,
  getSevenDaysHours as getSevenDaysHoursApi,
  getStoriesCompleted as getStoriesCompletedApi,
  getTimeSpentOnProject,
  getStudyTasks as getStudyTasksApi,
} from 'api/projects';
import { getStudyDeliverables, addStudyDeliverable } from 'api/deliverables';
import Types from 'store/types/projectDashboard';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import { NotificationHandler } from 'components/Notifications';

export const addDeliverableToStudy = obj => {
  return async dispatch => {
    dispatch({ type: Types.ADD_STUDY_DELIVERABLES_INPROGRESS });
    try {
      const resp = await addStudyDeliverable(obj);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.ADD_STUDY_DELIVERABLES_SUCCESS,
          data,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'success',
          icon: ' ',
          title: ' ',
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.ADD_STUDY_DELIVERABLES_FAILURE,
          message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
          icon: ' ',
          title: ' ',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ADD_STUDY_DELIVERABLES_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
        icon: ' ',
        title: ' ',
      });
    }
  };
};

export const getBudgetSpent = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_PROJECT_SPENT_INPROGRESS });
    try {
      const resp = await getBudgetSpentApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PROJECT_SPENT_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PROJECT_SPENT_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROJECT_SPENT_FAILURE,
        message: error,
      });
    }
  };
};

export const getTimeLoggedThisWeek = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TIME_LOG_CURRENT_WEEK_INPROGRESS });
    try {
      const resp = await getTimeLoggedThisWeekApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_TIME_LOG_CURRENT_WEEK_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE,
        message: error,
      });
    }
  };
};

export const getDeliverables = (projectId, params, showAllData = false) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_DELIVERABLES_INPROGRESS });
    try {
      const resp = await getStudyDeliverables(projectId, params);
      const status = get(resp, 'status');
      if (status && showAllData) {
        dispatch({
          type: Types.FETCH_ALL_STUDY_DELIVERABLES_SUCCESS,
          data: resp,
        });
      } else if (status) {
        dispatch({
          type: Types.FETCH_STUDY_DELIVERABLES_SUCCESS,
          data: resp,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_DELIVERABLES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDY_DELIVERABLES_FAILURE,
        message: error,
      });
    }
  };
};

export const getSevenDaysHours = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_LAST_WEEK_HOURS_INPROGRESS });
    try {
      const resp = await getSevenDaysHoursApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_LAST_WEEK_HOURS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_LAST_WEEK_HOURS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_LAST_WEEK_HOURS_FAILURE,
        message: error,
      });
    }
  };
};

export const getStoriesCompleted = projectId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_COMPLETED_STORIES_INPROGRESS });
    try {
      const resp = await getStoriesCompletedApi(projectId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_COMPLETED_STORIES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_COMPLETED_STORIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_COMPLETED_STORIES_FAILURE,
        message: error,
      });
    }
  };
};

export const getTimeSpent = (
  projectId,
  group = 'week',
  startDate = '',
  endDate = ''
) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TIME_LOG_GRAPH_INPROGRESS });
    try {
      const resp = await getTimeSpentOnProject(projectId, {
        group,
        startDate,
        endDate,
      });
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_TIME_LOG_GRAPH_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TIME_LOG_GRAPH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIME_LOG_GRAPH_FAILURE,
        message: error,
      });
    }
  };
};

export const getStudyTasks = (id, queryParams) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_TASKS_INPROGRESS });
    try {
      const resp = await getStudyTasksApi(id, queryParams);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_TASKS_SUCCESS,
          data: pickBy(resp, (value, key) => key !== 'status'),
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_TASKS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDY_TASKS_FAILURE,
        message: error,
      });
    }
  };
};

export const getStudyTasksForTasksScreen = (id, queryParams) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_TASKS_SCREEN_STUDY_TASKS_INPROGRESS });
    try {
      const resp = await getStudyTasksApi(id, queryParams);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_TASKS_SCREEN_STUDY_TASKS_SUCCESS,
          data: pickBy(resp, (value, key) => key !== 'status'),
          key: get(queryParams, 'status'),
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_TASKS_SCREEN_STUDY_TASKS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TASKS_SCREEN_STUDY_TASKS_FAILURE,
        message: error,
      });
    }
  };
};
