import React, { useState, useRef, useEffect } from 'react';
import numeral from 'numeral';
import Input from 'components/FormFields/Input';
import { useClickAway } from 'react-use';
import cx from 'classnames';
import classes from 'views/pages/Project/Dashboard/Dashboard.module.scss';

const Budget = ({
  getFieldValue,
  onChange,
  isEditAllowed,
  defaultValue,
  analyticsSendEvent,
  analyticsConstants,
  type = 'number',
  placeholder = 'Budget Amount',
  name = 'monthlyBudget',
  title = 'Budget($)',
  leftIcon = '',
  fieldKey,
  viewModeIcon,
  noDataValue = 0,
}) => {
  const budget = getFieldValue(fieldKey);
  const [showInput, setShowInput] = useState(false);
  const [newBudget, setNewBudget] = useState(budget);
  useEffect(() => {
    setNewBudget(budget);
  }, [budget]);
  const inputRef = useRef(null);
  const changeBudget = () => {
    setShowInput(false);
    analyticsSendEvent({
      action: analyticsConstants.action.edit_project_budget_$,
    });

    onChange(name, newBudget);
  };
  useClickAway(inputRef, () => {
    changeBudget();
  });

  const getFormattedValue = () => {
    switch (type) {
      case 'number':
        return budget && budget != 0
          ? numeral(budget).format('0,0[.].00')
          : isEditAllowed
          ? noDataValue
          : noDataValue !== '-'
          ? defaultValue
          : noDataValue;
      case 'text':
        return budget ? budget : isEditAllowed ? '-' : defaultValue;
    }
  };

  return (
    <>
      <h6 className={cx('text-uppercase', 'text-muted', classes.title)}>
        {title}
      </h6>
      {showInput ? (
        <Input
          value={newBudget || ''}
          leftIcon={leftIcon}
          type={type}
          innerRef={inputRef}
          alternative={false}
          focused
          onChange={e => {
            setNewBudget(e.target.value);
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              changeBudget();
            }
          }}
          autoFocus
          placeholder={placeholder}
        />
      ) : (
        <p
          className={cx(
            isEditAllowed && classes.hoverHand,
            classes.dollarIcon,
            !budget && 'text-muted',
            classes.valueItems
          )}
          onClick={() => {
            if (isEditAllowed) {
              setNewBudget(budget);
              setShowInput(true);
            }
          }}
        >
          {viewModeIcon && Boolean(budget && budget != 0) && (
            <i className={cx('fa', viewModeIcon, classes.viewModeIcon)} />
          )}
          {getFormattedValue()}
        </p>
      )}
    </>
  );
};

export default Budget;
