import React from 'react';
import classes from './CustomAccordion.module.scss';
import cx from 'classnames';

function CustomAccordion({
  children,
  header = '',
  dropIconPosition = 'left',
  index,
  activeIndex,
  onSelect,
}) {
  function toggleAccordion() {
    onSelect(index);
  }

  const getDropdownIcon = () => {
    return (
      <div className={classes.iconWrapper}>
        <i
          className={cx('fa fa-chevron-down', {
            [classes.activeIcon]: activeIndex === index,
          })}
          aria-hidden="true"
        ></i>
      </div>
    );
  };

  return (
    <div className={classes.accordionWrapper}>
      <div className={cx(classes.accordionHeader)} onClick={toggleAccordion}>
        {dropIconPosition === 'left' && getDropdownIcon()}
        <div style={{ width: '100%' }}>{header}</div>
        {dropIconPosition === 'right' && getDropdownIcon()}
      </div>
      <div
        className={cx(classes.accordionContent, {
          [classes.activeContent]: activeIndex === index,
        })}
      >
        <div className={classes.childrenWrapper}> {children}</div>
      </div>
    </div>
  );
}

export default CustomAccordion;
