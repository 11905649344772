import Can from 'components/Can';
import classNames from 'classnames';
import Dropdowns from 'components/Dropdowns';
import { permissions, useAccess } from 'helpers/permission';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { doDeleteClientDeliverable } from 'store/actions/clientProfile';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import noDataIcon from 'assets/img/icons/no-deliverables.svg';
import classes from './Deliverables.module.scss';
import { getDeliverables } from 'store/actions/projectDashboard';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/Loading';

const Deliverables = ({
  reducerKey = 'projectDashboard',
  setShowDeliverablesModal,
  setEditDeliverables,
  setViewDeliverable,
  setDownloadDeliverable,
  onDeleted = () => {},
  projectId,
}) => {
  const [isPagination, setIsPagination] = useState(false);

  const deliverables = useGetFieldFromObjects(
    `${reducerKey}`,
    'deliverables.data.data',
    []
  );

  const deliverablesMeta = useGetFieldFromObjects(
    `${reducerKey}`,
    'deliverables.data.meta',
    {}
  );

  const clientDeliverables = useGetFieldFromObjects(
    `${reducerKey}`,
    'deliverables.data',
    []
  );

  const downloadLoading = useGetFieldFromObjects(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  useEffect(() => {
    if (deliverables.length > 0) setIsPagination(true);
    else setIsPagination(false);
  }, [deliverables, clientDeliverables]);

  const dispatch = useDispatch();
  const isViewDeliverableAllowed = useAccess([permissions.VIEW_DELIVERABLES]);
  const isDeleteDeliverableAllowed = useAccess([
    permissions.DELETE_DELIVERABLES,
  ]);
  const isEditDeliverableAllowed = useAccess([permissions.EDIT_DELIVERABLES]);
  const isDownloadDeliverableAllowed = useGetFieldFromObjects(
    'auth',
    'user.is_client'
  );

  const handleDeleteDeliverables = deliverable => {
    AlertPopupHandler.open({
      onConfirm: async () => {
        await dispatch(doDeleteClientDeliverable(deliverable.id));
        onDeleted();
      },
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>
            You are about to delete "{deliverable.name}". Do you want to
            continue?
          </div>
        </div>
      ),
    });
  };

  const hasMore =
    deliverablesMeta.current_page &&
    deliverablesMeta.last_page &&
    isPagination &&
    deliverablesMeta.current_page < deliverablesMeta.last_page;

  const loadMoreActivities = async () => {
    await dispatch(
      getDeliverables(
        projectId,
        { page: deliverablesMeta.current_page + 1 },
        true
      )
    );
  };

  const deliverablesArray = isPagination ? deliverables : clientDeliverables;

  return (
    <Card
      className={reducerKey === 'userDashboard' ? classes.cardfixedheight : ''}
    >
      <CardHeader className="d-flex justify-content-between align-items-center">
        <div className="font-weight-bolder">
          Deliverables
          <Badge color="info" className="ml-2 rounded-circle">
            {isPagination ? deliverablesMeta.total : deliverablesArray.length}
          </Badge>
        </div>
        <Can permissions={permissions.CREATE_DELIVERABLES}>
          <Button
            size="sm"
            color="primary"
            onClick={() => setShowDeliverablesModal(true)}
          >
            Upload
          </Button>
        </Can>
      </CardHeader>
      {deliverablesArray.length > 0 ? (
        <CardBody
          className={classNames('p-0', classes.deliverableCard, {
            [classes.hideScroll]: deliverablesArray.length <= 2,
          })}
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMoreActivities}
            hasMore={hasMore}
            loader={<Loading key="loader" size={50} />}
            useWindow={false}
          >
            {deliverablesArray.map((deliverable, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between p-3 font-weight-bolder text-sm m-0 align-items-center">
                    <span
                      className={classes.deliverableTitle}
                      onClick={() => setViewDeliverable(deliverable.id)}
                    >
                      {deliverable.name}
                    </span>
                    <Can
                      permissions={[
                        permissions.EDIT_DELIVERABLES,
                        permissions.VIEW_DELIVERABLES,
                        permissions.DELETE_DELIVERABLES,
                      ]}
                    >
                      <Dropdowns
                        onClick={event => event.stopPropagation()}
                        text={<i className="fas fa-ellipsis-v" />}
                        className="btn-icon-only m-0 text-light float-right"
                        options={[
                          ...(isViewDeliverableAllowed
                            ? [
                                {
                                  text: 'View File',
                                  onClick: event => {
                                    event.stopPropagation();
                                    setViewDeliverable(deliverable.id);
                                  },
                                },
                              ]
                            : []),
                          ...(isEditDeliverableAllowed
                            ? [
                                {
                                  text: 'Edit File',
                                  onClick: event => {
                                    event.stopPropagation();
                                    setEditDeliverables(deliverable);
                                  },
                                },
                              ]
                            : []),
                          ...(isDeleteDeliverableAllowed
                            ? [
                                {
                                  text: 'Delete File',
                                  onClick: event => {
                                    event.stopPropagation();
                                    handleDeleteDeliverables(deliverable);
                                  },
                                },
                              ]
                            : []),
                          ...(isDownloadDeliverableAllowed
                            ? [
                                {
                                  text: 'Download File',
                                  onClick: event => {
                                    event.stopPropagation();
                                    setDownloadDeliverable(deliverable.id);
                                  },
                                  disabled: downloadLoading,
                                },
                              ]
                            : []),
                        ]}
                        caret={false}
                        size="sm"
                        color=""
                      />
                    </Can>
                  </div>
                  <hr className="m-0" />
                </div>
              );
            })}
          </InfiniteScroll>
        </CardBody>
      ) : (
        <CardBody
          className={classNames(
            'p-0',
            classes.deliverableCard,
            classes.defaultHeight
          )}
        >
          <img src={noDataIcon} alt="No Deliverables Added" />
        </CardBody>
      )}
    </Card>
  );
};

export default Deliverables;
