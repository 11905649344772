import React, { useState } from 'react';
import moment from 'moment';
import classes from './Kanban.module.scss';

import { Container } from 'reactstrap';
import ToggleCheckBox from 'components/FormFields/ToggleCheckBox';
import Input from 'components/FormFields/Input';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useDebounce, useEffectOnce } from 'react-use';
import DragContainer from './DragContainer';

import analytics, { analyticsConstants } from 'helpers/analytics';
import isEmpty from 'lodash/isEmpty';
import Loading from 'components/Loading';
import ExpandIcon from 'assets/img/icons/expand-icon.svg';
import CollapseIcon from 'assets/img/icons/collaps-icon.svg';
import DateRangePicker from 'components/FormFields/DateRangePicker';

const Kanban = props => {
  const [keyword, setKeyword] = useState('');
  const [count, setCount] = useState(0);
  const [period, setPeriod] = useState('last_month');
  const [expand, setExpand] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();

  const [dataRetrievalCount, setDataRetrievalCount] = useState(0);

  useEffectOnce(() => {
    analyticsSendEvent({
      action: props.isGlobal
        ? analyticsConstants.action.view_global_kanban
        : analyticsConstants.action.view_personal_kanban,
    });
    const footerElement = document.getElementById('admin-footer');
    const parent = document.getElementById('admin-footer').parentElement;
    parent.removeChild(footerElement);
    return () => {
      parent.appendChild(footerElement);
    };
  });

  const loggedInUserId = useSelector(({ auth }) => get(auth, 'user.id'));

  const handleExpandCollapse = () => {
    setExpand(!expand);
  };
  const analyticsSendEvent = ({ action, label, value, ...rest }) => {
    analytics.sendEvent({
      category: props.isGlobal
        ? analyticsConstants.category.global_kanban
        : analyticsConstants.category.personal_kanban,
      // is_logged_in_user: isLoggedInUser,
      action,
      label,
      value,
      ...rest,
    });
  };

  useDebounce(
    () => {
      if (!isEmpty(keyword))
        analyticsSendEvent({
          action: props.isGlobal
            ? analyticsConstants.action.search_global_kanban
            : analyticsConstants.action.search_personal_kanban,
          label: analyticsConstants.label.kanban_search,
        });
    },
    1000,
    [keyword]
  );

  const getData = async (period, keyword) => {
    let params = {
      q: keyword,
    };
    if (startDate && endDate) {
      params = {
        ...params,
        from_date: moment(startDate).format('YYYY/MM/DD'),
        to_date: moment(endDate).format('YYYY/MM/DD'),
      };
    }
    await props.fetchKanbanData(params);
    setDataRetrievalCount(currentCount => currentCount + 1);
  };

  useDebounce(
    () => {
      getData(period, keyword);
    },
    500,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, keyword, count]
  );

  const reload = () => setCount(count + 1);

  const assignmentSummary = get(props.data, 'studies_summary', 0);

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <div className="d-flex flex-column">
          <h2>{props.title}</h2>
          <span className={classes.desc}>
            {assignmentSummary.open} Open Studies
          </span>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <div className="input-group-prepend align-items-center">
              <span id="basic-addon1">Search: </span>
            </div>
            <Input
              className={classes.searchBox}
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
              }}
            />
            <DateRangePicker
              showIcon
              startDate={startDate}
              endDate={endDate}
              value={dateRange}
              onChange={dates => {
                setStartDate(dates.startDate);
                setEndDate(dates.endDate);
                if (dates.startDate && dates.endDate) {
                  const rangeDate =
                    dates.startDate.format('MMM DD, YYYY') +
                    ' - ' +
                    dates.endDate.format('MMM DD, YYYY');
                  setDateRange(rangeDate);
                } else {
                  setDateRange(dateRange);
                }
              }}
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            {/*<div className={classes.switchIcon}>*/}
            {/*  <ToggleCheckBox />*/}
            {/*  <span className={classes.switchText}>Switch to List View</span>*/}
            {/*</div>*/}
            <div className={classes.expandCollapse}>
              <img
                src={`${expand ? ExpandIcon : CollapseIcon}`}
                className="w-20"
                onClick={() => handleExpandCollapse()}
              />
            </div>
          </div>
        </div>
      </div>
      {dateRange && (
        <div className={classes.filters}>
          <span>
            {dateRange}
            <a
              onClick={e => {
                e.preventDefault();
                setStartDate(null);
                setEndDate(null);
                setDateRange(null);
              }}
              href="#"
            >
              X
            </a>
          </span>
        </div>
      )}
      <div id="kanban-page">
        {dataRetrievalCount === 0 ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <Container fluid>
            <div className={classes.content}>
              <DragContainer
                isGlobal={props.isGlobal}
                userId={loggedInUserId}
                setPeriod={setPeriod}
                refresh={reload}
                data={props.data}
                analyticsSendEvent={analyticsSendEvent}
                expand={!expand}
              />
            </div>
          </Container>
        )}
      </div>
    </div>
  );
};

export default Kanban;
