import React, { useState } from 'react';
import classes from './PartnerClientStudies.module.scss';
import CustomAccordion from 'components/CustomAccordion';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { patchApproveById, patchBillableById } from 'api/timelogReportApi';

const clientStudyStatus = [
  {
    label: 'Document Gathering',
    stage: 1,
  },
  {
    label: 'Calculations',
    stage: 2,
  },
  {
    label: 'Credits Delivered',
    stage: 3,
  },
  {
    label: 'Final Reports',
    stage: 4,
  },
];

const PartnerClientStudies = ({ openModal, handleSetLeadId }) => {
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const [activeIndex, setActiveIndex] = useState(0);

  const clientStudies = useSelector(({ partner }) =>
    get(partner, 'clientStudies.data', [])
  );

  const handleClientStudySelect = data => e => {
    e.stopPropagation();
    handleSetLeadId(data?.lead_id);
    openModal();
  };

  const getStudyStage = status => {
    switch (status) {
      case 'Document Gathering':
        return 1;
      case 'Calculation':
        return 2;
      case 'Credits Delivered':
        return 3;
      case 'Final Reports (Half)':
        return 3.5;
      case 'Final Reports':
        return 4;
      case 'Paid':
        return 5;
      default:
        return 0;
    }
  };

  return (
    <div className={classes.clientStudies}>
      {clientStudies.map((data, index) => {
        return (
          <CustomAccordion
            key={index}
            index={index}
            activeIndex={activeIndex}
            onSelect={selectedIndex => {
              if (activeIndex === index) {
                setActiveIndex(null);
              } else {
                setActiveIndex(selectedIndex);
              }
            }}
            header={
              <div className={classes.headerWrapper}>
                <div className={classes.headerContent}>
                  <div className={classes.clientName}>
                    <h3>{data.lead}</h3>
                    <p className={classes.active_study}>
                      {
                        data.studies.filter(
                          ({ study_status }) => study_status !== 'on_hold'
                        ).length
                      }{' '}
                      active studies{' '}
                    </p>
                  </div>
                  {data.show_earning && !isProduction && (
                    <div>
                      <p>Estimated Earnings</p>
                      <p>{data.estimated_earnings}</p>
                    </div>
                  )}
                  {data.show_earning && !isProduction && (
                    <div>
                      <p>Payouts to date</p>
                      <p>{data?.payouts_to_date}</p>
                    </div>
                  )}
                </div>
                <div
                  className={classes.addIcon}
                  onClick={handleClientStudySelect(data)}
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </div>
              </div>
            }
          >
            <div className={classes.studyDetails}>
              {data.studies.map((study, index) => {
                return (
                  <div key={index} className={classes.studyDetailsWrapper}>
                    <div className={classes.studyType}>
                      <h3>{study?.name} Study</h3>
                    </div>
                    <div className={classes.studyProgression}>
                      {clientStudyStatus.map(({ label, stage }, i) => {
                        return (
                          <div key={stage} className={classes.barWrapper}>
                            {index === 0 && <p>{label}</p>}
                            <div
                              className={cx(classes.bar, {
                                [classes.firstChild]: i === 0,
                                [classes.lastChild]: i === 3,
                                [classes.activeBar]:
                                  stage <= getStudyStage(study?.status),
                                [classes.completedStatus]:
                                  stage === getStudyStage(study?.status),
                                [classes.halfReport]:
                                  getStudyStage(study?.status) === 3.5 &&
                                  stage > 3,
                                [classes.onHoldBar]: study.status === 'Hold',
                              })}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className={cx(classes.paidInFull, {
                        [classes.fullBar]: getStudyStage(study?.status) === 5,
                      })}
                    >
                      {index === 0 && <p>Paid in Full</p>}
                      <div></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </CustomAccordion>
        );
      })}
    </div>
  );
};
export default PartnerClientStudies;
