import React from 'react';
import { CardBody, Card } from 'reactstrap';
import ScheduleMeeting from './ScheduleMeeting';
import ViewDocuments from './ViewDocuments';
import InviteTeammate from './InviteTeammate';

const QuickActions = ({ projectId, studyName }) => {
  return (
    <Card className="d-flex">
      <CardBody className="d-flex flex-column position-relative pt-3">
        <h5 className="text-uppercase text-muted mb-0 card-title">
          Quick Actions
        </h5>
        <div className="d-flex w-100 mt-2 justify-content-between flex-wrap">
          <ScheduleMeeting />
          <ViewDocuments projectId={projectId} />
          <InviteTeammate projectId={projectId} studyName={studyName} />
        </div>
      </CardBody>
    </Card>
  );
};

export default QuickActions;
