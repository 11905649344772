import Request from './request';

export const getRecentlyViewedProjects = () => {
  return Request.call({
    url: '/me/dashboard/recent-projects',
    method: 'GET',
  });
};

export const getWeeklyHoursLogged = () => {
  return Request.call({
    url: '/me/dashboard/weekly-hours',
    method: 'GET',
  });
};

export const getOpenAssignments = () => {
  return Request.call({
    url: '/me/dashboard/open-tasks',
    method: 'GET',
  });
};

export const getTimeSpent = async ({
  group = 'week',
  startDate = '',
  endDate = '',
}) => {
  return Request.call({
    url: `/me/dashboard/project-hours?group=${group}&date_from=${startDate}&date_to=${endDate}`,
    method: 'GET',
  });
};

export const getRecentlyViewedStories = async (page = 1) => {
  return Request.call({
    url: '/me/dashboard/recent-stories',
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getClientTasks = async ({
  created_by,
  sort = '-created_at',
  page = 1,
  owner_id,
}) => {
  let url = `/me/dashboard/tasks?status=open&clientTasks=true&sort=${sort}`;
  if (created_by) {
    url += `&created_by=${created_by}`;
  }
  if (owner_id) {
    url += `&owner_id=${owner_id}`;
  }
  return Request.call({
    url: url,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getMyOpenTasks = async ({
  userId,
  created_by,
  sort = '-created_at',
  page = 1,
}) => {
  let url = `/me/dashboard/tasks?status=open&clientTasks=true&owner_id=${userId}&sort=${sort}`;
  return Request.call({
    url: url,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getEstimatedDeliveries = async ({
  sort = '-estimated_delivery_date',
  page = 1,
}) => {
  return Request.call({
    url: `/me/dashboard/estimated-deliveries?sort=${sort}`,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getDashboardActivities = async page => {
  return Request.call({
    url: `/me/dashboard/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getActiveStudy = async page => {
  return Request.call({
    url: `/me/dashboard/active-study`,
    method: 'GET',
  });
};

export const getCompletedStudies = async page => {
  return Request.call({
    url: `/me/dashboard/completed-studies`,
    method: 'GET',
  });
};
export const getDeliverables = async page => {
  return Request.call({
    url: `/me/dashboard/deliverables`,
    method: 'GET',
  });
};
