import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
// import cs from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  // FormGroup,
  Row,
} from 'reactstrap';
import classes from './Login.module.scss';
import cs from 'classnames';
// core components
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import ButtonSocialLogin from 'components/ButtonSocialLogin';
import MicrosoftIcon from 'assets/img/icons/common/microsoft.svg';
import Alert from 'components/Alert';
import {
  doLogin,
  toggleAuthError,
  toggleResetPasswordError,
  toggleResetPasswordSuccess,
} from 'store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import history from 'helpers/history';
import AuthCardHeader from 'components/Headers/AuthCardHeader';
import Loading from 'components/Loading';

const Login = props => {
  const dispatch = useDispatch();
  const authReducer = useSelector(({ auth }) => auth);
  // const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
      .max(255, 'The email must be less than 255 chars'),
    password: Yup.string().required('Password is required'),
  });

  useEffect(() => {
    // If user's not logged in we will have stored the route they are on in router state
    const pathname = get(props, 'location.state.from.pathname', '');
    // when user hits logout this will be true, so pathname won't be set in that case
    const isLogout = get(props, 'history.location.state.isLogOut', false);
    const search = get(props, 'location.state.from.search', '');
    // if route is present setting as redirect_url so on login user will be taken on that route
    if (pathname && !isLogout) {
      sessionStorage.setItem(
        'redirect_URL',
        search ? `${pathname}/${search}` : pathname
      );
    }

    // dispatch(toggleResetPasswordSuccess());
    // dispatch(toggleResetPasswordError());
    // dispatch(toggleAuthError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async values => {
    const email = values.email;
    const password = values.password;

    setLoading(true);
    const { status } = await dispatch(doLogin(email, password));

    if (status !== 1) setLoading(false);
  };

  if (get(authReducer, 'isError')) {
    setTimeout(() => {
      dispatch(toggleAuthError());
    }, 5000);
  }

  if (get(authReducer, 'passwordReset.isError')) {
    setTimeout(() => {
      dispatch(toggleResetPasswordError());
    }, 5000);
  }

  if (get(authReducer, 'passwordReset.status')) {
    setTimeout(() => {
      dispatch(toggleResetPasswordSuccess());
    }, 5000);
  }

  const renderLoginForm = ({
    // values,
    errors,
    // touched,
    // handleBlur,
    // handleChange,
    handleSubmit,
  }) => {
    return (
      <Card className={cs(classes.card, 'mb-0')}>
        <CardBody className="px-lg-5 py-lg-5 text-center">
          <AuthCardHeader
            head="Sign In"
            title="Sign in to your Strike account to get started on your tax credit."
          />
          {authReducer.socialLoginLoading ? (
            <div className={classes.socialLoading}>
              <Loading />
            </div>
          ) : null}
          {get(authReducer, 'isError') ? (
            <div className={classes.alert}>
              <Alert
                text={authReducer.message}
                dismiss={true}
                fade={true}
                color="danger"
                toggle={false}
                // icon={<i className="fas fa-times-circle" />}
              />
            </div>
          ) : null}
          {get(authReducer, 'passwordReset.isError') === true && (
            <div className={classes.alert}>
              <Alert
                text={get(authReducer, 'passwordReset.message')}
                dismiss={true}
                fade={true}
                color="danger"
                toggle={false}
                // icon={<i className="fas fa-times-circle" />}
              />
            </div>
          )}
          {get(authReducer, 'passwordReset.isError') === false &&
            get(authReducer, 'passwordReset.status') === 1 && (
              <div className={classes.alert}>
                <Alert
                  text={authReducer.passwordReset.message}
                  dismiss
                  fade
                  color="success"
                  toggle={false}
                  // icon={<i className="fas fa-check-circle" />}
                />
              </div>
            )}
          <Form role="form" onSubmit={handleSubmit}>
            <FormikInput
              groupClassName="mb-3"
              name="email"
              placeholder="Email"
              type="text"
              leftIcon={<i className="ni ni-email-83" />}
            />
            <FormikInput
              name="password"
              placeholder="Password"
              type="password"
              leftIcon={<i className="ni ni-lock-circle-open" />}
            />
            <div className="text-center">
              <Button
                className="my-4"
                block
                color="primary"
                type="submit"
                loading={loading}
                disabled={!isEmpty(errors)}
              >
                Sign in
              </Button>
            </div>
          </Form>
          <p className="text-xs mb-4">
            By signing in, you agree to the Strike Tax{' '}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="/terms" target="_blank">
              Terms and Conditions
            </a>
          </p>
          <div className="mt-2">
            <ButtonSocialLogin
              className="font-weight-400 d-flex align-items-center justify-content-center"
              history={props.history}
            >
              Continue with Google
            </ButtonSocialLogin>
            <ButtonSocialLogin
              social="microsoft"
              className="ml-0 font-weight-400 d-flex align-items-center justify-content-center"
              history={props.history}
              leftIcon={<img alt="Microsoft" src={MicrosoftIcon} />}
            >
              Continue with Microsoft
            </ButtonSocialLogin>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className={cs('pb-5', classes.container)}>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={schema}
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {renderLoginForm}
            </Formik>
            <Row className="mt-3">
              <Col xs={12} className="d-flex">
                <a
                  className={cs('mx-auto', classes.forgotPasswordLink)}
                  href="/auth/email/reset-password"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/email/reset-password');
                  }}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              {/*<Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/auth/register');
                  }}
                >
                  <small>Create new account</small>
                </a>
              </Col>*/}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
