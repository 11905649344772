import { get } from 'lodash';
import {
  fetchList,
  getRoleOptions,
  resendInvitation,
  sendInvitations,
  updateRole,
  reInvite,
  deactivate,
  cancel,
  inviteClientRoleOptions,
  editUser,
  deleteUser as deleteUserAPI,
  impersonateUser as impersonateUserAPI,
} from 'api/users';
import Types from '../types/users';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';

export const fetchUsersList = (page, sort, q, limit = 50, projectId) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_USERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchList(page, sort, q, limit, projectId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_USERS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_USERS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_USERS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchRoles = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_USER_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getRoleOptions();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_USER_ROLES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_USER_ROLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_USER_ROLES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchInviteClientRoles = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_INVITE_CLIENT_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await inviteClientRoleOptions();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_INVITE_CLIENT_ROLES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_INVITE_CLIENT_ROLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_INVITE_CLIENT_ROLES_FAILURE,
        message: error,
      });
    }
  };
};

export const updateUserRoles = (id, roles, shouldUpdateDta = true) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_USER_ROLE_INPROGRESS,
    });

    if (shouldUpdateDta) {
      const users = get(getState(), 'user.userState.data.data', []);
      const foundIndex = users.findIndex(u => u.id === id);
      dispatch({
        type: Types.UPDATE_USERS_DATA,
        data: [
          ...users.slice(0, foundIndex),
          { ...users[foundIndex], roles },
          ...users.slice(foundIndex + 1),
        ],
      });
    }
    // eslint-disable-next-line no-undef
    try {
      const data = [roles.name];
      const resp = await updateRole(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPDATE_USER_ROLE_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPDATE_USER_ROLE_FAILURE,
          message: message,
        });
      }
      return { status, record };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_USER_ROLE_FAILURE,
        message: error,
      });
    }
  };
};

export const inviteMembers = data => {
  return async dispatch => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await sendInvitations(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });

        NotificationHandler.open({
          title: 'Invite Sent Successfully',
          operation: 'success',
          icon: ' ',
        });
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          // if user need to upgrade the plan, then we dont need to show this alert
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const resendInviteToUser = (id, params = {}) => {
  return async dispatch => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await resendInvitation(id, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });

        NotificationHandler.open({
          message,
          title: 'Invitation Resent Successfully',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const reInviteUser = (id, data, params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await reInvite(id, data, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });
        const users = get(getState(), 'user.userState.data.data', []);
        const foundIndex = users.findIndex(u => u.id === id);
        dispatch({
          type: Types.UPDATE_USERS_DATA,
          data: [
            ...users.slice(0, foundIndex),
            {
              ...users[foundIndex],
              status: record.status,
              roles: record.roles,
              joined_at: null,
            },
            ...users.slice(foundIndex + 1),
          ],
        });
        NotificationHandler.open({
          message,
          title: 'Invite Sent Successfully',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deactivateUser = (id, params) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deactivate(id, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });
        const users = get(getState(), 'user.userState.data.data', []);
        const foundIndex = users.findIndex(u => u.id === id);
        dispatch({
          type: Types.UPDATE_USERS_DATA,
          data: [
            ...users.slice(0, foundIndex),
            { ...users[foundIndex], ...record },
            ...users.slice(foundIndex + 1),
          ],
        });
        NotificationHandler.open({
          title: 'User Deactivated Successfully',
          message,
          operation: 'success',
        });
        return status;
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const cancelInvite = (id, params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await cancel(id, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });
        const users = get(getState(), 'user.userState.data.data', []);
        const foundIndex = users.findIndex(u => u.id === id);
        dispatch({
          type: Types.UPDATE_USERS_DATA,
          data: [
            ...users.slice(0, foundIndex),
            { ...users[foundIndex], ...record },
            ...users.slice(foundIndex + 1),
          ],
        });
        NotificationHandler.open({
          title: 'Invitation Cancelled Successfully',
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const editInvite = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await editUser(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.open({
          title: 'User Edited Successfully',
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          title: ' ',
          icon: ' ',
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteUser = (id, params = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.INVITE_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteUserAPI(id, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_MEMBERS_SUCCESS,
        });
        NotificationHandler.open({
          title: ' ',
          icon: ' ',
          message,
          operation: 'success',
        });
        return status;
      } else {
        dispatch({
          type: Types.INVITE_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          title: ' ',
          icon: ' ',
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.INVITE_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        title: ' ',
        icon: ' ',
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const impersonateUser = userId => {
  return async dispatch => {
    dispatch({
      type: Types.IMPERSONATE_USER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await impersonateUserAPI(userId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.IMPERSONATE_USER_SUCCESS,
          data: record || {},
        });
      } else {
        dispatch({
          type: Types.IMPERSONATE_USER_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          title: ' ',
          icon: ' ',
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.IMPERSONATE_USER_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        title: ' ',
        icon: ' ',
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const impersonateUserWithPartnerId = guestPartner => {
  return async dispatch => {
    // eslint-disable-next-line no-undef
    if (guestPartner) {
      dispatch({
        type: Types.IMPERSONATE_INACTIVE_USER_SUCCESS,
        data: guestPartner,
      });
    }
  };
};

export const exitImpersonateUser = () => {
  return dispatch => {
    dispatch({
      type: Types.EXIT_IMPERSONATE_USER,
    });
  };
};
