import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, UncontrolledTooltip } from 'reactstrap';
import NoViewedStories from 'assets/img/theme/no-estimated-delivery.svg';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from 'views/pages/dashboards/Dashboard.module.scss';
import get from 'lodash/get';
import history from 'helpers/history';
import { getEstimatedDeliveries as getEstimatedDeliveriesAction } from 'store/actions/userDashboard';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import UserInfo from 'components/UserInfo';

const EstimatedDeliveries = ({ getDetails, isMobile }) => {
  const dispatch = useDispatch();

  const getEstimatedDeliveries = (field, defaultValue) =>
    getDetails(`estimatedDeliveries.${field}`, defaultValue);
  const estimatedDeliveries = getEstimatedDeliveries('data.data', []);
  const metaDetails = getEstimatedDeliveries('data.meta');
  const isError = getEstimatedDeliveries('isError', false);
  const loading = getEstimatedDeliveries('isInProgress', false);
  const lastPage = get(metaDetails, 'last_page', 1);
  const currentPage = get(metaDetails, 'current_page', 1);

  const [sortBy, setSortBy] = useState({});

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  useEffect(() => {
    let searchParams = {};
    const { order, dataField } = sortBy;
    if (order && dataField) {
      searchParams.page = 0;
      searchParams.sort = order === 'asc' ? dataField : `-${dataField}`;
    }
    dispatch(getEstimatedDeliveriesAction(searchParams));
  }, [dispatch, sortBy]);

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const rowEvents = {
    onClick: (e, row) => {
      return history.push(`/admin/studies/${row.id}`);
    },
  };

  return (
    <>
      <Card className={classes.viewedStories}>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h3 className="mb-0">Upcoming Estimated Delivery Dates</h3>
          </div>
        </CardHeader>
        <CardBody
          id="recently-viewed-stories-card-body"
          className={cx('p-0', classes.cardBody, !isMobile && classes.scroll)}
        >
          <InfiniteScroll
            useWindow={isMobile}
            hasMore={currentPage < lastPage && !loading && !isError}
            initialLoad={false}
            pageStart={1}
            loadMore={() => {
              dispatch(getEstimatedDeliveriesAction({ page: currentPage + 1 }));
            }}
          >
            <BaseTable
              keyField="id"
              defaultSorted={null}
              noDataIndication={() => (
                <div
                  className={cx(
                    'd-flex flex-column justify-content-centre align-items-centre',
                    classes.tableWrapper
                  )}
                >
                  <img
                    src={NoViewedStories}
                    className="mx-auto my-3"
                    alt="No Upcoming Estimated Deliveries"
                  />
                </div>
              )}
              remote
              search={false}
              bordered={false}
              loading={loading}
              paginationOptions={false}
              data={estimatedDeliveries}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  setSortBy({
                    dataField: sortField,
                    order: sortOrder,
                  });
                }
              }}
              rowEvents={rowEvents}
              rowClasses={classes.row}
              columns={[
                {
                  dataField: 'client_name',
                  text: 'Client',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <h5
                          className={cx(
                            'm-0',
                            classes.ellipsis,
                            classes.storyName
                          )}
                        >
                          {cell}
                        </h5>
                      </>
                    );
                  },
                },
                {
                  dataField: 'name',
                  text: 'Study',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div
                          className={cx(
                            'd-flex align-items-center',
                            classes.ellipsis,
                            classes.storyName,
                            classes.projectName
                          )}
                        >
                          <span id={`project-name-${row.id}`}>{cell}</span>
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={`project-name-${row.id}`}
                          placement="bottom"
                          innerClassName={classes['tooltip']}
                          boundariesElement="viewport"
                        >
                          {cell}
                        </UncontrolledTooltip>
                      </>
                    );
                  },
                },
                {
                  dataField: 'owner_name',
                  text: 'Project Lead',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell, row) => {
                    return (
                      <UserInfo
                        info={{
                          name: cell,
                          id: row.id,
                          avatar: row.owner_avatar,
                        }}
                        showName={false}
                        showToolTip={true}
                      />
                    );
                  },
                },
                {
                  dataField: 'estimated_delivery_date',
                  text: 'Delivery',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: cell => {
                    return (
                      <span>
                        {moment(cell)
                          .tz(userTimezone)
                          .format('M/DD/YYYY')}
                      </span>
                    );
                  },
                },
              ]}
            />
          </InfiniteScroll>
        </CardBody>
      </Card>
    </>
  );
};

EstimatedDeliveries.propTypes = {};

export default EstimatedDeliveries;
