import React from 'react';
import classNames from 'classnames';
import classes from './Backlogs.module.scss';
import useBreakPoint from 'helpers/useBreakPoint';
import { Row, Col } from 'reactstrap';
import Input from 'components/FormFields/Input';

const SectionsHeader = ({
  isMassUpdateAllowed,
  isMassDeleteAllowed,
  id,
  onClickCheckbox,
  isChecked,
}) => {
  const isMobile = useBreakPoint('xs', 'down');
  const isTablet = useBreakPoint('sm', 'down');

  return (
    <Row
      className={classNames(
        'd-flex border-bottom border-top align-items-center',
        classes.heading
      )}
    >
      <Col
        xs="auto"
        className={classNames(classes.titleColumn, 'd-flex align-items-center')}
      >
        {(isMassUpdateAllowed || isMassDeleteAllowed) && (
          <Input
            checked={isChecked}
            type="checkbox"
            id={`story-select-all-${id}`}
            onChange={event => {
              onClickCheckbox(event.target.checked);
            }}
          />
        )}
        <h6
          className={classNames(
            classes.columnHeading,
            'text-gray text-weight-bold mb-0'
          )}
        >
          Title
        </h6>
      </Col>
      {!isMobile ? (
        <>
          <Col xs={2}>
            <h6
              className={classNames(
                classes.columnHeading,
                classes.columnHeadingSmall,
                'text-gray text-weight-bold mb-0 '
              )}
            >
              Phase
            </h6>
          </Col>
          <Col xs="auto" lg={2} className={classes.priority}>
            <h6
              className={classNames(
                classes.columnHeading,
                classes.columnHeadingSmall,
                'text-gray text-weight-bold mb-0 '
              )}
            >
              Priority
            </h6>
          </Col>
        </>
      ) : null}
      <Col xs={2}>
        <h6
          className={classNames(
            classes.columnHeading,
            classes.columnHeadingSmall,
            'text-gray text-weight-bold mb-0 '
          )}
        >
          Status
        </h6>
      </Col>
      {!isTablet && (
        <Col xs={2}>
          <h6
            className={classNames(
              classes.columnHeading,
              classes.columnHeadingSmall,
              'text-gray text-weight-bold mb-0 '
            )}
          >
            Assignee
          </h6>
        </Col>
      )}
      {/* eslint-disable-next-line */}
      </Row>
  );
};

export default React.memo(SectionsHeader);
