import * as React from 'react';
import Plan from './Plan';
import classes from './billing.module.scss';

const PlanList = props => {
  const {
    loadingPlan,
    isStarter,
    isPro,
    shouldShowStarterPlan,
    shouldShowProPlan,
    shouldShowEnterprisePlan,
    goToStripe,
    onManagePlan,
    onContactUs,
  } = props;
  return (
    <div className={classes.plans}>
      <Plan
        name="Starter Plan"
        price="Free"
        description="Great for small teams and those that are just getting started. Startups and ideators!"
        features={[
          'Full Suite of Features',
          '5 Team Members',
          '3 Studies',
          '1 GB Storage',
        ]}
        buttonText={isStarter ? 'Your Current Plan' : 'Downgrade to Starter'}
        onButtonClick={() => goToStripe('starter')}
        disableButton={isStarter}
        isLoading={loadingPlan === 'starter'}
        isVisible={shouldShowStarterPlan}
      />
      <Plan
        name="Pro Plan"
        price="$14.99"
        description="Perfect for growing teams of 6 or more! Create studies with as many people as you need!"
        features={[
          'Full Suite of Features',
          'Unlimited Team Members',
          'Unlimited Studies',
          'Unlimited Storage',
        ]}
        buttonText={isPro ? 'Manage Your Plan' : 'Upgrade to Pro'}
        proTheme
        onButtonClick={() => {
          isPro ? onManagePlan() : goToStripe('pro');
        }}
        isLoading={loadingPlan === 'pro'}
        ribbonText={isPro ? 'Current Plan' : 'Best Plan'}
        isVisible={shouldShowProPlan}
      />
      <Plan
        name="Enterprise Plan"
        price="Coming Soon"
        description="Corporate enterprises with complex technical requirements and operational workflows."
        features={[
          'Custom Integrations',
          'Dedicated Server',
          'Priority Support',
          'Unlimited Possibilities',
        ]}
        buttonText="Contact Us"
        yellowTheme
        isLoading={loadingPlan === 'enterprise'}
        onButtonClick={onContactUs}
        isVisible={shouldShowEnterprisePlan}
      />
    </div>
  );
};

export default PlanList;
