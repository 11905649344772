import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { inviteMembers } from 'store/actions/users';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';

const InviteUserFromSidebar = ({ closeModal, studyName, projectId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectionChange, setSelectionChange] = useState({
    openValidModal: false,
    agreeToInvite: false,
    selectedValue: '',
    data: null,
  });

  const handleModalClose = () => {
    closeModal(false);
  };
  const submitValues = async values => {
    setIsLoading(true);
    const { firstName, lastName, email, role, note } = values;
    const data = {
      roles: [role.name],
      first_name: firstName,
      last_name: lastName,
      email_addresses: [email],
    };
    if (note) {
      data.personal_note = note;
    }

    const { errorStatus } = await dispatch(inviteMembers(data));
    setIsLoading(false);
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showTeamLimit();
      return;
    }
    handleModalClose();
  };

  return (
    <InviteUserForm
      isModalOpen
      submitValues={submitValues}
      closeModal={handleModalClose}
      editValues={
        projectId && studyName
          ? {
              projects: [{ id: +projectId, name: studyName }],
            }
          : null
      }
      isLoading={isLoading}
      selectionChange={selectionChange}
      setSelectionChange={setSelectionChange}
    />
  );
};

export default InviteUserFromSidebar;
