import Types from 'store/types/partnerDashboard';

const initialState = {
  partnerDashboard: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerInProgressRecords: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerSilentRecords: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerDisqualifiedRecords: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  industryList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  clientIndustryList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerTypeList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerReferral: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_SUCCESS:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_FAILURE:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNERS_INDUSTRIES_LIST_INPROGRESS:
      return {
        ...state,
        industryList: {
          ...state.industryList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_INDUSTRIES_LIST_SUCCESS:
      return {
        ...state,
        industryList: {
          ...state.industryList,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_INDUSTRIES_LIST_FAILURE:
      return {
        ...state,
        industryList: {
          ...state.industryList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_PARTNER_REFERRAL_INPROGRESS:
      return {
        ...state,
        partnerReferral: {
          ...state.partnerReferral,
          isInProgress: true,
        },
      };
    case Types.CREATE_PARTNER_REFERRAL_SUCCESS:
      return {
        ...state,
        partnerReferral: {
          ...state.partnerReferral,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.CREATE_PARTNER_REFERRAL_FAILURE:
      return {
        ...state,
        partnerReferral: {
          ...state.partnerReferral,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_INPROGRESS:
      return {
        ...state,
        partnerInProgressRecords: {
          ...state.partnerInProgressRecords,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_SUCCESS:
      return {
        ...state,
        partnerInProgressRecords: {
          ...state.partnerInProgressRecords,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_FAILURE:
      return {
        ...state,
        partnerInProgressRecords: {
          ...state.partnerInProgressRecords,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_INPROGRESS:
      return {
        ...state,
        partnerSilentRecords: {
          ...state.partnerSilentRecords,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_SUCCESS:
      return {
        ...state,
        partnerSilentRecords: {
          ...state.partnerSilentRecords,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_FAILURE:
      return {
        ...state,
        partnerSilentRecords: {
          ...state.partnerSilentRecords,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_INPROGRESS:
      return {
        ...state,
        partnerDisqualifiedRecords: {
          ...state.partnerDisqualifiedRecords,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_SUCCESS:
      return {
        ...state,
        partnerDisqualifiedRecords: {
          ...state.partnerDisqualifiedRecords,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_FAILURE:
      return {
        ...state,
        partnerDisqualifiedRecords: {
          ...state.partnerDisqualifiedRecords,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_INDUSTRIES_LIST_INPROGRESS:
      return {
        ...state,
        clientIndustryList: {
          ...state.clientIndustryList,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_INDUSTRIES_LIST_SUCCESS:
      return {
        ...state,
        clientIndustryList: {
          ...state.clientIndustryList,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_INDUSTRIES_LIST_FAILURE:
      return {
        ...state,
        clientIndustryList: {
          ...state.clientIndustryList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNER_TYPE_LIST_INPROGRESS:
      return {
        ...state,
        partnerTypeList: {
          ...state.partnerTypeList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_TYPE_LIST_SUCCESS:
      return {
        ...state,
        partnerTypeList: {
          ...state.partnerTypeList,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNER_TYPE_LIST_FAILURE:
      return {
        ...state,
        partnerTypeList: {
          ...state.partnerTypeList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
