import React from 'react';
import classes from './epics.module.scss';
import cx from 'classnames';
import Input from 'components/FormFields/Input';
import Dropdowns from 'components/Dropdowns';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Loader from 'components/Loading';
import ColorPicker from './BadgeColorPicker';
import compact from 'lodash/compact';

const RenderData = ({
  isLoading,
  isEditInprogress,
  isCreateInprogress,
  isDeleteInprogress,
  epicData,
  setNewColor,
  setNewEpic,
  elementToFocus,
  editEpic,
  updateEpic,
  newEpic,
  deleteEpicConfirm,
  moveEpicToBottom,
  moveEpicToTop,
  editId,
  createdProjectId,
}) => {
  return (
    <>
      {(isLoading ||
        isEditInprogress ||
        isCreateInprogress ||
        isDeleteInprogress) && (
        <div className="d-flex align-items-stretch">
          <Loader wrapperClass="position-absolute" />
        </div>
      )}
      <Droppable droppableId="epic-list">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {epicData.map((row, index) => (
              <Draggable
                draggableId={row.id.toString()}
                index={index}
                key={row.id}
                isDragDisabled={!editEpic}
              >
                {(provided, snapshot) => {
                  const draggableStyles = snapshot.isDragging
                    ? { ...provided.draggableProps.style, zIndex: 1 }
                    : provided.draggableProps.style;
                  return (
                    <div
                      {...provided.draggableProps}
                      style={draggableStyles}
                      ref={provided.innerRef}
                      className={cx(
                        'd-flex align-items-center flex-row px-3 py-4 bg-white',
                        epicData.length - 1 > index && 'border-bottom'
                      )}
                    >
                      {editEpic && (
                        <i
                          {...provided.dragHandleProps}
                          className={cx('fas fa-grip-vertical text-light mr-2')}
                        />
                      )}
                      <ColorPicker
                        color={`#${row.color}`}
                        onSelect={color => {
                          setNewColor(color, row);
                        }}
                        onClick={() => {
                          setNewEpic(row);
                        }}
                        rowId={row.id}
                        disabled={!editEpic}
                      />

                      {editId !== row.id ? (
                        <div
                          ref={
                            row.id === createdProjectId ? elementToFocus : null
                          }
                          id={row.id}
                          className={cx(
                            'd-flex w-100 align-items-center',
                            !editEpic && classes['edit-not-allowed']
                          )}
                        >
                          <span
                            className={cx(
                              classes['epic-name'],
                              'font-weight-bold'
                            )}
                          >
                            <h5 className="m-0">{row.name}</h5>
                          </span>
                          {editEpic && (
                            <span className="d-flex align-items-center ml-auto h-100">
                              <Dropdowns
                                text={<i className="fas fa-ellipsis-v" />}
                                className="btn-icon-only m-0 text-light float-right"
                                options={compact([
                                  {
                                    text: 'Move to Top',
                                    onClick: () => moveEpicToTop(index),
                                  },
                                  {
                                    text: 'Move to Bottom',
                                    onClick: () => moveEpicToBottom(index),
                                  },
                                  {
                                    text: 'Rename Phase',
                                    onClick: () => editEpic(row),
                                  },
                                  deleteEpicConfirm && {
                                    text: 'Delete Phase',
                                    onClick: () => deleteEpicConfirm(row),
                                  },
                                ])}
                                caret={false}
                                size="sm"
                                color=""
                              />
                            </span>
                          )}
                        </div>
                      ) : (
                        <Input
                          defaultValue={newEpic.name}
                          onBlur={event => updateEpic(event.target.value)}
                          maxLength={255}
                          autoFocus
                          onKeyDown={event => {
                            if (event.keyCode === 13) {
                              updateEpic(event.target.value);
                            }
                          }}
                        />
                      )}
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default RenderData;
