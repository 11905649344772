import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { fetchClientActivities } from 'store/actions/clientProfile';
import Loading from 'components/Loading';
import Activity from 'components/Activity';
import classes from './ClientProfile.module.scss';
import classNames from 'classnames';
import NoActivityImg from 'assets/img/theme/No_Activity.png';

const RecentActivity = ({ clientId, onStoryClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const activities = useSelector(({ clientProfile }) =>
    get(clientProfile, 'activities.data.data', [])
  );
  const activitiesMeta = useSelector(({ clientProfile }) =>
    get(clientProfile, 'activities.data.meta', {})
  );

  const hasMore =
    activitiesMeta.current_page &&
    activitiesMeta.last_page &&
    activitiesMeta.current_page < activitiesMeta.last_page;

  const loadMoreActivities = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      await dispatch(
        fetchClientActivities(
          clientId,
          parseInt(activitiesMeta.current_page) + 1
        )
      );
      setIsLoading(false);
    }
  };

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={classNames('m-auto w-100', classes.defaultImage)}
              src={NoActivityImg}
              alt="No Recent Activity"
            />
          </div>
          <h4
            className={classNames(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Recent Activities
          </h4>
        </div>
      </div>
    );
  };

  const renderActivities = () => {
    if (activities.length > 0) {
      return (
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMoreActivities}
          hasMore={hasMore}
          loader={<Loading key="loader" size={100} />}
          useWindow={false}
        >
          {orderBy(activities, ['created_at'], 'desc').map((d, i) => (
            <Activity key={i} item={d} isLargeAvatar onClick={onStoryClick} />
          ))}
        </InfiniteScroll>
      );
    }
    return <NoDataIndication />;
  };

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Recent Activity</h3>
      </CardHeader>
      <CardBody className={classes.recentActivities}>
        {renderActivities()}
      </CardBody>
    </Card>
  );
};

export default RecentActivity;
