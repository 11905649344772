import React, { useState } from 'react';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import cls from './myDocuments.module.scss';
import CheckBox from 'components/FormFields/CheckBox';
import { useDispatch } from 'react-redux';
import { fetchDocumentTypesForFY, submitYear } from 'store/actions/documents';

const SubmitDocuments = ({
  isModalOpen,
  closeModal,
  title,
  activeYear,
  hasMissing1099s,
  hasMissingEmployeeReports,
  hasTaxReturns,
  documents = [],
}) => {
  const dispatch = useDispatch();
  const [isFederal, setIsFederal] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isMissingTaxReturns, setIsMissingTaxReturns] = useState(false);
  const [isMissingEmpReports, setIsMissingEmpReports] = useState(false);
  const [isMissing1099s, setIsMissing1099s] = useState(false);
  const [loading, setLoading] = useState(false);

  const isValid = () => {
    return !(
      (hasMissing1099s && !isMissing1099s) ||
      (hasMissingEmployeeReports && !isMissingEmpReports) ||
      (hasTaxReturns && !isFederal && !isState) ||
      (!hasTaxReturns && !isMissingTaxReturns)
    );
  };

  const taxReturnsFolder = documents?.find(doc => {
    return doc.short_name === 'Tax Returns';
  });

  const taxReturnsDocsCount = taxReturnsFolder?.documents.length;

  const handleSubmit = async () => {
    setLoading(true);
    const params = documents.map(document => {
      if (
        document.short_name === '1099s' ||
        document.short_name === 'Employee Payroll Reports'
      ) {
        return {
          id: document.id,
          extra_info: {
            no_documents: true,
          },
        };
      }
      if (document.short_name === 'Tax Returns' && hasTaxReturns) {
        const tax_types = [];
        if (isFederal) tax_types.push('Federal');
        if (isState) tax_types.push('State');
        return {
          id: document.id,
          extra_info: {
            tax_types,
          },
        };
      }
      return {
        id: document.id,
        extra_info: {},
      };
    });
    await dispatch(submitYear(activeYear.id, params));
    dispatch(fetchDocumentTypesForFY(activeYear));
    closeModal();
  };

  return (
    <Modal
      toggle={closeModal}
      centered
      isOpen={isModalOpen}
      title={title}
      size="lg"
      noHeader
    >
      <ModalBody>
        <div className={cls.modalHeader}>
          <div className={cls.titleWrapper}>
            <div className={cls.questionIcon}>
              <i className="fas fa-question-circle" />
            </div>
            <h5>{title}</h5>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className={cls.modalContent}>
          <ul>
            {hasTaxReturns ? (
              <li>
                <span className={cls.warningHeader}>
                  <strong>{taxReturnsDocsCount} Tax Returns -</strong> Select
                  all that apply:
                </span>
                <div>
                  <CheckBox
                    checked={isFederal}
                    onChange={() => setIsFederal(!isFederal)}
                    id="federal-checkbox"
                    className="mb-1"
                    label="Federal"
                  />
                  <CheckBox
                    checked={isState}
                    onChange={() => setIsState(!isState)}
                    label="State"
                    id="state-checkbox"
                  />
                </div>
              </li>
            ) : (
              <li>
                <span className={cls.warningHeader}>
                  <strong>Missing Tax Returns:</strong>
                </span>
                <div>
                  <CheckBox
                    checked={isMissingTaxReturns}
                    onChange={() =>
                      setIsMissingTaxReturns(!isMissingTaxReturns)
                    }
                    label={`I didn’t have any tax returns in ${activeYear.year}.`}
                    id="tax-returns-checkbox"
                  />
                </div>
              </li>
            )}
            {hasMissingEmployeeReports && (
              <li>
                <span className={cls.warningHeader}>
                  <strong>Missing Employee Payroll Reports:</strong>
                </span>
                <div>
                  <CheckBox
                    checked={isMissingEmpReports}
                    onChange={() =>
                      setIsMissingEmpReports(!isMissingEmpReports)
                    }
                    label={`I didn’t have any employees in ${activeYear.year}.`}
                    id="employee-checkbox"
                  />
                </div>
              </li>
            )}
            {hasMissing1099s && (
              <li>
                <span className={cls.warningHeader}>
                  <strong>Missing 1099s:</strong>
                </span>
                <div>
                  <CheckBox
                    checked={isMissing1099s}
                    onChange={() => {
                      setIsMissing1099s(!isMissing1099s);
                    }}
                    label={`I didn’t have any contractors in ${activeYear.year}.`}
                    id="contractor-checkbox"
                  />
                </div>
              </li>
            )}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={closeModal}
          color="outline-primary"
          className={'float-right'}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isValid() || loading}
          color={'primary'}
          className={'float-left'}
          loading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SubmitDocuments;
