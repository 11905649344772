import React from 'react';
import useBreakPoint from 'helpers/useBreakPoint';
import classes from '../Backlogs.module.scss';
import classNames from 'classnames';
import { get, orderBy, includes } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import StoryPriorityDropdown from './StoryPriorityDropdown';
import StoryEpicDropdown from './StoryEpicDropdown';
import StoryStatusDropdown from './StoriesStatusDropdown';
import StoryOptions from './StoryOptions';
import history from 'helpers/history';
import UserInfo from 'components/UserInfo';
import { Col } from 'reactstrap';
import Input from 'components/FormFields/Input';
import StoryToolTip from 'components/StoryToolTip';
import PeopleSelector from 'components/PeopleSelector';
import { assignStoryOwner as assignStoryOwnerAction } from 'store/actions/backlogs';
import { updateActiveTourStatus } from 'store/actions/profile';

const Stories = ({
  deleteAction,
  fetchStoryAction,
  id: sectionId,
  isFirstSection,
  reorderStories,
  projectId,
  isStoryReorderAllowed,
  isStoryEditAllowed,
  isStoryDeleteAllowed,
  isStoryViewAllowed,
  onStorySelect,
  onStoryDeSelect,
  isMassUpdateAllowed,
  isMassDeleteAllowed,
  analyticsConstants,
  analyticsSendEvent,
  disableStoryToolTip,
  analyticsSendEventStory,
}) => {
  const isMobile = useBreakPoint('xs', 'down');
  const isTablet = useBreakPoint('sm', 'down');
  const dispatch = useDispatch();
  const stories = useSelector(({ backlog }) =>
    get(backlog, `itemsState.data.${sectionId}.data`, [])
  );

  const selectedStories = useSelector(({ backlog }) =>
    get(backlog.selectedStories, sectionId, [])
  );

  const projectCompanyId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.company_id', null)
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  // redirects to story modal with the comments tab open
  const goToComments = storyId => () => {
    history.push(
      `/admin/studies/${projectId}/tasks/?story=${storyId}&tab=comments`
    );
  };

  return orderBy(stories, ['sort_order'], 'asc').map((story, index) => (
    <Draggable
      draggableId={story.id.toString()}
      index={index}
      key={`section-story-${story.id}`}
      isDragDisabled={!isStoryReorderAllowed}
    >
      {(provided, snapshot) => {
        const draggableStyles = snapshot.isDragging
          ? { ...provided.draggableProps.style, zIndex: 1 }
          : provided.draggableProps.style;
        return (
          <div
            {...provided.draggableProps}
            style={draggableStyles}
            ref={provided.innerRef}
            className={classNames(
              'align-items-center border-bottom row flex-nowrap',
              classes.item,
              classes.story
            )}
          >
            <Col
              xs="auto"
              className={classNames(
                classes.titleColumn,
                'd-flex align-items-center'
              )}
              {...(isFirstSection && index === stories.length - 1
                ? { id: 'lastSectionStory' }
                : {})}
            >
              {(isMassUpdateAllowed || isMassDeleteAllowed) && (
                <Input
                  id={`story-checkbox-${story.id}`}
                  type="checkbox"
                  checked={includes(selectedStories, story.id)}
                  onChange={event =>
                    event.target.checked
                      ? onStorySelect(story.id)
                      : onStoryDeSelect(story.id)
                  }
                />
              )}
              {isStoryReorderAllowed && (
                <i
                  {...provided.dragHandleProps}
                  className="fas fa-grip-vertical text-light mr-2"
                />
              )}
              <div
                id={story.id}
                className={classNames(
                  'd-flex align-items-center',
                  classes.storyName
                )}
              >
                <h5
                  id={`story-name-${story.id}`}
                  onClick={async () => {
                    if (isFirstSection) {
                      if (
                        quickStartTour &&
                        quickStartTour.activeTour &&
                        quickStartTour.activeTour === 'story_creation' &&
                        (quickStartTour.step === 7 || quickStartTour.step === 6)
                      ) {
                        await dispatch(
                          updateActiveTourStatus({
                            step: null,
                            activeTour: null,
                          })
                        );
                        setTimeout(() => {
                          dispatch(
                            updateActiveTourStatus({
                              step: 8,
                              activeTour: 'story_creation',
                            })
                          );
                        }, 2000);
                      }
                    }
                    fetchStoryAction(story);
                  }}
                  className="m-0 font-weight-bold"
                >
                  {story.name}
                </h5>
              </div>
              <StoryToolTip
                targetId={`story-name-${story.id}`}
                name={get(story, 'name', '')}
                disabled={disableStoryToolTip}
              />
            </Col>
            {!isMobile && (
              <>
                <Col xs={2}>
                  <StoryEpicDropdown
                    isStoryEditAllowed={isStoryEditAllowed}
                    story={story}
                    sectionId={sectionId}
                    projectId={projectId}
                  />
                </Col>
                <Col xs="auto" lg={2}>
                  <StoryPriorityDropdown
                    isStoryEditAllowed={isStoryEditAllowed}
                    story={story}
                    sectionId={sectionId}
                    analyticsSendEventStory={analyticsSendEventStory}
                  />
                </Col>
              </>
            )}
            <Col xs={2} className={classNames('d-flex align-items-center')}>
              <StoryStatusDropdown
                isStoryEditAllowed={isStoryEditAllowed}
                story={story}
                sectionId={sectionId}
                analyticsSendEventStory={analyticsSendEventStory}
                labelField={'label'}
              />
            </Col>
            {!isTablet && (
              <Col xs={2}>
                <PeopleSelector
                  id={`section-story-owner-${story.id}`}
                  disabled={!isStoryEditAllowed}
                  url={
                    story.is_client_task
                      ? `/users/list/dropdown?company=${projectCompanyId}`
                      : '/users/list/dropdown?status=Active&role=manager'
                  }
                  onChange={({ id }) => {
                    analyticsSendEventStory({
                      action: analyticsConstants.action.update_story_status,
                      updated_from: 'Project Backlog',
                    });
                    dispatch(assignStoryOwnerAction(sectionId, story.id, id));
                  }}
                  placeholder="Assign a Story Owner"
                >
                  <div
                    className={classNames({
                      [classes.pointer]: isStoryEditAllowed,
                    })}
                  >
                    <UserInfo info={{ avatar: get(story, 'owner.avatar') }} />
                  </div>
                </PeopleSelector>
              </Col>
            )}
            <Col
              xs="auto"
              className={classNames(
                'd-flex align-items-center justify-content-between ml-auto',
                classes.lastColumn
              )}
            >
              {Boolean(story.unread_comments || story.comments_count) && (
                <i
                  className={classNames(
                    'fas fa-comment text-xl',
                    story.unread_comments ? 'text-primary' : 'text-light',
                    classes.commentCount
                  )}
                  onClick={goToComments(story.id)}
                >
                  <span className="position-absolute text-white text-sm">
                    {story.unread_comments
                      ? story.unread_comments
                      : story.comments_count}
                  </span>
                </i>
              )}
              {(isStoryReorderAllowed ||
                isStoryDeleteAllowed ||
                isStoryViewAllowed) && (
                <StoryOptions
                  id={sectionId}
                  index={index}
                  story={story}
                  deleteAction={deleteAction}
                  reorderStories={reorderStories}
                  isStoryReorderAllowed={isStoryReorderAllowed}
                  isStoryDeleteAllowed={isStoryDeleteAllowed}
                  isStoryViewAllowed={isStoryViewAllowed}
                  stories={stories}
                  fetchStoryAction={fetchStoryAction}
                  analyticsConstants={analyticsConstants}
                  analyticsSendEvent={analyticsSendEvent}
                />
              )}
            </Col>
          </div>
        );
      }}
    </Draggable>
  ));
};

export default React.memo(Stories);
