import React, { useEffect } from 'react';
import { Container, UncontrolledTooltip } from 'reactstrap';

import classes from './earnings.module.scss';
import EstimatedChart from './EstimatedChart';
import cx from 'classnames';
import EarningTable from './EarningTable';
import { fetchEarnings } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';

const Earnings = () => {
  const dispatch = useDispatch();

  const earningsInProgress = useSelector(({ partner }) =>
    get(partner, 'earnings.InProgress', false)
  );
  const estimatedEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.estimated_earnings', {})
  );

  const payments = useSelector(({ partner }) =>
    get(partner, 'earnings.data.payments', {})
  );
  const receivedPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.received_payouts', [])
  );
  const pendingPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.pending_payouts', [])
  );
  const referralEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.referral_earnings', [])
  );

  useEffect(() => {
    dispatch(fetchEarnings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (earningsInProgress) {
    return <Loading />;
  }

  return (
    <Container fluid className={classes.earnings}>
      <p className={classes.title}>Earnings</p>
      <div className={classes.infoWrapper}>
        <div>
          <p>
            Last
            <br />
            Payment
          </p>
          <p>{payments.last_payment || '$0'}</p>
        </div>
        <div>
          <p>Total Pending Payouts </p>
          <p>{payments.next_payment || '$0'}</p>
        </div>
        <div>
          <p>
            Year-to-Date <br /> Payments
          </p>
          <p>{payments?.year_to_date_payments || '$0'}</p>
        </div>
        <div>
          <p>
            Total Payments <br />
            (all time)
          </p>
          <p>{payments?.total_payments || '$0'}</p>
        </div>
      </div>
      <EstimatedChart
        futureData={estimatedEarnings}
        comparedData={estimatedEarnings}
      />
      <EarningTable
        header={'Your Earnings by Referral Client'}
        type="earning"
        data={referralEarnings}
      />
      <div className="row">
        <div className="col-4">
          <EarningTable
            header={'Payments Disbursed'}
            type="payment_received"
            data={receivedPayouts}
          />
        </div>
        <div className="col-8">
          <EarningTable
            header={'Pending Payouts by Referral'}
            type="pending_payouts"
            data={pendingPayouts}
          />
        </div>
      </div>
    </Container>
  );
};

export default Earnings;
