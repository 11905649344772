import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Greeting from './Fields/Greeting';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import ClientDashboard from './ClientDashboard';
import PartnerDashboard from './PartnerDashboard';
import {
  getViewedProjects,
  getWeeklyHoursLogged,
  getOpenAssignments,
  getTimeSpent,
  getViewedStories,
  getClientTasks,
  getEstimatedDeliveries,
} from 'store/actions/userDashboard';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { fetchStoryPriorities } from 'store/actions/Story/priorities';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { fetchComplexities } from 'store/actions/Story/complexities';
import { fetchScoreMatrix } from 'store/actions/Story/scoreMatrix';
import { fetchTaskTypes } from 'store/actions/Story/taskTypes';
import { isEmpty } from 'lodash';
import StrikeDashboard from './StrikeDashboard';

const Dashboard = () => {
  const dispatch = useDispatch();

  const handleActionToPerform = (params = {}) => {
    dispatch(getClientTasks(params));
  };

  const analyticsSendEvent = (action, label, value) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_dashboard,
      action,
      label,
      value,
    });
  };

  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('userDashboard', field, defaultValue);
  const commonProps = {
    isMobile,
    getDetails: useGetDetails,
    isTablet,
    analyticsConstants,
    actionToPerform: handleActionToPerform,
  };

  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const impersonateState = useSelector(({ user }) =>
    get(user, 'impersonateState')
  );

  useEffect(() => {
    analyticsSendEvent(analyticsConstants.action.view_personal_dashboard);
    dispatch(getOpenAssignments());
    dispatch(fetchStoryStatuses());
    dispatch(fetchStoryPriorities());
    dispatch(fetchComplexities());
    dispatch(fetchScoreMatrix());
    dispatch(fetchTaskTypes());
    if (!currentUser.is_partner) {
      dispatch(getEstimatedDeliveries({}));
      dispatch(getTimeSpent());
      dispatch(getWeeklyHoursLogged());
      dispatch(getViewedStories());
      dispatch(getViewedProjects());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const getDashboard = () => {
    if (currentUser?.is_partner || !isEmpty(impersonateState.guestPartner)) {
      return <PartnerDashboard />;
    } else if (currentUser?.is_client) {
      return <ClientDashboard />;
    } else {
      return (
        <>
          <Greeting {...commonProps} />
          <StrikeDashboard commonProps={commonProps} />
        </>
      );
    }
  };

  return <>{currentUser && getDashboard()}</>;
};

export default Dashboard;
