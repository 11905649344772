import {
  getPartnersDashboard as getPartnersDashboardApi,
  getInProgressPartnerRecords as getInProgressPartnerRecordsApi,
  getSilentPartnerRecords as getSilentPartnerRecordsApi,
  getDisqualifiedPartnerRecords as getDisqualifiedPartnerRecordsApi,
  getIndustriesList as getIndustriesListApi,
  createReferral as createReferralApi,
  getClientIndustriesList as getClientIndustriesListApi,
  fetchPartnerTypeList as fetchPartnerTypeListApi,
} from 'api/partnerDashboard';
import Types from 'store/types/partnerDashboard';
import get from 'lodash/get';
import { NotificationHandler } from 'components/Notifications';

export const getPartnersDashboard = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS });
    try {
      const resp = await getPartnersDashboardApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_DASHBOARD_FAILURE,
        message: error,
      });
    }
  };
};

export const createReferral = data => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_PARTNER_REFERRAL_INPROGRESS,
    });
    try {
      const resp = await createReferralApi(data);
      if (resp.status === 1) {
        dispatch({
          type: Types.CREATE_PARTNER_REFERRAL_SUCCESS,
          status: resp.status,
          user: resp.data,
        });
      } else {
        dispatch({
          type: Types.CREATE_PARTNER_REFERRAL_FAILURE,
          status: resp.status,
          message: resp.message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
          icon: ' ',
          title: ' ',
        });
      }
      return resp.status;
    } catch (error) {
      dispatch({
        type: Types.CREATE_PARTNER_REFERRAL_FAILURE,
        message: error,
      });
    }
  };
};

export const getPartnersIndustriesList = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_PARTNERS_INDUSTRIES_LIST_INPROGRESS });
    try {
      const resp = await getIndustriesListApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PARTNERS_INDUSTRIES_LIST_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNERS_INDUSTRIES_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_INDUSTRIES_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getClientsIndustriesList = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_INDUSTRIES_LIST_INPROGRESS });
    try {
      const resp = await getClientIndustriesListApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'industries');
        dispatch({
          type: Types.FETCH_CLIENT_INDUSTRIES_LIST_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_INDUSTRIES_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_INDUSTRIES_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPartnerTypeList = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_PARTNER_TYPE_LIST_INPROGRESS });
    try {
      const resp = await fetchPartnerTypeListApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'partner_type');
        dispatch({
          type: Types.FETCH_PARTNER_TYPE_LIST_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNER_TYPE_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_TYPE_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getInProgressPartnerRecords = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_FAILURE,
    });
    try {
      const resp = await getInProgressPartnerRecordsApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_FAILURE,
        message: error,
      });
    }
  };
};

export const getSilentPartnerRecords = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_INPROGRESS,
    });
    try {
      const resp = await getSilentPartnerRecordsApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_FAILURE,
        message: error,
      });
    }
  };
};

export const getDisqualifiedPartnerRecords = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_INPROGRESS,
    });
    try {
      const resp = await getDisqualifiedPartnerRecordsApi();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_FAILURE,
        message: error,
      });
    }
  };
};
