import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormModal from 'components/FormFields/FormModal';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import FileDropZone from 'components/FormFields/FileDropZone';
import each from 'lodash/each';

const dropDownStyle = {
  singleValue: base => ({
    ...base,
    color: '#333333 !important',
  }),
  multiValue: base => ({
    ...base,
    background: '#F4F5F7',
    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
    borderRadius: '4px',
    padding: '4px 5px',
  }),
  multiValueLabel: base => ({
    ...base,
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#32325d',
  }),
};

const UploadDocument = ({
  closeModal,
  submitValues,
  isModalOpen,
  loading,
  companyId,
  clientId,
}) => {
  let initialValues = {
    study: '',
    year: '',
    type: '',
    attachments: [],
  };

  const UploadDocSchema = Yup.object().shape({
    study: Yup.object().required(),
    year: Yup.object().required(),
    type: Yup.object().required(),
    attachments: Yup.array().required(),
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { study, year, type, attachments } = values;
    setSubmitting(true);
    const formData = new FormData();
    formData.append('study', study.id);
    formData.append('year', year.id);
    if (attachments.length > 0) {
      each(attachments, (attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    await submitValues(type.id, formData);
    resetForm();
  };

  const RenderForm = ({
    handleSubmit,
    isValid,
    values,
    touched,
    errors,
    setFieldValue,
  }) => {
    const onFileChange = attachments => {
      setFieldValue('attachments', attachments);
    };

    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        size="lg"
        id="uploadDocModal"
        heading="Upload a Document"
        submit={{
          id: 'uploadDocBrn',
          onClick: handleSubmit,
          loading: loading,
          isValid: isValid,
          buttonText: `Upload File`,
        }}
        cancel={{
          onClick: handleCloseModal,
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <RSelectAsync
              url={`/initiatives/list/dropdown?client=${clientId}`}
              getOptionLabel={option => option.name}
              placeholder="Select a Study"
              onChange={value => {
                setFieldValue('year', null);
                setFieldValue('type', null);
                setFieldValue('study', value);
              }}
              isClearable={true}
              value={values.study}
              touched={touched.study}
              error={errors.study}
              styles={dropDownStyle}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <RSelectAsync
              url={
                values.study
                  ? `/financial-years?initiative=${values.study.id}`
                  : ''
              }
              getOptionLabel={option => option.year}
              placeholder="Select Document Year"
              onChange={value => {
                setFieldValue('type', null);
                setFieldValue('year', value);
              }}
              isClearable={true}
              value={values.year}
              touched={touched.year}
              error={errors.year}
              styles={dropDownStyle}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <RSelectAsync
              url={
                values.year
                  ? `/financial-years/${values.year.id}/document-types/list/dropdown?company_id=${companyId}`
                  : ''
              }
              getOptionLabel={option => option.name}
              placeholder="Select Document Type"
              onChange={value => {
                setFieldValue('type', value);
              }}
              isClearable={true}
              value={values.type}
              touched={touched.type}
              error={errors.type}
              styles={dropDownStyle}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FileDropZone
              handleFileChange={onFileChange}
              onRemove={onFileChange}
              attachment={values.attachments}
              inputProps={{
                multiple: true,
              }}
              shouldAppendFiles={true}
            />
          </FormGroup>
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={UploadDocSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {RenderForm}
    </Formik>
  );
};

export default UploadDocument;
