import React, { useState, useEffect } from 'react';
import classes from './AllDocuments.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import {
  fetchClientDocuments,
  uploadClientDocument,
} from 'store/actions/clientProfile';
import classNames from 'classnames';
import Button from 'components/Button';
import BaseTable from 'components/Table';
import { get } from 'lodash';
import FileViewer from 'components/FileViewer';
import TaskForm from 'views/pages/QuickActions/CreateTask/CreateTask.Form';
import { createClientTaskQuickAction } from 'store/actions/quickActions';
import moment from 'moment';
import NoDocumentsImg from 'assets/img/theme/NoDocumentsImg.png';
import DefaultDocumentImg from 'assets/img/theme/files-documents.png';
import UploadDocument from './UploadDocument';

const AllDocuments = ({ clientId, companyId }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'year',
    order: 'asc',
  });
  const [searchText, setSearchText] = useState('');
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState(null);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clientDocuments = useSelector(
    ({ clientProfile }) => clientProfile.clientDocuments
  );
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const documents = get(clientDocuments, 'data.data', []);
  const documentsMetadata = get(clientDocuments, 'data.meta', {});

  const listLoading = get(clientDocuments, 'isInProgress', false);

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, page, sortBy, searchText]);

  const fetchDocuments = () => {
    const { dataField, order } = sortBy;
    const sort = order === 'desc' ? `-${dataField}` : dataField;
    const params = { page, sort };
    if (searchText) {
      params.q = searchText;
    }
    dispatch(fetchClientDocuments(clientId, params));
  };

  const handleTableChange = async (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      setPage(1);
      setSearchText(searchText);
    }
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const onFileViewerClose = () => {
    setActiveDocument(null);
    setIsFileViewerOpen(false);
  };

  const rowEvents = {
    onClick: (e, row) => {
      setActiveDocument(row);
      setIsFileViewerOpen(true);
    },
  };

  const handleSubmit = async values => {
    setIsSubmitting(true);
    await dispatch(createClientTaskQuickAction(values));
    setIsSubmitting(false);
    setShowCreateTaskModal(false);
  };

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column w-100">
          {searchText ? (
            <img
              className={classNames('m-auto w-100', classes.image, {
                [classes.defaultImage]: !searchText,
              })}
              src={searchText ? NoDocumentsImg : DefaultDocumentImg}
              alt="No Documents"
            />
          ) : (
            <div className={classes.imageWrapper}>
              <img
                className={classNames('m-auto w-100', classes.image, {
                  [classes.defaultImage]: !searchText,
                })}
                src={searchText ? NoDocumentsImg : DefaultDocumentImg}
                alt="No Documents"
              />
            </div>
          )}

          <h4
            className={classNames('display-4', 'mb-0', 'text-center', 'px-2', {
              [classes.defaultText]: !searchText,
            })}
          >
            {searchText ? 'No Search Results Found.' : 'No Documents Added Yet'}
          </h4>
          {searchText && <p className="text-center">Try another search.</p>}
        </div>
      </div>
    );
  };

  const handleUploadDocument = async (documentType, params) => {
    setIsSubmitting(true);
    await dispatch(uploadClientDocument(documentType, params));
    setIsSubmitting(false);
    setShowUploadDocModal(false);
    fetchDocuments();
  };

  return (
    <>
      {isFileViewerOpen && (
        <FileViewer
          isOpen={isFileViewerOpen}
          toggle={onFileViewerClose}
          fileData={activeDocument}
          title={activeDocument.name}
        />
      )}
      {showUploadDocModal && (
        <UploadDocument
          clientId={clientId}
          companyId={companyId}
          isModalOpen={showUploadDocModal}
          submitValues={handleUploadDocument}
          closeModal={() => setShowUploadDocModal(false)}
          loading={isSubmitting}
        />
      )}
      <TaskForm
        isModalOpen={showCreateTaskModal}
        isLoading={isSubmitting}
        closeModal={() => {
          setShowCreateTaskModal(false);
        }}
        submitValues={handleSubmit}
      />
      <div className={classes.documents}>
        <div className="px-4">
          <Card>
            <CardHeader className={classNames('w-100', classes.cardHeader)}>
              <div>
                <h3 className="mb-0" id="clientTitle">
                  All Documents
                </h3>
              </div>
              <div>
                <Button
                  className={classes.uploadDocButton}
                  type="button"
                  onClick={() => setShowUploadDocModal(true)}
                >
                  Upload Documents
                </Button>
                <Button
                  className={classes.newTaskButton}
                  type="button"
                  onClick={() => setShowCreateTaskModal(true)}
                >
                  Create New Task
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                bootstrap4
                remote
                search={true}
                bordered={false}
                loading={listLoading}
                paginationOptions={{
                  page: page,
                  totalSize: documentsMetadata.total,
                  sizePerPage: parseInt(documentsMetadata.per_page),
                }}
                noDataIndication={NoDataIndication}
                noSearchFound={NoDataIndication}
                data={documents}
                onTableChange={handleTableChange}
                rowEvents={rowEvents}
                columns={[
                  {
                    dataField: 'year',
                    text: 'Year',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'doc_type',
                    text: 'Type',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'name',
                    text: 'File',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <>
                        <div
                          className="file-name"
                          id={`document-name-${row.id}`}
                        >
                          {cell || '-'}
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={`document-name-${row.id}`}
                          placement="bottom"
                          innerClassName={classes['tooltip']}
                          boundariesElement="viewport"
                        >
                          {cell}
                        </UncontrolledTooltip>
                      </>
                    ),
                  },
                  {
                    dataField: 'updated_at',
                    text: 'Last Updated',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <span>
                        {moment(cell)
                          .tz(userTimezone)
                          .format('MM/DD/YYYY')}
                      </span>
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AllDocuments;
